import AntLayout from 'antd/lib/layout'
import * as React from 'react'
import logo from '../../assets/images/logo-vaneau-white.svg'
import Header from './Header/Header'
import styles from './Layout.module.scss'
import NavBar from './NavBar/NavBar'

interface IProps {
  children: React.ReactNode
}

interface IState {
  collapsed: boolean
}

export default class Layout extends React.Component<IProps, IState> {
  public state: IState = { collapsed: false }

  public render = () => {
    const { children } = this.props
    const { collapsed } = this.state
    return (
      <AntLayout className={styles.layout}>
        <AntLayout.Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse}>
          <div className={styles.logo}>
            <img alt="logo" title="logo" src={logo} />
          </div>
          <NavBar />
        </AntLayout.Sider>
        <AntLayout>
          <Header />
          {children}
        </AntLayout>
      </AntLayout>
    )
  }

  private onCollapse = () => this.setState({ collapsed: !this.state.collapsed })
}
