import {
  NewsTagCreationDTO,
  NewsTagPrivateDTO,
  NewsTagUpdateDTO,
  PaginationRequest
} from '@vaneauneuf/dtos'
import { Dispatch } from 'redux'
import { IRootState } from '..'
import { apiRequest } from '../utils/api'
import { addNewsTag, removeNewsTag, setLoading, setNewsTag, setNewsTagList } from './actions'

export const fetchNewsTagList = (paginationRequest?: PaginationRequest) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const newsTagList = await apiRequest<NewsTagPrivateDTO[]>(
      'GET',
      getState().authState.token,
      '/newsTag',
      undefined,
      paginationRequest
    )
    dispatch(setNewsTagList(newsTagList))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const fetchNewsTag = (id: string) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const newsTag = await apiRequest<NewsTagPrivateDTO>(
      'GET',
      getState().authState.token,
      '/newsTag/' + id
    )
    dispatch(setNewsTag(newsTag))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const createNewsTag = (newsTag: NewsTagCreationDTO) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const newNewsTag = await apiRequest<NewsTagPrivateDTO>(
      'POST',
      getState().authState.token,
      '/newsTag',
      newsTag
    )
    dispatch(addNewsTag(newNewsTag))
  } catch (e) {
    dispatch(setLoading(false))
    throw e.response.data
  }
  dispatch(setLoading(false))
}

export const updateNewsTag = (id: string, newsTag: NewsTagUpdateDTO) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const newsTagUpdated = await apiRequest<NewsTagPrivateDTO>(
      'PATCH',
      getState().authState.token,
      '/newsTag/' + id,
      newsTag
    )
    dispatch(setNewsTag(newsTagUpdated))
  } catch (e) {
    dispatch(setLoading(false))
    throw e.response.data
  }
  dispatch(setLoading(false))
}

export const deleteNewsTag = (id: string) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    await apiRequest('DELETE', getState().authState.token, '/newsTag/' + id)
    dispatch(removeNewsTag(id))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}
