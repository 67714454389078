import {
  AlertPrivateDTO,
  FavoritePrivateDTO,
  ProjectPrivateDTO,
  UserDTO,
  UserFormDTO,
  WishPrivateDTO
} from '@vaneauneuf/dtos'
import { ActionsTypes } from './model'

export const setContacts = (contacts: { data: UserFormDTO[]; total: number }) => ({
  type: ActionsTypes.SET_CONTACTS,
  contacts
})

export const setContact = (contact: UserFormDTO) => ({
  type: ActionsTypes.SET_CONTACT,
  contact
})

export const setMembers = (members: { data: UserDTO[]; total: number }) => ({
  type: ActionsTypes.SET_MEMBERS,
  members
})

export const setMember = (member: UserDTO) => ({
  type: ActionsTypes.SET_MEMBER,
  member
})

export const setMemberProjects = (projects: ProjectPrivateDTO[]) => ({
  type: ActionsTypes.SET_PROJECTS,
  projects
})

export const setMemberAlerts = (alerts: AlertPrivateDTO[]) => ({
  type: ActionsTypes.SET_ALERTS,
  alerts
})

export const setMemberFavorites = (favorites: FavoritePrivateDTO[]) => ({
  type: ActionsTypes.SET_FAVORITES,
  favorites
})

export const setMemberWishes = (wishes: WishPrivateDTO[]) => ({
  type: ActionsTypes.SET_WISHES,
  wishes
})

export const removeUser = (idUser: string) => ({
  type: ActionsTypes.REMOVE_USER,
  idUser
})

export const setLoading = (loading: boolean) => ({
  type: ActionsTypes.SET_USER_LOADING,
  loading
})
