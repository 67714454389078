import { HomeConfigDTO } from '@vaneauneuf/dtos'
import { ActionsTypes } from './model'

export const setHomeConfig = (homeConfig: HomeConfigDTO) => ({
  type: ActionsTypes.SET_HOME_CONFIG,
  homeConfig
})

export const setLoading = (loading: boolean) => ({
  type: ActionsTypes.SET_HOME_CONFIG_LOADING,
  loading
})
