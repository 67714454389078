import React from 'react'
import { connect } from 'react-redux'
import { match as Match } from 'react-router-dom'
import UpdateForm from '../../components/UpdateForm/UpdateForm'
import { IRootState } from '../../redux'
import { getMember } from '../../redux/user'
import MemberAlertTable from './MemberAlertTable/MemberAlertTable'
import MemberFavoriteTable from './MemberFavoritesTable/MemberFavoriteTable'
import MemberFormsTable from './MemberFormsTable/MemberFormsTable'
import styles from './MemberPage.module.scss'
import MemberProjectTable from './MemberProjectTable/MemberProjectTable'
import MemberUpdateForm from './MemberUpdateForm/MemberUpdateForm'
import MemberWishTable from './MemberWishTable/MemberWishTable'

interface IMemberPageProps {
  match: Match<{ idMember: string }>
}

type IProps = ReturnType<typeof mapStateToProps> & IMemberPageProps

function MemberPage({ membre, id, match }: IProps) {
  return (
    <div className={styles.container}>
      <h1>Détails du membre</h1>
      <UpdateForm component={MemberUpdateForm} match={match} />
      <h2 className={styles.formTitle}>Formulaires remplis</h2>
      <MemberFormsTable forms={membre ? membre.forms : null} />
      <h2 className={styles.formTitle}>Projets</h2>
      <MemberProjectTable id={id} />
      <h2 className={styles.formTitle}>Alertes</h2>
      <MemberAlertTable id={id} />
      <h2 className={styles.formTitle}>Favoris</h2>
      <MemberFavoriteTable id={id} />
      <h2 className={styles.formTitle}>Souhaits</h2>
      <MemberWishTable id={id} />
    </div>
  )
}

const mapStateToProps = (state: IRootState, ownProps: IMemberPageProps) => ({
  membre: getMember(state, ownProps.match.params.idMember ? ownProps.match.params.idMember : ''),
  id: ownProps.match.params.idMember
})

export default connect(
  mapStateToProps,
  null
)(MemberPage)
