import { Country } from '@vaneauneuf/dtos'
import { push } from 'connected-react-router'
import { AnyAction, Dispatch } from 'redux'
import { loginRequest } from '../utils/api'
import { resetToken, setLoading, setLocale, setToken } from './actions'

export const login = (email: string, password: string, belgium: boolean) => async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatch(setLoading(true))
  try {
    const auth = await loginRequest(email, password)

    if (!auth.isAdmin) {
      throw new Error('Unauthorized')
    }

    localStorage.setItem('token', auth.token)
    localStorage.setItem(
      'country',
      belgium ? (Country.BELGIQUE as string) : (Country.FRANCE as string)
    )

    dispatch(setToken(auth.token))
    dispatch(setLocale(belgium ? Country.BELGIQUE : Country.FRANCE))

    dispatch(push('/'))
  } catch (e) {
    dispatch(setLoading(false))
    throw e
  }
  dispatch(setLoading(false))
}

export const logout = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch(resetToken())
  localStorage.removeItem('token')
  localStorage.removeItem('country')
}
