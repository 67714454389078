import {
  LandingPageCreationDTO,
  LandingPagePrivateDTO,
  LandingPageUpdateDTO
} from '@vaneauneuf/dtos'
import { Dispatch } from 'redux'
import { IRootState } from '..'
import { apiRequest } from '../utils/api'
import { addLanding, removeLanding, setLanding, setLandings, setLoading } from './actions'

export const fetchLandings = () => async (dispatch: Dispatch, getState: () => IRootState) => {
  dispatch(setLoading(true))
  try {
    const landings = await apiRequest<LandingPagePrivateDTO[]>(
      'GET',
      getState().authState.token,
      `/landing-page?enabledOnly=0&countries[]=${getState().authState.locale}`
    )
    dispatch(setLandings(landings))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const fetchLanding = (id: string) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const landing = await apiRequest<LandingPagePrivateDTO>(
      'GET',
      getState().authState.token,
      '/landing-page/' + id
    )
    dispatch(addLanding(landing))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const createLanding = (landing: LandingPageCreationDTO) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const newLanding = await apiRequest<LandingPagePrivateDTO>(
      'POST',
      getState().authState.token,
      '/landing-page',
      landing
    )
    dispatch(addLanding(newLanding))
  } catch (e) {
    dispatch(setLoading(false))
    // @ts-ignore
    throw e.response.data
  }
  dispatch(setLoading(false))
}

export const updateLanding = (id: string, landing: LandingPageUpdateDTO) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const landingUpdated = await apiRequest<LandingPagePrivateDTO>(
      'PATCH',
      getState().authState.token,
      '/landing-page/' + id,
      landing
    )
    dispatch(setLanding(landingUpdated))
  } catch (e) {
    dispatch(setLoading(false))
    // @ts-ignore
    throw e.response.data
  }
  dispatch(setLoading(false))
}

export const deleteLanding = (id: string) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    await apiRequest('DELETE', getState().authState.token, '/landing-page/' + id)
    dispatch(removeLanding(id))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const moveLanding = (id: string, order: number) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const landingUpdated = await apiRequest<LandingPagePrivateDTO>(
      'PATCH',
      getState().authState.token,
      '/landing-page/' + id,
      { id, order }
    )
    dispatch(setLanding(landingUpdated))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}
