import Input from 'antd/lib/input'
import React from 'react'

interface IOwnProps {
  value?: string[]
  onChange?: (newValue: string[]) => void
}

interface IChangeEvent {
  target: {
    value: string
  }
}

export const ArrayInput = ({ value = [], onChange }: IOwnProps) => {
  const onLocalChange = ({ target: { value: newVal } }: IChangeEvent, index: number) => {
    if (!onChange) {
      return
    }

    const newValue = [...value]
    if (newVal === '') {
      newValue.splice(index, 1)
    } else {
      newValue[index] = newVal
    }

    onChange(newValue)
  }

  return (
    <>
      {value.concat('').map((val, index) => (
        <Input key={index} value={val} onChange={event => onLocalChange(event, index)} />
      ))}
    </>
  )
}
