import { NewsCreationDTO, NewsTagCreationDTO, NewsTagPublicDTO } from '@vaneauneuf/dtos'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import message from 'antd/lib/message'
import Modal from 'antd/lib/modal'
import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IComponentFormCreateProps } from '../../../components/CreateForm/CreateForm'
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent'
import { IRootState } from '../../../redux'
import { createNewsTag, updateNewsTag } from '../../../redux/newsTag'
import ISuggestion from '../../../utils/mapBox'

interface IOwnProps {
  tag?: NewsTagPublicDTO
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IComponentFormCreateProps &
  IOwnProps

interface IState {
  confirmDirty: boolean
  errors: string[]
  dataSource: ISuggestion[]
}

class CreateForm extends React.Component<IProps, IState> {
  public state: IState = {
    confirmDirty: false,
    errors: [],
    dataSource: []
  }

  public render() {
    const { visible, hideModal, loading, form, tag } = this.props
    const { errors } = this.state
    const { getFieldDecorator } = form
    return (
      <Modal
        visible={visible}
        title="Créer un nouveau tag"
        okText="Sauvegarder"
        onCancel={hideModal}
        onOk={this.createUpdateNewsTag}
        confirmLoading={loading}
      >
        <Form>
          <Form.Item label="Slug">
            {getFieldDecorator('slug', {
              initialValue: tag && tag.slug ? tag.slug : undefined,
              rules: [
                {
                  required: true,
                  message: 'Veuillez entrer un slug valide',
                  pattern: new RegExp('^[a-z0-9]+(?:-[a-z0-9]+)*$')
                }
              ]
            })(<Input placeholder="Slug du tag" />)}
          </Form.Item>
        </Form>
        <ErrorComponent errors={errors} />
      </Modal>
    )
  }

  private createUpdateNewsTag = async () => {
    const { hideModal, form, tag } = this.props
    this.setState({ errors: [] })

    form.validateFieldsAndScroll(async (errors, fieldsValue: NewsCreationDTO) => {
      if (errors) {
        return
      }

      try {
        if (tag && tag.id) {
          await this.props.updateNewsTag(tag.id, fieldsValue)
        } else {
          await this.props.createNewsTag(fieldsValue)
        }
      } catch ({ errors }) {
        if (errors) {
          this.setState({ errors })
        }
        return
      }

      message.success("Le tag d'actualité a bien été créée")
      form.resetFields()
      hideModal()
    })
  }
}

const mapStateToProps = (state: IRootState) => ({
  loading: state.newsTagState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  createNewsTag: (newsTag: NewsTagCreationDTO) => dispatch(createNewsTag(newsTag)),
  updateNewsTag: (id: string, newsTag: NewsTagCreationDTO) => dispatch(updateNewsTag(id, newsTag))
})

const NewsTagCreateAndUpdateForm = Form.create({ name: 'newsTagList_create_update_form' })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateForm)
)

export default NewsTagCreateAndUpdateForm
