import { LotPrivateDTO } from '@vaneauneuf/dtos'

export enum ActionsTypes {
  SET_LOTS = 'SET_LOTS',
  SET_LOT = 'SET_LOT',
  ADD_LOT = 'ADD_LOT',
  REMOVE_LOT = 'REMOVE_LOT',
  SET_LOT_LOADING = 'SET_LOT_LOADING'
}

export interface ILotState {
  lots: LotPrivateDTO[]
  loading: boolean
}
