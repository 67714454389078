import { HomeConfigDTO } from '@vaneauneuf/dtos'

export enum ActionsTypes {
  SET_HOME_CONFIG = 'SET_HOME_CONFIG',
  SET_HOME_CONFIG_LOADING = 'SET_HOME_CONFIG_LOADING'
}

export interface IHomeConfigState {
  homeConfig: HomeConfigDTO
  loading: boolean
}
