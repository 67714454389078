import { PromoterCreationDTO } from '@vaneauneuf/dtos'
import Checkbox from 'antd/lib/checkbox'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import message from 'antd/lib/message'
import Modal from 'antd/lib/modal'
import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IComponentFormCreateProps } from '../../../components/CreateForm/CreateForm'
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent'
import { IRootState } from '../../../redux'
import { createPromoter } from '../../../redux/promoter'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IComponentFormCreateProps

interface IState {
  errors: string[]
}

class CreateForm extends React.Component<IProps, IState> {
  public state: IState = {
    errors: []
  }

  public render() {
    const { visible, hideModal, loading, form } = this.props
    const { errors } = this.state
    const { getFieldDecorator } = form
    return (
      <Modal
        visible={visible}
        title="Créer un nouveau promoteur"
        okText="Sauvegarder"
        onCancel={hideModal}
        onOk={this.createPromoter}
        confirmLoading={loading}
      >
        <Form>
          <Form.Item label="Nom">
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Veuillez renseigner le nom du promoteur' }]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="E-mail">{getFieldDecorator('email')(<Input type="email" />)}</Form.Item>
          <Form.Item label="Téléphone">
            {getFieldDecorator('phone')(<Input type="text" />)}
          </Form.Item>
          <Form.Item label="Description">
            {getFieldDecorator('description')(<Input.TextArea />)}
          </Form.Item>
          <Form.Item label="Site web">
            {getFieldDecorator('website', {
              rules: [{ type: 'url', message: "L'URL n'est pas valide" }]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Url du logo">
            {getFieldDecorator('imgLogo', {
              rules: [
                {
                  pattern: new RegExp(
                    // tslint:disable-next-line: max-line-length
                    '^(https://www.||https://)[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$'
                  ),
                  message: "L'URL n'est pas valide (https obligatoire)"
                }
              ]
            })(<Input />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('enabled', {
              initialValue: false
            })(<Checkbox>Enabled</Checkbox>)}
          </Form.Item>
        </Form>
        <ErrorComponent errors={errors} />
      </Modal>
    )
  }

  private createPromoter = async () => {
    const { hideModal, form, locale } = this.props
    this.setState({ errors: [] })

    form.validateFieldsAndScroll(async (errors, values: PromoterCreationDTO) => {
      values.country = locale

      if (errors) {
        return
      }

      try {
        await this.props.createPromoter(values)
      } catch ({ errors }) {
        if (errors) {
          this.setState({ errors })
        }
        return
      }

      message.success('Le promoteur a bien été créé.')
      form.resetFields()
      hideModal()
    })
  }
}

const mapStateToProps = (state: IRootState) => ({
  locale: state.authState.locale,
  loading: state.promoterState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  createPromoter: (promoter: PromoterCreationDTO) => dispatch(createPromoter(promoter))
})

const PromotersCreateForm = Form.create({ name: 'promoters_create_form' })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateForm)
)

export default PromotersCreateForm
