import { LotPrivateDTO } from '@vaneauneuf/dtos'
import Modal from 'antd/lib/modal'
import Table, { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { connect } from 'react-redux'
import { Link, match as Match } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import { deleteLot, fetchLotsByProgramId } from '../../../redux/lot'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ILotsTableProps

interface ILotsTableProps {
  match: Match<{ idProgram: string }>
}

class LotsTable extends React.Component<IProps> {
  private columns: Array<ColumnProps<LotPrivateDTO>> = [
    {
      title: 'Référence',
      dataIndex: 'reference',
      render: (name, record) => (
        <Link to={`/programs/${this.props.match.params.idProgram}/lots/${record.id}`}>{name}</Link>
      )
    },
    {
      title: 'Prix',
      dataIndex: 'price'
    },
    {
      title: 'Surface',
      dataIndex: 'surface'
    },
    {
      title: 'Etage',
      dataIndex: 'floor'
    },
    {
      title: 'Action',
      render: (text, record) => <a onClick={() => this.handleDelete(record.id)}>Supprimer</a>
    }
  ]

  public async componentDidMount() {
    await this.props.fetchLotsByProgramId(this.props.match.params.idProgram)
  }

  public render() {
    return <Table columns={this.columns} dataSource={this.props.lots} rowKey="id" />
  }

  private handleDelete = (id: string) => {
    Modal.confirm({
      title: 'Do you really want to delete this lot?',
      onOk: () => this.props.deleteLot(id)
    })
  }
}

const mapStateToProps = (state: IRootState) => ({
  lots: state.lotState.lots
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchLotsByProgramId: (id: string) => dispatch(fetchLotsByProgramId(id)),
  deleteLot: (id: string) => dispatch(deleteLot(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LotsTable)
