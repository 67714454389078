import AntTag from 'antd/lib/tag'
import CheckableTag from 'antd/lib/tag/CheckableTag'
import React from 'react'

interface ITagProps {
  title: string
  editable?: boolean
  checked?: boolean
  onChange?: (value: boolean) => void
}

export const Tag = (props: ITagProps) => {
  const { title, onChange, editable = false, checked = false } = props

  if (true !== editable) {
    return checked ? <AntTag>{title}</AntTag> : <></>
  }

  const onToggle = (newState: boolean) => {
    onChange && onChange(newState)
  }

  return (
    <CheckableTag checked={checked || false} onChange={onToggle}>
      {title}
    </CheckableTag>
  )
}
