import { deptExists, getRegionFromDepartment, regionExists } from '@vaneauneuf/dtos'
import axios from 'axios'
import settings from '../settings'

export default interface ISuggestion {
  id: string
  place_type: string[]
  text: string
  place_name: string
  center: [number, number]
  geometry: GeoJSON.Point
  address: string
  context: [{ id: string; short_code: string; text: string }]
  properties: { address: string }
}

export async function mapBoxSearch(param: string) {
  const result = await axios.get(
    `${settings.mapbox.baseUrl}/mapbox.places/${encodeURI(param)}.json?country=FR&access_token=${
      settings.mapbox.accessToken
    }`
  )

  return result.data
}

export const getFieldsValuefromLocation = (location: ISuggestion) => {
  const address = location.properties.address
  const city = location.context.find(context => context.id.includes('place'))
  const country = location.context.find(context => context.id.includes('country'))

  const fields = {
    street: location.id.includes('address')
      ? `${location.address} ${location.text}`
      : (address && address) || '',
    city: location.id.includes('place') ? location.text : (city && city.text) || '',
    country: location.id.includes('country') ? location.text : (country && country.text) || '',
    latitude: location.geometry.coordinates[1],
    longitude: location.geometry.coordinates[0]
  }

  let region = ''
  let department = ''

  let placeName =
    location.place_type[0] === 'region'
      ? location.text
      : (location.context.find(context => context.id.includes('region')) || { text: '' }).text

  if (placeName === 'Occitania') {
    placeName = 'Occitanie'
  }

  if (placeName) {
    if (regionExists(placeName)) {
      region = placeName
    } else if (deptExists(placeName)) {
      region = getRegionFromDepartment(placeName)
      department = placeName
    }
  }

  return { ...fields, region, department }
}
