import { ProgramTags, ProgramUpdateDTO } from '@vaneauneuf/dtos'
import { Checkbox } from 'antd'
import AutoComplete from 'antd/lib/auto-complete'
import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import TextArea from 'antd/lib/input/TextArea'
import message from 'antd/lib/message'
import Row from 'antd/lib/row'
import Select, { SelectValue } from 'antd/lib/select'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent'
import { Tag } from '../../../components/UI/Tag'
import { IComponentFormUpdateProps } from '../../../components/UpdateForm/UpdateForm'
import { IRootState } from '../../../redux'
import { fetchProgram, getProgram, updateProgram } from '../../../redux/program'
import { fetchPromoters } from '../../../redux/promoter'
import ISuggestion, { getFieldsValuefromLocation, mapBoxSearch } from '../../../utils/mapBox'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IComponentFormUpdateProps

interface IState {
  errors: string[]
  dataSource: ISuggestion[]
}

class UpdateForm extends React.Component<IProps, IState> {
  public state: IState = {
    errors: [],
    dataSource: []
  }

  public async componentDidMount() {
    if (!this.props.match.params.idProgram) {
      return
    }

    await this.props.fetchPromoters()
    await this.props.fetchProgram(this.props.match.params.idProgram)

    this.fillForm()
  }

  public render() {
    const { edit, loading, promoters, form } = this.props
    const { errors, dataSource } = this.state
    const { getFieldDecorator } = form
    return (
      <>
        <Form>
          <Form.Item label="Promoteur">
            {getFieldDecorator('promoterId', {
              rules: [{ required: false, message: 'Veuillez saisir le promoteur du programme' }]
            })(
              <Select disabled={!edit}>
                {promoters.map(promoter => (
                  <Select.Option key={promoter.id} value={promoter.id}>
                    {promoter.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Nom">
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Veuillez saisir le nom du programme' }]
            })(<Input disabled={!edit} />)}
          </Form.Item>
          <Form.Item label="Description">
            {getFieldDecorator('description', {
              rules: [{ required: true, message: 'Veuillez saisir la description du programme' }]
            })(<TextArea placeholder="Texte en format markdown" disabled={!edit} />)}
          </Form.Item>
          <Form.Item label="Adresse">
            {getFieldDecorator('address', {
              rules: [{ required: true, message: "Veuillez saisir l'adresse du programme" }]
            })(
              <AutoComplete
                onSearch={this.autocomplete}
                onSelect={this.setLocalisationFields}
                disabled={!edit}
              >
                {dataSource.map((data: ISuggestion) => (
                  <Select.Option key={data.id}>{data.place_name}</Select.Option>
                ))}
              </AutoComplete>
            )}
          </Form.Item>
          <Form.Item label="Rue">
            {getFieldDecorator('street', {
              rules: [{ required: true, message: 'Veuillez saisir la rue du programme' }]
            })(<Input disabled={!edit} />)}
          </Form.Item>
          <Form.Item label="Ville">
            {getFieldDecorator('city', {
              rules: [{ required: true, message: 'Veuillez saisir la ville du programme' }]
            })(<Input disabled={!edit} />)}
          </Form.Item>
          <Form.Item label="Code postal">
            {getFieldDecorator('postalCode', {
              rules: [{ required: true, message: 'Veuillez saisir le code postal du programme' }]
            })(<Input disabled={!edit} />)}
          </Form.Item>
          <Form.Item label="Pays">
            {getFieldDecorator('country', {
              rules: [{ required: true, message: 'Veuillez saisir le pays du programme' }]
            })(<Input disabled={!edit} />)}
          </Form.Item>
          <Form.Item label="Departement">
            {getFieldDecorator('department', {
              rules: [
                { required: true, message: 'Veuillez saisir le département du programme (ex : 95)' }
              ]
            })(<Input disabled={!edit} />)}
          </Form.Item>
          <Form.Item label="Région">
            {getFieldDecorator('region', {
              rules: [{ required: false, message: 'Veuillez saisir la région du programme' }]
            })(<Input disabled={!edit} />)}
          </Form.Item>
          <Form.Item label="Latitude">
            {getFieldDecorator('latitude', {
              rules: [{ required: true, message: 'Veuillez saisir la latitude du programme' }]
            })(<Input disabled={!edit} />)}
          </Form.Item>
          <Form.Item label="Longitude">
            {getFieldDecorator('longitude', {
              rules: [{ required: true, message: 'Veuillez saisir la longitude du programme' }]
            })(<Input disabled={!edit} />)}
          </Form.Item>
          <Form.Item label="Date de livraison">
            {getFieldDecorator('finishDate', {
              rules: [{ type: 'object', required: true, message: 'Veuillez saisir la date' }]
            })(<DatePicker disabled={!edit} showTime format="YYYY-MM-DD HH:mm:ss" />)}
          </Form.Item>
          <div className="ant-row ant-form-item">
            <div className="ant-form-item-label">
              <label>Tags</label>
            </div>
            <div className="ant-form-item-control-wrapper">
              <Row style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                {Object.entries(ProgramTags).map(([key, title]) => (
                  <Col key={key} style={{ marginBottom: '8px' }}>
                    {getFieldDecorator(key, {
                      valuePropName: 'checked'
                    })(<Tag title={title} editable={edit} />)}
                  </Col>
                ))}
              </Row>
            </div>
          </div>
          <Form.Item label="Ne pas mettre à jour ce programme via l'import">
            {getFieldDecorator('locked', {
              valuePropName: 'checked'
            })(<Checkbox disabled={!edit} />)}
          </Form.Item>
        </Form>
        <ErrorComponent errors={errors} />
        {edit && (
          <>
            <Button onClick={this.cancelEdit}>Cancel</Button>
            <Button
              type="primary"
              onClick={this.updateProgram}
              loading={loading}
              style={{ marginLeft: '1rem' }}
            >
              Save
            </Button>
          </>
        )}
      </>
    )
  }

  private fillForm = () => {
    const { program, form } = this.props

    form.setFieldsValue({
      promoterId: program.promoter ? program.promoter.id : null,
      name: program.name,
      description: program.description,
      address: `${program.street}, ${program.city}, ${program.department} ${program.postalCode}`,
      street: program.street,
      city: program.city,
      postalCode: program.postalCode,
      country: program.country,
      department: program.department,
      region: program.region,
      latitude: program.coordinates.coordinates[0],
      longitude: program.coordinates.coordinates[1],
      finishDate: moment(program.finishDate),
      liveSelection: program.liveSelection,
      investSelection: program.investSelection,
      reducedVat: program.reducedVat,
      closeToParis: program.closeToParis,
      pinel: program.pinel,
      profitability: program.profitability,
      greaterParis: program.greaterParis,
      locked: program.locked
    })
  }

  private autocomplete = async (value: string) => {
    if (value.length >= 3) {
      const res = await mapBoxSearch(value)
      this.setState({ dataSource: res.features })
    }
  }

  private setLocalisationFields = (value: SelectValue) => {
    const location = this.state.dataSource.find(loc => loc.id === value)

    if (location) {
      const fieldsValue = getFieldsValuefromLocation(location)
      this.props.form.setFieldsValue(fieldsValue)
    }
  }

  private updateProgram = async () => {
    const { stopEdit, program, form } = this.props
    this.setState({ errors: [] })

    form.validateFieldsAndScroll(async (errors, fieldsValue: ProgramUpdateDTO) => {
      if (errors) {
        return
      }

      const coordinates: GeoJSON.Point = {
        type: 'Point',
        coordinates: [(fieldsValue as any).latitude, (fieldsValue as any).longitude]
      }

      const values = {
        ...fieldsValue,
        finishDate: fieldsValue.finishDate,
        coordinates
      }

      try {
        await this.props.updateProgram(program.id, values)
      } catch ({ errors }) {
        if (errors) {
          this.setState({ errors })
        }
        return
      }

      message.success('Program was successfully updated')
      stopEdit()
    })
  }

  private cancelEdit = () => {
    this.props.stopEdit()
    this.fillForm()
  }
}

const mapStateToProps = (state: IRootState, ownProps: IComponentFormUpdateProps) => ({
  loading: state.programState.loading,
  program: getProgram(
    state,
    ownProps.match.params.idProgram ? ownProps.match.params.idProgram : ''
  ),
  promoters: state.promoterState.promoters
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchPromoters: () => dispatch(fetchPromoters()),
  fetchProgram: (id: string) => dispatch(fetchProgram(id)),
  updateProgram: (id: string, program: ProgramUpdateDTO) => dispatch(updateProgram(id, program))
})

const ProgramUpdateForm = Form.create({ name: 'program_update_form' })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UpdateForm)
)

export default ProgramUpdateForm
