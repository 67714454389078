import {
  FiscalTools,
  HeatingTypes,
  LotSubTypes,
  LotTypes,
  LotUpdateDTO,
  ParkingTypes,
  PinelZones
} from '@vaneauneuf/dtos'
import Button from 'antd/lib/button'
import Checkbox from 'antd/lib/checkbox'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import InputNumber from 'antd/lib/input-number'
import TextArea from 'antd/lib/input/TextArea'
import message from 'antd/lib/message'
import Select from 'antd/lib/select'
import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent'
import { IComponentFormUpdateProps } from '../../../components/UpdateForm/UpdateForm'
import { IRootState } from '../../../redux'
import { fetchLot, updateLot } from '../../../redux/lot'
import { getLot } from '../../../redux/lot/selectors'
import styles from './LotUpdateForm.module.scss'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IComponentFormUpdateProps

interface IState {
  errors: string[]
}

class UpdateForm extends React.Component<IProps, IState> {
  public state: IState = {
    errors: []
  }

  public async componentDidMount() {
    if (!this.props.match.params.idLot) {
      return
    }

    await this.props.fetchLot(this.props.match.params.idLot)
    this.fillForm()
  }

  public render() {
    const { edit, loading, form } = this.props
    const { errors } = this.state
    const { getFieldDecorator } = form
    return (
      <>
        <Form>
          <Form.Item label="Réference">
            {getFieldDecorator('reference', {
              rules: [{ required: true, message: 'Veuillez saisir la référence du lot' }]
            })(<Input disabled={!edit} />)}
          </Form.Item>
          <Form.Item label="Prix">
            {getFieldDecorator('price', {
              rules: [{ required: true, message: 'Veuillez saisir le prix du lot' }]
            })(<InputNumber disabled={!edit} className={styles.inputNumber} />)}
          </Form.Item>
          <Form.Item label="Surface">
            {getFieldDecorator('surface', {
              rules: [{ required: true, message: 'Veuillez saisir la surface du lot (m²)' }]
            })(<InputNumber disabled={!edit} className={styles.inputNumber} />)}
          </Form.Item>
          <Form.Item label="Etage">
            {getFieldDecorator('floor', {
              rules: [{ required: true, message: "Veuillez saisir l'étage du lot" }]
            })(<InputNumber disabled={!edit} className={styles.inputNumber} />)}
          </Form.Item>
          <Form.Item label="Type">
            {getFieldDecorator('type', {
              rules: [{ required: true, message: 'Veuillez saisir le type du lot' }]
            })(
              <Select disabled={!edit}>
                {Object.values(LotTypes).map(lotType => (
                  <Select.Option key={lotType} value={lotType}>
                    {lotType}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Sous-Type">
            {getFieldDecorator('subType')(
              <Select disabled={!edit}>
                {Object.values(LotSubTypes).map(lotSubType => (
                  <Select.Option key={lotSubType} value={lotSubType}>
                    {lotSubType}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Outil fiscal">
            {getFieldDecorator('fiscalTool')(
              <Select disabled={!edit}>
                {Object.values(FiscalTools).map(fiscalTool => (
                  <Select.Option key={fiscalTool} value={fiscalTool}>
                    {fiscalTool}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Zone pinel">
            {getFieldDecorator('pinelZone')(
              <Select disabled={!edit}>
                {Object.values(PinelZones).map(pinelZone => (
                  <Select.Option key={pinelZone} value={pinelZone}>
                    {pinelZone}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Type de parking">
            {getFieldDecorator('parkingType')(
              <Select disabled={!edit}>
                {Object.values(ParkingTypes).map(parkingType => (
                  <Select.Option key={parkingType} value={parkingType}>
                    {parkingType}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Type de chauffage">
            {getFieldDecorator('heatingType')(
              <Select allowClear disabled={!edit}>
                {Object.values(HeatingTypes).map(heatingType => (
                  <Select.Option key={heatingType} value={heatingType}>
                    {heatingType}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Exposition">
            {getFieldDecorator('northExposition', {
              initialValue: false,
              valuePropName: 'checked'
            })(<Checkbox disabled={!edit}>Nord</Checkbox>)}
            {getFieldDecorator('southExposition', {
              initialValue: false,
              valuePropName: 'checked'
            })(<Checkbox disabled={!edit}>Sud</Checkbox>)}
            {getFieldDecorator('eastExposition', {
              initialValue: false,
              valuePropName: 'checked'
            })(<Checkbox disabled={!edit}>Est</Checkbox>)}
            {getFieldDecorator('westExposition', {
              initialValue: false,
              valuePropName: 'checked'
            })(<Checkbox disabled={!edit}>Ouest</Checkbox>)}
          </Form.Item>
          <Form.Item label="Nombre de pièces">
            {getFieldDecorator('roomsCount', {
              rules: [{ required: true, message: 'Veuillez saisir le nombre de pièces du lot' }]
            })(<InputNumber disabled={!edit} className={styles.inputNumber} />)}
          </Form.Item>
          <Form.Item label="Nombre d'extérieurs">
            {getFieldDecorator('exteriorsCount')(
              <InputNumber disabled={!edit} className={styles.inputNumber} />
            )}
          </Form.Item>
          <Form.Item label="Régulation thermique">
            {getFieldDecorator('bbc', {
              initialValue: false,
              valuePropName: 'checked'
            })(<Checkbox disabled={!edit}>BBC</Checkbox>)}
            {getFieldDecorator('RT2012', {
              initialValue: false,
              valuePropName: 'checked'
            })(<Checkbox disabled={!edit}>RT2012</Checkbox>)}
          </Form.Item>
          <Form.Item label="Caractéristiques">
            {getFieldDecorator('Ascenseur', {
              initialValue: false,
              valuePropName: 'checked'
            })(<Checkbox disabled={!edit}>Ascenseur</Checkbox>)}
            {getFieldDecorator('PTZ', {
              initialValue: false,
              valuePropName: 'checked'
            })(<Checkbox disabled={!edit}>PTZ</Checkbox>)}
          </Form.Item>
          <Form.Item label="Nombre de caves">
            {getFieldDecorator('basementNb')(
              <InputNumber disabled={!edit} className={styles.inputNumber} />
            )}
          </Form.Item>
          <Form.Item label="Surface de la cave (m²)">
            {getFieldDecorator('basementSurface')(
              <InputNumber disabled={!edit} className={styles.inputNumber} />
            )}
          </Form.Item>
          <Form.Item label="Nombre de balcons">
            {getFieldDecorator('balconyNb')(
              <InputNumber disabled={!edit} className={styles.inputNumber} />
            )}
          </Form.Item>
          <Form.Item label="Surface du balcon (m²)">
            {getFieldDecorator('balconySurface')(
              <InputNumber disabled={!edit} className={styles.inputNumber} />
            )}
          </Form.Item>
          <Form.Item label="Nombre de terasses">
            {getFieldDecorator('terraceNb')(
              <InputNumber disabled={!edit} className={styles.inputNumber} />
            )}
          </Form.Item>
          <Form.Item label="Surface de la terrasse">
            {getFieldDecorator('terraceSurface')(
              <InputNumber disabled={!edit} className={styles.inputNumber} />
            )}
          </Form.Item>
          <Form.Item label="Nombre de jardins">
            {getFieldDecorator('yardNb')(
              <InputNumber disabled={!edit} className={styles.inputNumber} />
            )}
          </Form.Item>
          <Form.Item label="Surface des jardins">
            {getFieldDecorator('yardSurface')(
              <InputNumber disabled={!edit} className={styles.inputNumber} />
            )}
          </Form.Item>
        </Form>
        <ErrorComponent errors={errors} />
        {edit && (
          <>
            <Button onClick={this.cancelEdit}>Annuler</Button>
            <Button
              type="primary"
              onClick={this.updateLot}
              loading={loading}
              style={{ marginLeft: '1rem' }}
            >
              Sauvegarder
            </Button>
          </>
        )}
      </>
    )
  }

  private fillForm = () => {
    const { lot, form } = this.props
    form.setFieldsValue({
      reference: lot.reference,
      price: lot.price,
      surface: lot.surface,
      floor: lot.floor,
      type: lot.type,
      subType: lot.subType,
      fiscalTool: lot.fiscalTool,
      pinelZone: lot.pinelZone,
      parkingType: lot.parkingType,
      heatingType: lot.heatingType,
      northExposition: lot.northExposition,
      southExposition: lot.southExposition,
      eastExposition: lot.eastExposition,
      westExposition: lot.westExposition,
      roomsCount: lot.roomsCount,
      exteriorsCount: lot.exteriorsCount,
      bbc: lot.bbc,
      RT2012: lot.RT2012,
      elevator: lot.elevator,
      zeroLoan: lot.zeroLoan,
      basementNb: lot.basementNb,
      basementSurface: lot.basementSurface,
      balconyNb: lot.balconyNb,
      balconySurface: lot.balconySurface,
      terraceNb: lot.terraceNb,
      terraceSurface: lot.terraceSurface,
      yardNb: lot.yardNb,
      yardSurface: lot.yardSurface
    })
  }

  private updateLot = async () => {
    const { stopEdit, lot, form } = this.props
    this.setState({ errors: [] })

    form.validateFieldsAndScroll(async (errors, values: LotUpdateDTO) => {
      if (errors) {
        return
      }

      try {
        await this.props.updateLot(lot.id, values)
      } catch ({ errors }) {
        if (errors) {
          this.setState({ errors })
        }
        return
      }

      message.success('Le lot a bien été mis à jour')
      stopEdit()
    })
  }

  private cancelEdit = () => {
    this.props.stopEdit()
    this.fillForm()
  }
}

const mapStateToProps = (state: IRootState, ownProps: IComponentFormUpdateProps) => ({
  loading: state.lotState.loading,
  lot: getLot(state, ownProps.match.params.idLot ? ownProps.match.params.idLot : '')
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchLot: (id: string) => dispatch(fetchLot(id)),
  updateLot: (id: string, lot: LotUpdateDTO) => dispatch(updateLot(id, lot))
})

const LotUpdateForm = Form.create({ name: 'lot_update_form' })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UpdateForm)
)

export default LotUpdateForm
