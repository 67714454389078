const getMeetingSupportLabel = (support: any) => {
  switch (support) {
    case 'PHONE':
      return 'Par téléphone'
    case 'VISIO':
      return 'Par Visio-conférence'
    case 'HOME':
      return 'A son domicile'
    case 'AGENCY':
      return 'A notre agence'
    default:
      return ''
  }
}

export default getMeetingSupportLabel
