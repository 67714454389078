import { createReducer } from '../utils'
import { addAdmin, removeAdmin, setAdmin, setAdmins, setLoading } from './actions'
import { ActionsTypes, IAdminsState } from './model'

const initialState: IAdminsState = {
  admins: [],
  loading: false
}

const actionHandlers = {
  [ActionsTypes.SET_ADMINS]: (
    state: IAdminsState,
    { admins }: ReturnType<typeof setAdmins>
  ): IAdminsState => ({
    ...state,
    admins
  }),
  [ActionsTypes.SET_ADMIN]: (
    state: IAdminsState,
    { admin }: ReturnType<typeof setAdmin>
  ): IAdminsState => ({
    ...state,
    admins: [...state.admins.filter(currentAdmin => currentAdmin.id !== admin.id), admin]
  }),
  [ActionsTypes.ADD_ADMIN]: (
    state: IAdminsState,
    { admin }: ReturnType<typeof addAdmin>
  ): IAdminsState => ({
    ...state,
    admins: [...state.admins, admin]
  }),
  [ActionsTypes.REMOVE_ADMIN]: (
    state: IAdminsState,
    { idAdmin }: ReturnType<typeof removeAdmin>
  ): IAdminsState => ({
    ...state,
    admins: [...state.admins].filter(admin => admin.id !== idAdmin)
  }),
  [ActionsTypes.SET_ADMIN_LOADING]: (
    state: IAdminsState,
    { loading }: ReturnType<typeof setLoading>
  ): IAdminsState => ({
    ...state,
    loading
  })
}

export default createReducer(initialState, actionHandlers)
