import { GlobalConfigDTO, GlobalConfigUpdateDTO } from '@vaneauneuf/dtos'
import { Button, Form, InputNumber, message } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import SeoFooterForm from '../../../components/SeoFooterForm/SeoFooterForm'
import Wysiwyg from '../../../components/Wysiwyg/Wysiwyg'
import { IRootState } from '../../../redux'
import { updateGlobalConfig } from '../../../redux/GlobalConfig'
import styles from './GlobalConfigUpdateForm.module.scss'

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const UpdateForm: React.FC<FormComponentProps & IProps> = ({
  form,
  globalConfig,
  updateGlobalConfigStore
}) => {
  const { getFieldDecorator } = form

  const fillForm = () => {
    form.setFieldsValue({
      limitSimulationMember: globalConfig.limitSimulationMember,
      limitSimulationVisitor: globalConfig.limitSimulationVisitor,
      searchSeoText: globalConfig.searchSeoText,
      homeSeoFooterText: globalConfig.homeSeoFooterText,
      homeSeoFooterLinks: globalConfig.homeSeoFooterLinks,
      searchSeoFooterText: globalConfig.searchSeoFooterText,
      searchSeoFooterLinks: globalConfig.searchSeoFooterLinks
    })
  }

  const sendForm = () => {
    form.validateFieldsAndScroll(async (errors, values: GlobalConfigDTO) => {
      if (!errors) {
        await updateGlobalConfigStore(globalConfig.id, {
          ...values
        })
        message.success('la configuration a bien été sauvegardée.')
      }
    })
  }

  useEffect(() => {
    fillForm()
  }, [globalConfig])

  return (
    <>
      <Form>
        <Form.Item
          label="Nombre limite de simulation membre
         n'ayant pas rempli de formulaire de contact"
        >
          {getFieldDecorator('limitSimulationMember')(<InputNumber />)}
        </Form.Item>
        <Form.Item
          label="Nombre limite de simulation visiteur
         n'ayant pas rempli de formulaire de contact"
        >
          {getFieldDecorator('limitSimulationVisitor')(<InputNumber />)}
        </Form.Item>
        <Form.Item label="Texte SEO recherche">
          {getFieldDecorator('searchSeoText')(<Wysiwyg />)}
          <div>
            <span className={styles.icon}>&#9432;</span> Vous pouvez insérer dans le texte ci-dessus
            les expressions suivantes, elles seront remplacées par les valeurs adéquates lors de
            l'affichage sur la page de recherche :
            <ul>
              <li className={styles.listItem}>
                <b>[ville]</b>: la ville courante
              </li>
              <li className={styles.listItem}>
                <b>[departement]</b>: le département courant
              </li>
              <li className={styles.listItem}>
                <b>[region]</b>: la région courante
              </li>
            </ul>
          </div>
        </Form.Item>
        <SeoFooterForm
          fieldTitle="Homepage"
          getFieldDecorator={getFieldDecorator}
          seoFooterLinksName="homeSeoFooterLinks"
          seoFooterTextName="homeSeoFooterText"
          disabled={false}
        />
        <SeoFooterForm
          fieldTitle="Recherche"
          getFieldDecorator={getFieldDecorator}
          seoFooterLinksName="searchSeoFooterLinks"
          seoFooterTextName="searchSeoFooterText"
          disabled={false}
        />
      </Form>
      <Button type="primary" onClick={() => sendForm()} style={{ marginLeft: '1rem' }}>
        Sauvegarder
      </Button>
    </>
  )
}

const mapStateToProps = (state: IRootState) => ({
  globalConfig: state.globalState.globalConfig
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  updateGlobalConfigStore: (id: string, globalConfig: GlobalConfigUpdateDTO) =>
    dispatch(updateGlobalConfig(id, globalConfig))
})

const GlobalConfigUpdateForm = Form.create({ name: 'global_config_update' })(UpdateForm)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalConfigUpdateForm)
