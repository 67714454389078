"use strict";

var _classCallCheck = require("/srv/packages/backoffice/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var formTypes_1 = require("./formTypes");

var class_validator_1 = require("class-validator");

var class_transformer_1 = require("class-transformer");

var formData_1 = require("./formData");

var UserFormCreationDTO = function UserFormCreationDTO() {
  _classCallCheck(this, UserFormCreationDTO);
};

__decorate([class_validator_1.IsEnum(formTypes_1.FormTypes), __metadata("design:type", String)], UserFormCreationDTO.prototype, "type", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], UserFormCreationDTO.prototype, "formOrig", void 0);

__decorate([class_transformer_1.Type(function () {
  return formData_1.FormDataDTO;
}), class_validator_1.Allow(), __metadata("design:type", Object)], UserFormCreationDTO.prototype, "formData", void 0);

exports.UserFormCreationDTO = UserFormCreationDTO;