import {
  PaginationRequest,
  UserFilterDTO,
  UserFormCreationDTO,
  UserFormDTO
} from '@vaneauneuf/dtos'
import Modal from 'antd/lib/modal'
import Table, { ColumnProps, PaginationConfig, SorterResult } from 'antd/lib/table'
import React, { Dispatch } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import { deleteUser, fetchContacts } from '../../../redux/user'
import getMeetingSupportLabel from '../../../utils/getMeetingSupportLabel'
import { PAGE_SIZE } from '../ContactsPage'

interface IContactTableProps {
  filters: UserFilterDTO
  onUpdateFilters: Dispatch<React.SetStateAction<UserFilterDTO & PaginationRequest>>
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IContactTableProps

class ContactsTable extends React.Component<IProps> {
  private columns: Array<ColumnProps<UserFormDTO>> = [
    {
      title: 'Email',
      key: 'email',
      render: contact => (contact.email ? contact.email : '')
    },
    {
      title: 'Nom',
      key: 'name',
      dataIndex: 'name',
      render: (contact, record) => `${record.lastName || ''} ${record.firstName || ''}`
    },
    {
      title: 'Téléphone',
      render: contact => (contact.phone ? contact.phone : '')
    },
    {
      title: 'Membre ?',
      render: contact => (contact.isMember ? 'Oui' : 'Non')
    },
    {
      title: 'Date de création',
      dataIndex: 'createDate',
      render: (contact, record) => new Date(record.createDate).toLocaleString(),
      sorter: true,
      defaultSortOrder: 'descend'
    },
    {
      title: 'Disponibilités',
      render: contact => {
        const dispo = []
        const contactForm = contact.forms.find((f: UserFormCreationDTO) =>
          f.formData.hasOwnProperty('now')
        )
        const data = contactForm ? contactForm.formData : {}
        if (data.now) dispo.push('Immédiatement')
        if (data.morning) dispo.push('Matinée')
        if (data.afternoon) dispo.push('Après-midi')
        if (data.dayEnd) dispo.push('Soirée')
        return dispo.join(', ')
      }
    },
    {
      title: 'Accepte offres',
      render: contact => {
        const contactForm = contact.forms.find((f: any) => f.formData.offers)
        const data = contactForm ? contactForm.formData : {}
        return data.offers ? 'Oui' : 'Non'
      }
    },
    {
      title: 'Nb formulaires remplis',
      render: contact => (contact.forms ? contact.forms.length : 0)
    },
    {
      title: 'Rdv(s) demandé(s)',
      render: contact => {
        // return last contactForm with meeting filled by user
        const meetingForms = contact.forms.filter((f: any) => f.formData.meetingDate)

        if (meetingForms.length === 0) return 'Non'

        return `${getMeetingSupportLabel(meetingForms[0].formData.support)} ${new Date(
          meetingForms[0].formData.meetingDate
        ).toLocaleString()} `
      }
    },
    {
      title: 'Détails',
      dataIndex: 'id',
      render: (name, record) => <Link to={'/contacts/' + record.id}>Voir plus</Link>
    },
    {
      title: 'Action',
      render: (text, record) => <a onClick={() => this.handleDelete(record.id)}>Supprimer</a>
    }
  ]

  public async componentDidMount() {
    await this.props.fetchContacts({
      take: PAGE_SIZE,
      order: { createDate: 'DESC' },
      onlyContact: true
    })
  }

  public render() {
    return (
      <>
        <Table
          columns={this.columns}
          dataSource={this.props.contacts}
          rowKey="id"
          pagination={{ total: this.props.total, defaultPageSize: PAGE_SIZE }}
          onChange={this.handleTableChange}
          loading={this.props.loading}
        />
      </>
    )
  }

  private handleDelete = (id: string) => {
    Modal.confirm({
      title: 'Voulez vous vraiment supprimer ce contact?',
      onOk: () => this.props.deleteUser(id)
    })
  }

  private handleTableChange = (
    pagination: PaginationConfig,
    filters: any,
    sorter: SorterResult<UserFormDTO>
  ) => {
    this.props.fetchContacts({
      ...this.props.filters,
      skip: pagination.current ? (pagination.current - 1) * PAGE_SIZE : 0,
      take: PAGE_SIZE,
      order: { [sorter.field]: sorter.order === 'ascend' ? 'ASC' : 'DESC' }
    })

    this.props.onUpdateFilters({
      ...this.props.filters,
      skip: pagination.current ? (pagination.current - 1) * PAGE_SIZE : 0,
      take: PAGE_SIZE,
      order: { [sorter.field]: sorter.order === 'ascend' ? 'ASC' : 'DESC' }
    })
  }
}

const mapStateToProps = (state: IRootState) => ({
  contacts: state.userState.contacts,
  total: state.userState.total,
  loading: state.userState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchContacts: (queryParams: UserFilterDTO & PaginationRequest) =>
    dispatch(fetchContacts(queryParams)),
  deleteUser: (id: string) => dispatch(deleteUser(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactsTable)
