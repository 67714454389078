import { AuthDTO } from '@vaneauneuf/dtos'
import axios, { AxiosError } from 'axios'
import { browserHistory } from '../../redux'
import settings from '../../settings'

export const loginRequest = async (email: string, password: string) => {
  const response = await axios.post<AuthDTO>(`${settings.api.baseUrl}/account/login`, {
    email,
    password
  })

  return response.data
}

export const apiRequest = async <T = void>(
  method: string,
  token: string | null,
  url: string,
  payload?: object,
  params?: object,
  headers?: object
) => {
  if (!token) throw new Error('token is null')

  axios.interceptors.response.use(undefined, errorInterceptor)

  if (payload) payload = removeEmptyString(payload as Record<string, string | string[] | undefined>)

  const response = await axios.request<T>({
    method,
    url: `${settings.api.baseUrl}${url}`,
    headers: { Authorization: `Bearer ${token}`, 'Cache-Control': `no-cache`, ...headers },
    ...{ data: payload },
    ...{ params }
  })

  return response.data
}

const errorInterceptor = (error: AxiosError) => {
  if (error.response && error.response.status === 401) {
    browserHistory.push('/login')
  }
  return Promise.reject(error)
}

const removeEmptyString = (o: Record<string, string | string[] | undefined>) => {
  Object.keys(o).forEach((key: string) => {
    if ((o[key] as string) === '') delete o[key]
  })
  return o
}
