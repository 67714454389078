import Button from 'antd/lib/button'
import React from 'react'
import { apiRequest } from '../../../redux/utils/api'
import styles from './ContactsExport.module.scss'

function ContactsExport() {
  const token = localStorage.getItem('token')

  const download = async () => {
    const contacts = await apiRequest<string>('GET', token, '/user/contact/export')
    dlFile(contacts)
  }

  const dlFile = (contacts: string) => {
    const link = document.createElement('a')
    // \ufeff indicate utf-8
    link.href = window.URL.createObjectURL(new Blob(['\ufeff', contacts], { type: 'text/csv' }))
    link.setAttribute('download', 'export-contacts.csv')

    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div className={styles.exportButtonContainer}>
      {/* href={`${settings.api.baseUrl}/user/contact/export`} */}
      <Button type="primary" onClick={download}>
        Exporter les contacts
      </Button>
    </div>
  )
}

export default ContactsExport
