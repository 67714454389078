import { PromoterCreationDTO, PromoterPrivateDTO, PromoterUpdateDTO } from '@vaneauneuf/dtos'
import { Dispatch } from 'redux'
import { IRootState } from '..'
import { apiRequest } from '../utils/api'
import { addPromoter, removePromoter, setLoading, setPromoter, setPromoters } from './actions'

export const fetchPromoters = () => async (dispatch: Dispatch, getState: () => IRootState) => {
  dispatch(setLoading(true))
  try {
    const promoters = await apiRequest<{ data: PromoterPrivateDTO[]; total: number }>(
      'GET',
      getState().authState.token,
      `/promoter?countries[]=${getState().authState.locale}`
    )
    dispatch(setPromoters(promoters.data))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const fetchPromoter = (id: string) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const promoter = await apiRequest<PromoterPrivateDTO>(
      'GET',
      getState().authState.token,
      '/promoter/' + id
    )
    dispatch(setPromoter(promoter))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const createPromoter = (promoter: PromoterCreationDTO) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const newPromoter = await apiRequest<PromoterPrivateDTO>(
      'POST',
      getState().authState.token,
      '/promoter',
      promoter
    )
    dispatch(addPromoter(newPromoter))
  } catch (e) {
    dispatch(setLoading(false))
    throw e.response.data
  }
  dispatch(setLoading(false))
}

export const updatePromoter = (id: string, promoter: PromoterUpdateDTO) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const promoterUpdated = await apiRequest<PromoterPrivateDTO>(
      'PATCH',
      getState().authState.token,
      '/promoter/' + id,
      promoter
    )
    dispatch(setPromoter(promoterUpdated))
  } catch (e) {
    dispatch(setLoading(false))
    throw e.response.data
  }
  dispatch(setLoading(false))
}

export const deletePromoter = (id: string) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    await apiRequest('DELETE', getState().authState.token, '/promoter/' + id)
    dispatch(removePromoter(id))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}
