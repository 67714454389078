import React from 'react'
import { match as Match } from 'react-router-dom'
import CreateForm from '../../components/CreateForm/CreateForm'
import ProgramMedias from '../../components/ProgramMedias/ProgramMedias'
import UpdateForm from '../../components/UpdateForm/UpdateForm'
import LotsCreateForm from './LotsCreateForm/LotsCreateForm'
import LotsTable from './LotsTable/LotsTable'
import styles from './ProgramPage.module.scss'
import ProgramUpdateForm from './ProgramUpdateForm/ProgramUpdateForm'

interface IProps {
  match: Match<{ idProgram: string }>
}

const ProgramPage: React.FunctionComponent<IProps> = props => (
  <div className={styles.container}>
    <h1>Détail du programme</h1>
    <UpdateForm component={ProgramUpdateForm} match={props.match} />
    {/* <h2>Médias</h2>
    <AddMediaComponent idProgram={props.match.params.idProgram} field="bookletId" /> */}

    <ProgramMedias match={props.match} />
    <h2 className={styles.lotTitle}>Lots</h2>
    <CreateForm entity="lot" component={LotsCreateForm} match={props.match} />

    <LotsTable match={props.match} />
  </div>
)

export default ProgramPage
