import { WishFilterDTO } from '@vaneauneuf/dtos'
import React, { useState } from 'react'
import WishesFilter from './WishesFilter/WishesFilter'
import styles from './WishesPage.module.scss'
import WishesTable from './WishesTable/WishesTable'

export const PAGE_SIZE = 50

const WishesPage: React.FunctionComponent = () => {
  const [filters, setFilters] = useState<WishFilterDTO>({
    name: '',
    reference: '',
    email: '',
    answer: undefined
  })

  return (
    <div className={styles.container}>
      <h1>Demandes de disponibilité</h1>
      <WishesFilter filters={filters} onUpdateFilters={setFilters} />
      <WishesTable filters={filters} />
    </div>
  )
}

export default WishesPage
