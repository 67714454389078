import Button from 'antd/lib/button'
import AntLayout from 'antd/lib/layout'
import Modal from 'antd/lib/modal'
import * as React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import { logout } from '../../../redux/auth'
import styles from './Header.module.scss'

type IProps = ReturnType<typeof mapDispatchToProps>

class Header extends React.PureComponent<IProps> {
  public render() {
    return (
      <AntLayout.Header className={styles.header}>
        <Button type="danger" onClick={this.handleLogout}>
          Déconnexion
        </Button>
      </AntLayout.Header>
    )
  }

  private handleLogout = () => {
    Modal.confirm({
      title: 'Voulez-vous vraiment vous déconnecter?',
      onOk: () => this.props.logout()
    })
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  logout: () => dispatch(logout())
})

export default connect(
  null,
  mapDispatchToProps
)(Header)
