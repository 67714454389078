import { createReducer } from '../utils'
import { addLot, removeLot, setLoading, setLot, setLots } from './actions'
import { ActionsTypes, ILotState } from './model'

const initialState: ILotState = {
  lots: [],
  loading: false
}

const actionHandlers = {
  [ActionsTypes.SET_LOTS]: (state: ILotState, { lots }: ReturnType<typeof setLots>): ILotState => ({
    ...state,
    lots
  }),
  [ActionsTypes.SET_LOT]: (state: ILotState, { lot }: ReturnType<typeof setLot>): ILotState => ({
    ...state,
    lots: [...state.lots.filter(currentLot => currentLot.id !== lot.id), lot]
  }),
  [ActionsTypes.ADD_LOT]: (state: ILotState, { lot }: ReturnType<typeof addLot>): ILotState => ({
    ...state,
    lots: [...state.lots, lot]
  }),
  [ActionsTypes.REMOVE_LOT]: (
    state: ILotState,
    { idLot }: ReturnType<typeof removeLot>
  ): ILotState => ({
    ...state,
    lots: [...state.lots].filter(lot => lot.id !== idLot)
  }),
  [ActionsTypes.SET_LOT_LOADING]: (
    state: ILotState,
    { loading }: ReturnType<typeof setLoading>
  ): ILotState => ({
    ...state,
    loading
  })
}

export default createReducer(initialState, actionHandlers)
