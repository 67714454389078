import {
  PaginationRequest,
  ProgramCreationDTO,
  ProgramFilterDTO,
  ProgramPrivateDTO,
  ProgramUpdateDTO
} from '@vaneauneuf/dtos'
import { Dispatch } from 'redux'
import { IRootState } from '..'
import { apiRequest } from '../utils/api'
import { addProgram, removeProgram, setLoading, setProgram, setPrograms } from './actions'

export const fetchPrograms = (queryParams: PaginationRequest & ProgramFilterDTO) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const programs = await apiRequest<{ data: ProgramPrivateDTO[]; total: number }>(
      'GET',
      getState().authState.token,
      `/program?countries[]=${getState().authState.locale}`,
      undefined,
      {
        ...queryParams,
        withPromoters: true,
        allowEmptyPrograms: true,
        cityInclude: true,
        allowDisabledPromoters: true
      }
    )
    dispatch(setPrograms(programs))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const fetchProgram = (id: string) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const program = await apiRequest<ProgramPrivateDTO>(
      'GET',
      getState().authState.token,
      '/program/' + id
    )
    dispatch(setProgram(program))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const createProgram = (program: ProgramCreationDTO) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const newProgram = await apiRequest<ProgramPrivateDTO>(
      'POST',
      getState().authState.token,
      '/program',
      program
    )
    dispatch(addProgram(newProgram))
  } catch (e) {
    dispatch(setLoading(false))
    throw e.response.data
  }
  dispatch(setLoading(false))
}

export const createMedia = (file: any) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const formData = new FormData()
    formData.append('file', file)

    const media = await apiRequest<any>(
      'POST',
      getState().authState.token,
      '/media',
      formData,
      undefined,
      {
        'content-type': 'multipart/form-data'
      }
    )
    dispatch(setLoading(false))
    return media
  } catch (e) {
    console.error(e)
    dispatch(setLoading(false))
    throw e.response.data
  }
}

export const deleteMedia = (medialUrl: string) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  const match = medialUrl && medialUrl.match(/[^\/]+$/)
  const medialId = match && match.length > 0 && match[0]

  try {
    await apiRequest('DELETE', getState().authState.token, '/media/' + medialId)

    dispatch(setLoading(false))
    return true
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const updateProgram = (id: string, program: ProgramUpdateDTO) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const programUpdated = await apiRequest<ProgramPrivateDTO>(
      'PATCH',
      getState().authState.token,
      '/program/' + id,
      program
    )
    dispatch(setProgram(programUpdated))
  } catch (e) {
    dispatch(setLoading(false))
    throw e.response.data
  }
  dispatch(setLoading(false))
}

export const deleteProgram = (id: string) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    await apiRequest('DELETE', getState().authState.token, '/program/' + id)
    dispatch(removeProgram(id))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}
