"use strict";

var _classCallCheck = require("/srv/packages/backoffice/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

var lot_1 = require("./lot");

var program_1 = require("./program");

var AlertPrivateDTO = function AlertPrivateDTO() {
  _classCallCheck(this, AlertPrivateDTO);
};

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], AlertPrivateDTO.prototype, "id", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], AlertPrivateDTO.prototype, "region", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], AlertPrivateDTO.prototype, "department", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], AlertPrivateDTO.prototype, "city", void 0);

__decorate([class_validator_1.IsEnum(lot_1.FiscalTools), __metadata("design:type", String)], AlertPrivateDTO.prototype, "fiscalTool", void 0);

__decorate([class_validator_1.IsNumber({}, {
  each: true
}), __metadata("design:type", Array)], AlertPrivateDTO.prototype, "roomsCount", void 0);

__decorate([class_validator_1.IsNumber(), __metadata("design:type", Number)], AlertPrivateDTO.prototype, "minPrice", void 0);

__decorate([class_validator_1.IsNumber(), __metadata("design:type", Number)], AlertPrivateDTO.prototype, "maxPrice", void 0);

__decorate([class_validator_1.IsEnum(lot_1.LotTypes), __metadata("design:type", String)], AlertPrivateDTO.prototype, "type", void 0);

__decorate([class_validator_1.IsNumber(), __metadata("design:type", Number)], AlertPrivateDTO.prototype, "minSurface", void 0);

__decorate([class_validator_1.IsNumber(), __metadata("design:type", Number)], AlertPrivateDTO.prototype, "maxSurface", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], AlertPrivateDTO.prototype, "delivery", void 0);

__decorate([class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], AlertPrivateDTO.prototype, "invest", void 0);

__decorate([class_validator_1.IsNumber(), __metadata("design:type", Number)], AlertPrivateDTO.prototype, "monthlyComplementaryIncome", void 0);

__decorate([class_validator_1.IsNumber(), __metadata("design:type", Number)], AlertPrivateDTO.prototype, "annualTaxSaving", void 0);

__decorate([class_validator_1.IsNumber(), __metadata("design:type", Number)], AlertPrivateDTO.prototype, "monthlySaving", void 0);

__decorate([class_validator_1.IsNumber(), __metadata("design:type", Number)], AlertPrivateDTO.prototype, "latitude", void 0);

__decorate([class_validator_1.IsNumber(), __metadata("design:type", Number)], AlertPrivateDTO.prototype, "longitude", void 0);

exports.AlertPrivateDTO = AlertPrivateDTO;

var AlertCreationDTO = function AlertCreationDTO() {
  _classCallCheck(this, AlertCreationDTO);
};

__decorate([class_validator_1.IsString(), class_validator_1.IsOptional(), __metadata("design:type", String)], AlertCreationDTO.prototype, "region", void 0);

__decorate([class_validator_1.IsString(), class_validator_1.IsOptional(), __metadata("design:type", String)], AlertCreationDTO.prototype, "department", void 0);

__decorate([class_validator_1.IsString(), class_validator_1.IsOptional(), __metadata("design:type", String)], AlertCreationDTO.prototype, "city", void 0);

__decorate([class_validator_1.IsEnum(lot_1.FiscalTools), class_validator_1.IsOptional(), __metadata("design:type", String)], AlertCreationDTO.prototype, "fiscalTool", void 0);

__decorate([class_validator_1.IsNumber({}, {
  each: true
}), class_validator_1.IsOptional(), __metadata("design:type", Array)], AlertCreationDTO.prototype, "roomsCount", void 0);

__decorate([class_validator_1.IsNumber(), class_validator_1.IsOptional(), __metadata("design:type", Number)], AlertCreationDTO.prototype, "minPrice", void 0);

__decorate([class_validator_1.IsNumber(), class_validator_1.IsOptional(), __metadata("design:type", Number)], AlertCreationDTO.prototype, "maxPrice", void 0);

__decorate([class_validator_1.IsEnum(lot_1.LotTypes), class_validator_1.IsOptional(), __metadata("design:type", String)], AlertCreationDTO.prototype, "type", void 0);

__decorate([class_validator_1.IsNumber(), class_validator_1.IsOptional(), __metadata("design:type", Number)], AlertCreationDTO.prototype, "minSurface", void 0);

__decorate([class_validator_1.IsNumber(), class_validator_1.IsOptional(), __metadata("design:type", Number)], AlertCreationDTO.prototype, "maxSurface", void 0);

__decorate([class_validator_1.IsEnum(program_1.ProgramDeliveryEnum), class_validator_1.IsOptional(), __metadata("design:type", String)], AlertCreationDTO.prototype, "delivery", void 0);

__decorate([class_validator_1.IsBoolean(), class_validator_1.IsOptional(), __metadata("design:type", Boolean)], AlertCreationDTO.prototype, "invest", void 0);

__decorate([class_validator_1.IsNumber(), class_validator_1.IsOptional(), __metadata("design:type", Number)], AlertCreationDTO.prototype, "monthlyComplementaryIncome", void 0);

__decorate([class_validator_1.IsNumber(), class_validator_1.IsOptional(), __metadata("design:type", Number)], AlertCreationDTO.prototype, "annualTaxSaving", void 0);

__decorate([class_validator_1.IsNumber(), class_validator_1.IsOptional(), __metadata("design:type", Number)], AlertCreationDTO.prototype, "monthlySaving", void 0);

__decorate([class_validator_1.IsNumber(), class_validator_1.IsOptional(), __metadata("design:type", Number)], AlertCreationDTO.prototype, "latitude", void 0);

__decorate([class_validator_1.IsNumber(), class_validator_1.IsOptional(), __metadata("design:type", Number)], AlertCreationDTO.prototype, "longitude", void 0);

exports.AlertCreationDTO = AlertCreationDTO;