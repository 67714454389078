import { NewsCreationDTO, NewsPrivateDTO, NewsUpdateDTO, PaginationRequest } from '@vaneauneuf/dtos'
import { Dispatch } from 'redux'
import { IRootState } from '..'
import { apiRequest } from '../utils/api'
import { addNews, removeNews, setLoading, setNews, setNewsList } from './actions'

export const fetchNewsList = (paginationRequest?: PaginationRequest) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const newsList = await apiRequest<{ data: NewsPrivateDTO[]; total: number }>(
      'GET',
      getState().authState.token,
      `/news?countries[]=${getState().authState.locale}`,
      undefined,
      paginationRequest
    )
    dispatch(setNewsList(newsList))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const fetchNews = (id: string) => async (dispatch: Dispatch, getState: () => IRootState) => {
  dispatch(setLoading(true))
  try {
    const news = await apiRequest<NewsPrivateDTO>('GET', getState().authState.token, '/news/' + id)
    dispatch(setNews(news))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const createNews = (news: NewsCreationDTO) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const newNews = await apiRequest<NewsPrivateDTO>(
      'POST',
      getState().authState.token,
      '/news',
      news
    )
    dispatch(addNews(newNews))
  } catch (e) {
    dispatch(setLoading(false))
    throw e.response.data
  }
  dispatch(setLoading(false))
}

export const updateNews = (id: string, news: NewsUpdateDTO) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const newsUpdated = await apiRequest<NewsPrivateDTO>(
      'PATCH',
      getState().authState.token,
      '/news/' + id,
      news
    )
    dispatch(setNews(newsUpdated))
  } catch (e) {
    dispatch(setLoading(false))
    throw e.response.data
  }
  dispatch(setLoading(false))
}

export const deleteNews = (id: string) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    await apiRequest('DELETE', getState().authState.token, '/news/' + id)
    dispatch(removeNews(id))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}
