import { ActionsTypes } from './model'

import { setHomeConfig, setLoading } from './actions'

import { IHomeConfigState } from '.'
import { createReducer } from '../utils'

const initialState: IHomeConfigState = {
  homeConfig: {
    id: '',
    title: '',
    description: [],
    picture: undefined,
    linkText: ''
  },
  loading: false
}

const actionHandlers = {
  [ActionsTypes.SET_HOME_CONFIG]: (
    state: IHomeConfigState,
    { homeConfig }: ReturnType<typeof setHomeConfig>
  ): IHomeConfigState => ({
    ...state,
    homeConfig
  }),
  [ActionsTypes.SET_HOME_CONFIG_LOADING]: (
    state: IHomeConfigState,
    { loading }: ReturnType<typeof setLoading>
  ): IHomeConfigState => ({
    ...state,
    loading
  })
}

export default createReducer(initialState, actionHandlers)
