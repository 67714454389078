import { PromoterPrivateDTO } from '@vaneauneuf/dtos'

export enum ActionsTypes {
  SET_PROMOTERS = 'SET_PROMOTERS',
  SET_PROMOTER = 'SET_PROMOTER',
  ADD_PROMOTER = 'ADD_PROMOTER',
  REMOVE_PROMOTER = 'REMOVE_PROMOTER',
  SET_PROMOTER_LOADING = 'SET_PROMOTER_LOADING'
}

export interface IPromoterState {
  promoters: PromoterPrivateDTO[]
  loading: boolean
}
