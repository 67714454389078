import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router'
import { createBrowserHistory, History } from 'history'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import adminState, { IAdminsState } from './admin'
import authState, { IAuthState } from './auth'
import globalState, { IGlobalConfigState } from './GlobalConfig'
import homeConfigState, { IHomeConfigState } from './HomeConfig'
import landingState, { ILandingState } from './landing'
import lotState, { ILotState } from './lot'
import newsState, { INewsState } from './news'
import newsTagState, { INewsTagState } from './newsTag'
import programState, { IProgramState } from './program'
import promoterState, { IPromoterState } from './promoter'
import userState, { IUserState } from './user'
import wishState, { IWishState } from './wish'

const reducers = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    authState,
    promoterState,
    programState,
    lotState,
    userState,
    adminState,
    newsState,
    newsTagState,
    wishState,
    landingState,
    globalState,
    homeConfigState
  })

export interface IRootState {
  router: RouterState
  authState: IAuthState
  promoterState: IPromoterState
  programState: IProgramState
  lotState: ILotState
  userState: IUserState
  adminState: IAdminsState
  newsState: INewsState
  newsTagState: INewsTagState
  wishState: IWishState
  landingState: ILandingState
  globalState: IGlobalConfigState
  homeConfigState: IHomeConfigState
}

export const browserHistory = createBrowserHistory()

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default createStore(
  reducers(browserHistory),
  composeEnhancers(applyMiddleware(routerMiddleware(browserHistory), thunk))
)
