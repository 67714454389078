import { FavoritePrivateDTO, ProjectPrivateDTO } from '@vaneauneuf/dtos'
import Table, { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import { fetchFavorites } from '../../../redux/user/thunks'

interface IMemberTableProps {
  id: string
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IMemberTableProps

class MemberFavoriteTable extends React.Component<IProps> {
  private columns: Array<ColumnProps<FavoritePrivateDTO>> = [
    {
      title: 'Nom du programme',
      dataIndex: 'name'
    },
    {
      title: 'Ville',
      dataIndex: 'city'
    },
    {
      title: 'Type de bien',
      dataIndex: 'alertType',
      render: type => (type === 'Program' ? 'Programme' : 'Lot')
    },
    {
      title: 'Surface (m²)',
      dataIndex: 'surface'
    },
    {
      title: 'Etage',
      dataIndex: 'floor'
    },
    {
      title: 'Nb pièces',
      dataIndex: 'roomsCount'
    },
    {
      title: 'Prix',
      dataIndex: 'price'
    },
    {
      title: 'Référence',
      dataIndex: 'reference'
    },
    {
      title: 'Voir',
      render: (name, record) =>
        record.lotId ? (
          <Link to={'/programs/' + record.programId + '/lots/' + record.lotId}>Lot</Link>
        ) : (
          <Link to={'/programs/' + record.programId}>Programme</Link>
        )
    }
  ]

  public async componentDidMount() {
    await this.props.fetchFavorites(this.props.id)
  }

  public render() {
    return (
      <Table
        columns={this.columns}
        dataSource={this.props.favorites}
        rowKey="id"
        loading={this.props.loading}
      />
    )
  }
}

const mapStateToProps = (state: IRootState) => ({
  favorites: state.userState.favorites,
  loading: state.userState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchFavorites: (id: string) => dispatch(fetchFavorites(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberFavoriteTable)
