import React from 'react'
import CreateForm from '../../components/CreateForm/CreateForm'
import LandingsCreateForm from './LandingsCreateForm/LandingsCreateForm'
import styles from './LandingsPage.module.scss'
import LandingsTable from './LandingsTable/LandingsTable'

const LandingsPage: React.FC = () => (
  <div className={styles.container}>
    <h1>Landing pages</h1>
    <CreateForm entity="landing page" component={LandingsCreateForm} />
    <LandingsTable />
  </div>
)

export default LandingsPage
