import { UserFilterDTO } from '@vaneauneuf/dtos'
import React, { useState } from 'react'
import MembersFilter from './MembersFilter/MembersFilter'
import styles from './MembersPage.module.scss'
import MembersTable from './MembersTable/MembersTable'

export const PAGE_SIZE = 50

const MembersPage: React.FC = () => {
  const [filters, setFilters] = useState<UserFilterDTO>({ phone: '', name: '', email: '' })

  return (
    <div className={styles.container}>
      <h1>Members</h1>
      <MembersFilter filters={filters} onUpdateFilters={setFilters} />
      <MembersTable filters={filters} />
    </div>
  )
}

export default MembersPage
