import { LotUpdateDTO, ProgramUpdateDTO } from '@vaneauneuf/dtos'
import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { updateLot } from '../../redux/lot'
import { updateProgram } from '../../redux/program'
import ErrorComponent from '../ErrorComponent/ErrorComponent'
import UploadFileComponent from '../UploadFileComponent/UploadFileComponent'

type IProps = ReturnType<typeof mapDispatchToProps> & IAddMediaProps

interface IState {
  uploaded: boolean
  errors: string[]
}

interface IAddMediaProps {
  idProgram?: string
  idLot?: string
  field?: string
  onUpload?: (mediaId: string, mediaUrl?: string) => void
  autoUpload?: boolean
  showSuccessMessage?: boolean
}

class AddMedia extends React.Component<IProps, IState> {
  public state: IState = {
    uploaded: false,
    errors: []
  }

  public onFileUploaded = async (id: string, mediaUrl?: string) => {
    try {
      if (this.props.field) {
        const body: any = {}
        body[this.props.field] = id
        if (this.props.idProgram) {
          await this.props.updateProgram(this.props.idProgram, body)
        }
        if (this.props.idLot) {
          await this.props.uploadLot(this.props.idLot, body)
        }
      }

      if (this.props.onUpload) {
        this.props.onUpload(id, mediaUrl)
      }
    } catch ({ errors }) {
      if (errors) {
        this.setState({ errors })
      }
      return
    }
  }
  public render() {
    return (
      <>
        <UploadFileComponent
          onFileUploaded={this.onFileUploaded}
          autoUpload={this.props.autoUpload}
          showSuccessMessage={this.props.showSuccessMessage}
        />{' '}
        <ErrorComponent errors={this.state.errors} />
      </>
    )
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  updateProgram: (id: string, program: ProgramUpdateDTO) => dispatch(updateProgram(id, program)),
  uploadLot: (id: string, lot: LotUpdateDTO) => dispatch(updateLot(id, lot))
})

const AddMediaComponent = connect(
  null,
  mapDispatchToProps
)(AddMedia)

export default AddMediaComponent
