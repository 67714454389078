import { PaginationRequest, UserDTO, UserFilterDTO } from '@vaneauneuf/dtos'
import Modal from 'antd/lib/modal'
import Table, { ColumnProps, PaginationConfig, SorterResult } from 'antd/lib/table'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import { deleteUser, fetchMembers } from '../../../redux/user'
import { PAGE_SIZE } from '../MembersPage'

interface IMemberTableProps {
  filters: UserFilterDTO
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IMemberTableProps

class MembersTable extends React.Component<IProps> {
  private columns: Array<ColumnProps<UserDTO>> = [
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      defaultSortOrder: 'ascend'
    },
    {
      title: 'Prénom',
      dataIndex: 'firstName'
    },
    {
      title: 'Nom',
      dataIndex: 'lastName'
    },
    {
      title: 'Téléphone',
      dataIndex: 'phone'
    },
    {
      title: 'Age',
      dataIndex: 'ageRange'
    },

    {
      title: 'Date de création',
      dataIndex: 'createDate',
      render: (contact, record) => new Date(record.createDate).toLocaleString()
    },
    {
      title: 'Contact',
      render: member => {
        const dispo = []
        if (member.contactByPhone) dispo.push('Téléphone')
        if (member.contactBySms) dispo.push('Sms')
        if (member.contactByMail) dispo.push('Email')
        if (dispo.length === 0) dispo.push('Aucun')
        return dispo.join(', ')
      }
    },
    {
      title: 'Disponibilités',
      render: member => {
        if (!member.forms || member.forms.length === 0) return 'N/C'
        const dispo = []
        const contactForm = member.forms.find((f: any) => f.formData.now)
        const data = contactForm ? contactForm.formData : {}
        if (data.now) dispo.push('Immédiatement')
        if (data.morning) dispo.push('Matinée')
        if (data.afternoon) dispo.push('Après-midi')
        if (data.dayEnd) dispo.push('Soirée')
        return dispo.join(', ')
      }
    },
    {
      title: 'Accepte offres',
      render: member => {
        if (!member.forms || member.forms.length === 0) return 'N/C'
        const contactForm = member.forms.find((f: any) => f.formData.offers)
        const data = contactForm ? contactForm.formData : {}
        return data.offers ? 'Oui' : 'Non'
      }
    },
    {
      title: 'Nb formulaires remplis',
      render: member => (member.forms ? member.forms.length : 0)
    },
    {
      title: 'Détails',
      dataIndex: 'id',
      render: (name, record) => <Link to={'/members/' + record.id}>Voir plus</Link>
    }
    /*  {
      title: 'Action',
      render: (text, record) => <a onClick={() => this.handleDelete(record.id)}>Supprimer</a>
    }*/
  ]

  public async componentDidMount() {
    await this.props.fetchMembers({ take: PAGE_SIZE, order: { email: 'ASC' } })
  }

  public render() {
    return (
      <Table
        columns={this.columns}
        dataSource={this.props.members}
        rowKey="id"
        pagination={{ total: this.props.total, defaultPageSize: PAGE_SIZE }}
        onChange={this.handleTableChange}
        loading={this.props.loading}
      />
    )
  }

  private handleDelete = (id: string) => {
    Modal.confirm({
      title: 'Voulez vous vraiment supprimer ce member?',
      onOk: () => this.props.deleteUser(id)
    })
  }

  private handleTableChange = (
    pagination: PaginationConfig,
    filters: any,
    sorter: SorterResult<UserDTO>
  ) => {
    this.props.fetchMembers({
      skip: pagination.current ? (pagination.current - 1) * PAGE_SIZE : 0,
      take: PAGE_SIZE,
      order: { [sorter.field]: sorter.order === 'ascend' ? 'ASC' : 'DESC' },
      ...this.props.filters
    })
  }
}

const mapStateToProps = (state: IRootState) => ({
  members: state.userState.members,
  total: state.userState.total,
  loading: state.userState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchMembers: (paginationRequest: PaginationRequest) => dispatch(fetchMembers(paginationRequest)),
  deleteUser: (id: string) => dispatch(deleteUser(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembersTable)
