import { Country } from '@vaneauneuf/dtos'

export enum ActionsTypes {
  SET_TOKEN = 'SET_TOKEN',
  RESET_TOKEN = 'RESET_TOKEN',
  SET_LOCALE = 'SET_LOCALE',
  SET_AUTH_LOADING = 'SET_AUTH_LOADING'
}

export interface IAuthState {
  token: string | null
  locale: Country
  loading: boolean
}
