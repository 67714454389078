import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { createMedia } from '../../redux/program'
import ErrorComponent from '../ErrorComponent/ErrorComponent'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IUploadFileProps

interface IState {
  errors: string[]
  file: any
  isUploaded: boolean
  idMedia: string
}

interface IUploadFileProps {
  onFileUploaded: (id: string, mediaUrl?: string) => void
  autoUpload?: boolean
  showSuccessMessage?: boolean
}

interface IPostMediaRes {
  id: string
  azureId: string
  mediaUrl?: string
}

class UploadFile extends React.Component<IProps, IState> {
  public state: IState = {
    errors: [],
    file: null,
    isUploaded: false,
    idMedia: ''
  }

  public onChange = (e: any) => {
    this.setState({ file: e.target.files[0], isUploaded: false }, () => {
      this.props.autoUpload && this.doUpload()
    })
  }

  public doUpload = async () => {
    const { file } = this.state

    try {
      const res: IPostMediaRes = await this.props.createMedia(file)
      this.setState({
        ...this.state,
        file: null,
        isUploaded: true,
        errors: [],
        idMedia: res.id
      })
      this.props.onFileUploaded(res.id, res.mediaUrl)
    } catch (e) {
      this.setState({
        ...this.state,
        errors: [e.message]
      })
    }
  }

  public render() {
    const { errors, isUploaded, idMedia } = this.state
    const { autoUpload = false, showSuccessMessage = true } = this.props

    return (
      <div>
        <input type="file" onChange={this.onChange} />
        {!autoUpload && (
          <button type="button" onClick={() => this.doUpload()}>
            Téléverser
          </button>
        )}
        {isUploaded && showSuccessMessage && <p>Fichier téléversé avec succès</p>}
        <ErrorComponent errors={errors} />
      </div>
    )
  }
}

const mapStateToProps = (state: IRootState) => ({
  loading: state.programState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  createMedia: (file: any) => dispatch(createMedia(file))
})

const UploadFileComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadFile)
export default UploadFileComponent
