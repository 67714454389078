import { ProgramPrivateDTO } from '@vaneauneuf/dtos'
import { ActionsTypes } from './model'

export const setPrograms = (programs: { data: ProgramPrivateDTO[]; total: number }) => ({
  type: ActionsTypes.SET_PROGRAMS,
  programs
})

export const setProgram = (program: ProgramPrivateDTO) => ({
  type: ActionsTypes.SET_PROGRAM,
  program
})

export const addProgram = (program: ProgramPrivateDTO) => ({
  type: ActionsTypes.ADD_PROGRAM,
  program
})

export const removeProgram = (idProgram: string) => ({
  type: ActionsTypes.REMOVE_PROGRAM,
  idProgram
})

export const setLoading = (loading: boolean) => ({
  type: ActionsTypes.SET_PROGRAM_LOADING,
  loading
})
