import { WrappedFormUtils } from 'antd/lib/form/Form'
import React from 'react'

export const WishEditableContext = React.createContext<WrappedFormUtils | null>(null)

export const WishEditableRow = ({ form, index, ...props }: any) => (
  <WishEditableContext.Provider value={form}>
    <tr {...props} />
  </WishEditableContext.Provider>
)
