import {
  FiscalTools,
  LandingPagePublicDTO,
  LandingPageUpdateDTO,
  LotTypes,
  ProgramDeliveryEnum
} from '@vaneauneuf/dtos'
import { Checkbox, InputNumber, Radio } from 'antd'
import AutoComplete from 'antd/lib/auto-complete'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import message from 'antd/lib/message'
import Select, { SelectValue } from 'antd/lib/select'
import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent'
import Media from '../../../components/Media/Media'
import SeoFooterForm from '../../../components/SeoFooterForm/SeoFooterForm'
import { IComponentFormUpdateProps } from '../../../components/UpdateForm/UpdateForm'
import Wysiwyg from '../../../components/Wysiwyg/Wysiwyg'
import { IRootState } from '../../../redux'
import { fetchLandings, getLanding, updateLanding } from '../../../redux/landing'
import { createMedia } from '../../../redux/program'
import { formatDelivery } from '../../../utils/formatDelivery'
import ISuggestion, { getFieldsValuefromLocation, mapBoxSearch } from '../../../utils/mapBox'
import styles from './LandingUpdateForm.module.scss'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IComponentFormUpdateProps

interface IState {
  errors: string[]
  dataSource: ISuggestion[]
  region?: string
  department?: string
  city?: string
  latitude?: number
  longitude?: number
}

class UpdateForm extends React.Component<IProps, IState> {
  public state: IState = {
    errors: [],
    dataSource: []
  }

  public async componentDidMount() {
    if (!this.props.match.params.idLanding) {
      return
    }

    if (!this.props.landing) {
      await this.props.fetchLandings()
    }

    this.fillForm()
  }

  public render() {
    const {
      edit,
      loading,
      form,
      landingPages,
      landing: { id: landingPageId, order } = { id: null, order: 0 }
    } = this.props
    const { errors, dataSource } = this.state
    const { getFieldDecorator } = form

    const possibleSlug = this.getSlugByLocation(
      this.getSlugByLocation(this.buildLocalisationByLocation(this.state))
    )
    const actualSlug = form.getFieldValue('slug')

    const resetSlug = () => {
      form.setFieldsValue({ slug: possibleSlug })
    }

    const orderOptions = [
      {
        value: 1,
        label: 'En première position'
      }
    ].concat(
      [...landingPages]
        .filter(({ id }) => id !== landingPageId)
        .sort(({ order: orderA }, { order: orderB }) => orderA - orderB)
        .map(page => ({
          value: page.order > order ? page.order : page.order + 1,
          label: `Après '${page.title}'`
        }))
    )

    return (
      <>
        <Form>
          <Form.Item>
            {getFieldDecorator('search', {
              valuePropName: 'checked'
            })(<Checkbox disabled={!edit}>Page liste</Checkbox>)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('showInHomepage', {
              valuePropName: 'checked'
            })(<Checkbox disabled={!edit}>Visible sur la page d'accueil</Checkbox>)}
          </Form.Item>
          <Form.Item label="Position">
            {getFieldDecorator('order', {
              rules: [{ required: true, message: 'Veuillez choisir la position' }]
            })(
              <Select disabled={!edit}>
                {Object.values(orderOptions).map(position => (
                  <Select.Option key={position.value.toString()} value={position.value}>
                    {position.label}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Titre">
            {getFieldDecorator('title', {
              rules: [{ required: true, message: 'Veuillez saisir le titre de la landing page' }]
            })(<Input disabled={!edit} />)}
          </Form.Item>
          <Form.Item label="Sous-titre">
            {getFieldDecorator('subTitle', {
              rules: [{ message: 'Veuillez saisir le sous-titre de la landing page' }]
            })(<Input disabled={!edit} />)}
          </Form.Item>
          <Form.Item label="Description">
            {getFieldDecorator('header', {
              rules: [
                { required: true, message: 'Veuillez saisir la description de la landing page' }
              ]
            })(<Wysiwyg disabled={!edit} />)}
          </Form.Item>
          <Form.Item label="Slug">
            {getFieldDecorator('slug', {
              rules: [
                {
                  required: true,
                  message: 'Veuillez saisir le slug de la landing page'
                },
                {
                  pattern: /^[a-z0-9-\/]*$/,
                  message: 'Le slug ne peut contenir que des lettres minuscules, des chiffres ou des tirets' // tslint:disable-line
                }
              ]
            })(<Input disabled={!edit} />)}
            {possibleSlug && possibleSlug !== actualSlug && (
              <Button disabled={!edit} onClick={resetSlug}>
                Utiliser&nbsp;<b>{possibleSlug}</b>
              </Button>
            )}
          </Form.Item>
          <Form.Item label="Image gauche">
            {getFieldDecorator('imgLeft')(<Media disabled={!edit} />)}
          </Form.Item>
          <Form.Item label="Image droite">
            {getFieldDecorator('imgRight', {
              rules: [
                {
                  required: true,
                  message: "Veuillez télécharger l'image de droite de la landing page"
                }
              ]
            })(<Media disabled={!edit} />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('enabled', {
              valuePropName: 'checked'
            })(<Checkbox disabled={!edit}>Activer cette landing page</Checkbox>)}
          </Form.Item>
          {/* <Form.Item label="Tracking code Google Analytics">
            {getFieldDecorator('trackingCodeGA')(<Input disabled={!edit} />)}
          </Form.Item> */}
          <Form.Item label="Tracking code Facebook">
            {getFieldDecorator('trackingCodeFB')(<Input disabled={!edit} />)}
          </Form.Item>
          <Form.Item label="Localisation">
            {getFieldDecorator('localisation')(
              <AutoComplete
                onSearch={this.autocomplete}
                onSelect={this.setLocalisationFields}
                disabled={!edit}
              >
                {dataSource.map((data: ISuggestion) => (
                  <Select.Option key={data.id}>{data.place_name}</Select.Option>
                ))}
              </AutoComplete>
            )}
          </Form.Item>
          <Form.Item label="Recherche par radius">
            {getFieldDecorator('useRadius')(<Checkbox disabled={!edit} />)}
          </Form.Item>
          <Form.Item label="Type">
            {getFieldDecorator('type')(
              <Select allowClear disabled={!edit}>
                {Object.keys(LotTypes).map((lotType: any) => (
                  <Select.Option key={lotType} value={LotTypes[lotType as keyof typeof LotTypes]}>
                    {LotTypes[lotType as keyof typeof LotTypes]}
                  </Select.Option>
                ))}
                <Select.Option key="null" value={''}>
                  {'Non renseigné'}
                </Select.Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Fiscalité">
            {getFieldDecorator('fiscalTool')(
              <Select allowClear disabled={!edit}>
                {Object.keys(FiscalTools).map((fiscalTool: any) => (
                  <Select.Option
                    key={fiscalTool}
                    value={FiscalTools[fiscalTool as keyof typeof FiscalTools]}
                  >
                    {FiscalTools[fiscalTool as keyof typeof FiscalTools]}
                  </Select.Option>
                ))}
                <Select.Option key="null" value={''}>
                  {'Non renseigné'}
                </Select.Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Nombre de pièces">
            {getFieldDecorator('roomsCount')(
              <Select mode="multiple" disabled={!edit}>
                {[1, 2, 3, 4, 5].map(roomCount => (
                  <Select.Option key={roomCount.toString()} value={roomCount}>
                    {roomCount === 5 ? '5+' : roomCount}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Prix minimum">
            {getFieldDecorator('minPrice')(
              <InputNumber className={styles.width100} disabled={!edit} />
            )}
          </Form.Item>
          <Form.Item label="Prix maximum">
            {getFieldDecorator('maxPrice')(
              <InputNumber className={styles.width100} disabled={!edit} />
            )}
          </Form.Item>
          <Form.Item label="Surface minimum">
            {getFieldDecorator('minSurface')(
              <InputNumber className={styles.width100} disabled={!edit} />
            )}
          </Form.Item>
          <Form.Item label="Surface maximum">
            {getFieldDecorator('maxSurface')(
              <InputNumber className={styles.width100} disabled={!edit} />
            )}
          </Form.Item>
          <Form.Item label="Livraison">
            {getFieldDecorator('delivery')(
              <Select allowClear disabled={!edit}>
                {Object.keys(ProgramDeliveryEnum).map((delivery: any) => (
                  <Select.Option
                    key={delivery}
                    value={ProgramDeliveryEnum[delivery as keyof typeof ProgramDeliveryEnum]}
                  >
                    {formatDelivery(
                      ProgramDeliveryEnum[delivery as keyof typeof ProgramDeliveryEnum]
                    )}
                  </Select.Option>
                ))}
                <Select.Option key="null" value={''}>
                  {'Non renseigné'}
                </Select.Option>
              </Select>
            )}
          </Form.Item>
          {getFieldDecorator('invest', { initialValue: false })(
            <Radio.Group buttonStyle="solid" disabled={!edit}>
              <Radio.Button value={false}>Habiter</Radio.Button>
              <Radio.Button value={true}>Investir</Radio.Button>
            </Radio.Group>
          )}
          <Form.Item label="Revenus / mois">
            {getFieldDecorator('monthlyComplementaryIncome')(
              <InputNumber className={styles.width100} disabled={!edit} />
            )}
          </Form.Item>
          <Form.Item label="Epargne / mois">
            {getFieldDecorator('annualTaxSaving')(
              <InputNumber className={styles.width100} disabled={!edit} />
            )}
          </Form.Item>
          <Form.Item label="Eco. impôt / an">
            {getFieldDecorator('monthlySaving')(
              <InputNumber className={styles.width100} disabled={!edit} />
            )}
          </Form.Item>
          <SeoFooterForm
            fieldTitle="Pied de page SEO"
            getFieldDecorator={getFieldDecorator}
            seoFooterLinksName="seoFooterLinks"
            seoFooterTextName="seoFooterText"
            disabled={!edit}
          />
        </Form>
        <ErrorComponent errors={errors} />
        {edit && (
          <>
            <Button onClick={this.cancelEdit}>Cancel</Button>
            <Button
              type="primary"
              onClick={this.updateLanding}
              loading={loading}
              style={{ marginLeft: '1rem' }}
            >
              Save
            </Button>
          </>
        )}
      </>
    )
  }

  private buildLocalisationByLocation = (location: {
    region?: string
    department?: string
    city?: string
  }) => [location.city, location.department, location.region].filter(v => v).join(', ')

  private getSlugByLocation = (location: string) =>
    location
      .toLowerCase()
      .split(', ')
      .reverse()
      .join('/')

  private fillForm = () => {
    const { landing, form } = this.props
    if (landing) {
      this.setState({
        city: landing.city,
        region: landing.region,
        department: landing.department,
        latitude: landing.latitude,
        longitude: landing.longitude
      })

      const localisation = this.buildLocalisationByLocation(landing)

      form.setFieldsValue({
        search: landing.search,
        showInHomepage: landing.showInHomepage,
        title: landing.title,
        order: landing.order,
        subTitle: landing.subTitle,
        header: landing.header,
        slug: landing.slug || this.getSlugByLocation(localisation),
        imgLeft: landing.imgLeft,
        imgRight: landing.imgRight,
        enabled: landing.enabled,
        localisation,
        useRadius: landing.useRadius,
        type: landing.type,
        fiscalTool: landing.fiscalTool,
        roomsCount: landing.roomsCount && landing.roomsCount.map(r => Number(r)),
        minPrice: landing.minPrice,
        maxPrice: landing.maxPrice,
        minSurface: landing.minSurface,
        maxSurface: landing.maxSurface,
        delivery: landing.delivery,
        invest: landing.invest,
        monthlyComplementaryIncome: landing.monthlyComplementaryIncome,
        annualTaxSaving: landing.annualTaxSaving,
        monthlySaving: landing.monthlySaving,
        trackingCodeGA: landing.trackingCodeGA,
        trackingCodeFB: landing.trackingCodeFB,
        seoFooterText: landing.seoFooterText || '',
        seoFooterLinks: landing.seoFooterLinks
      })
    }
  }

  private autocomplete = async (value: string) => {
    if (value.length >= 3) {
      const res = await mapBoxSearch(value)
      this.setState({ dataSource: res.features })
    }
  }

  private setLocalisationFields = (value: SelectValue) => {
    const location = this.state.dataSource.find(loc => loc.id === value)

    if (location) {
      const fieldsValue = getFieldsValuefromLocation(location)

      this.setState({
        city: fieldsValue.city,
        region: fieldsValue.region,
        department: fieldsValue.department,
        latitude: fieldsValue.latitude,
        longitude: fieldsValue.longitude
      })
    }
  }

  private updateLanding = async () => {
    const { stopEdit, landing, form } = this.props
    const { region, department, city, latitude, longitude } = this.state
    this.setState({ errors: [] })

    form.validateFieldsAndScroll(
      async (errors, fieldValues: LandingPagePublicDTO & { [index: string]: any }) => {
        if (errors || !landing) {
          return
        }

        if (!fieldValues.imgRight) {
          throw new Error("L'image de droite est obligatoire")
        }

        Object.keys(fieldValues).map(key => {
          if (fieldValues[key] === '') {
            fieldValues[key] = null
          }
        })

        try {
          let leftPicture
          let rightPicture

          if (fieldValues.imgLeft instanceof File) {
            const media = await this.props.createMediaStore(fieldValues.imgLeft)
            leftPicture = media.id
          } else {
            leftPicture = fieldValues.imgLeft ? fieldValues.imgLeft.id : null
          }

          if (fieldValues.imgRight instanceof File) {
            const media = await this.props.createMediaStore(fieldValues.imgRight)
            rightPicture = media.id
          } else {
            rightPicture = fieldValues.imgRight ? fieldValues.imgRight.id : null
          }

          await this.props.updateLanding(landing.id, {
            ...fieldValues,
            showInHomepage: fieldValues.showInHomepage || false,
            region: region || null,
            department: department || null,
            city: city || null,
            latitude,
            longitude,
            imgLeft: leftPicture,
            imgRight: rightPicture
          })
        } catch ({ errors }) {
          if (errors) {
            // @ts-ignore
            this.setState({ errors })
          }
          return
        }

        message.success('Landing page was successfully updated')
        stopEdit()
      }
    )
  }

  private cancelEdit = () => {
    this.props.stopEdit()
    this.fillForm()
  }
}

const mapStateToProps = (state: IRootState, ownProps: IComponentFormUpdateProps) => ({
  loading: state.landingState.loading,
  landing: getLanding(
    state,
    ownProps.match.params.idLanding ? ownProps.match.params.idLanding : ''
  ),
  landingPages: state.landingState.landings
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  createMediaStore: (file: any) => dispatch(createMedia(file)),
  fetchLandings: () => dispatch(fetchLandings()),
  updateLanding: (id: string, landing: LandingPageUpdateDTO) => dispatch(updateLanding(id, landing))
})

const LandingUpdateForm = Form.create({ name: 'landing_update_form' })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UpdateForm)
)

export default LandingUpdateForm
