import { Col, Row } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import { match as Match } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { fetchLot } from '../../redux/lot'
import { getLot } from '../../redux/lot/selectors'
import AddMediaComponent from '../AddMediaComponent/AddMediaComponent'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ILotMediasProps

interface ILotMediasProps {
  match: Match<{ idLot: string }>
}

class LotMedias extends React.Component<IProps> {
  public async componentDidMount() {
    await this.props.fetchLot(this.props.match.params.idLot)
  }

  //   public a = this.props.lot.name

  public render() {
    return (
      <div>
        {this.props.lot && (
          <Row>
            <Col span={8}>
              {' '}
              <h2>Plan</h2>
              {this.props.lot.plan && <a href={this.props.lot.plan}>Télécharger le plan</a>}
              {!this.props.lot.plan && <span>Aucun plan téléchargé</span>}
              <AddMediaComponent idLot={this.props.match.params.idLot} field="planId" />
            </Col>
            <Col span={8}>
              {' '}
              <h2>Brochure</h2>
              {this.props.lot.threeDPlan && (
                <a href={this.props.lot.threeDPlan}>Télécharger le plan 3D</a>
              )}
              {!this.props.lot.threeDPlan && <span>Aucun plan 3D téléchargée</span>}
              <AddMediaComponent idLot={this.props.match.params.idLot} field="threeDPlanId" />
            </Col>
          </Row>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state: IRootState, ownProps: ILotMediasProps) => ({
  lot: getLot(state, ownProps.match.params.idLot ? ownProps.match.params.idLot : '')
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchLot: (id: string) => dispatch(fetchLot(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LotMedias)
