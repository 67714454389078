import React from 'react'
import { match as Match } from 'react-router-dom'
import UpdateForm from '../../components/UpdateForm/UpdateForm'
import styles from './NewsPage.module.scss'
import NewsUpdateForm from './NewsUpdateForm/NewsUpdateForm'

interface IProps {
  match: Match<{ idNews: string }>
}

const NewsPage: React.FunctionComponent<IProps> = props => (
  <div className={styles.container}>
    <h1>Détail de l'actualité</h1>
    <UpdateForm component={NewsUpdateForm} match={props.match} />
  </div>
)

export default NewsPage
