import { ActionsTypes } from './model'

import { addNewsTag, removeNewsTag, setLoading, setNewsTag, setNewsTagList } from './actions'

import { INewsTagState } from '.'
import { createReducer } from '../utils'

const initialState: INewsTagState = {
  newsTagList: [],
  loading: false
}

const actionHandlers = {
  [ActionsTypes.SET_NEWSTAG_LIST]: (
    state: INewsTagState,
    { newsTagList }: ReturnType<typeof setNewsTagList>
  ): INewsTagState => ({
    ...state,
    newsTagList
  }),
  [ActionsTypes.SET_NEWSTAG]: (
    state: INewsTagState,
    { newsTag }: ReturnType<typeof setNewsTag>
  ): INewsTagState => ({
    ...state,
    newsTagList: [
      ...state.newsTagList.filter(currentNewsTag => currentNewsTag.id !== newsTag.id),
      newsTag
    ]
  }),
  [ActionsTypes.ADD_NEWSTAG]: (
    state: INewsTagState,
    { newsTag }: ReturnType<typeof addNewsTag>
  ): INewsTagState => ({
    ...state,
    newsTagList: [...state.newsTagList, newsTag]
  }),
  [ActionsTypes.REMOVE_NEWSTAG]: (
    state: INewsTagState,
    { idNewsTag }: ReturnType<typeof removeNewsTag>
  ): INewsTagState => ({
    ...state,
    newsTagList: [...state.newsTagList].filter(newsTag => newsTag.id !== idNewsTag)
  }),
  [ActionsTypes.SET_NEWSTAG_LOADING]: (
    state: INewsTagState,
    { loading }: ReturnType<typeof setLoading>
  ): INewsTagState => ({
    ...state,
    loading
  })
}

export default createReducer(initialState, actionHandlers)
