import { AccountDTO } from '@vaneauneuf/dtos'
import { ActionsTypes } from './model'

export const setAdmins = (admins: AccountDTO[]) => ({
  type: ActionsTypes.SET_ADMINS,
  admins
})

export const setAdmin = (admin: AccountDTO) => ({
  type: ActionsTypes.SET_ADMIN,
  admin
})

export const addAdmin = (admin: AccountDTO) => ({
  type: ActionsTypes.ADD_ADMIN,
  admin
})

export const removeAdmin = (idAdmin: string) => ({
  type: ActionsTypes.REMOVE_ADMIN,
  idAdmin
})

export const setLoading = (loading: boolean) => ({
  type: ActionsTypes.SET_ADMIN_LOADING,
  loading
})
