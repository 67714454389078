import { PaginationRequest, UserFilterDTO } from '@vaneauneuf/dtos'
import { Button, Checkbox, Col, Row } from 'antd'
import Search from 'antd/lib/input/Search'
import React, { Dispatch, FC } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import { fetchContacts } from '../../../redux/user'
import { PAGE_SIZE } from '../ContactsPage'
import styles from './ContactsFilter.module.scss'

interface IContactFiltersProps {
  filters: UserFilterDTO
  onUpdateFilters: Dispatch<React.SetStateAction<UserFilterDTO & PaginationRequest>>
}

type IProps = ReturnType<typeof mapDispatchToProps> & IContactFiltersProps

const ContactsFilter: FC<IProps> = ({
  filters,
  fetchContacts: fetchContactsStore,
  onUpdateFilters
}) => {
  const resetFilters = () => {
    onUpdateFilters({ email: '', name: '', phone: '', onlyContact: false })
    fetchContactsWithFilters()
  }

  const fetchContactsWithFilters = () => fetchContactsStore({ take: PAGE_SIZE, ...filters })

  return (
    <div className={styles.container}>
      <h2>Filtrer</h2>
      <Row>
        <Col span={6}>
          <Search
            placeholder="Filter par nom"
            className={styles.filterRow}
            addonBefore="Par nom"
            value={filters.name}
            onChange={event => onUpdateFilters({ ...filters, name: event.currentTarget.value })}
            onPressEnter={fetchContactsWithFilters}
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <Search
            placeholder="Filtrer par numéro de téléphone"
            className={styles.filterRow}
            addonBefore="Par téléphone"
            value={filters.phone}
            onChange={event => onUpdateFilters({ ...filters, phone: event.currentTarget.value })}
            onPressEnter={fetchContactsWithFilters}
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <Search
            placeholder="Filtrer par adresse email"
            className={styles.filterRow}
            addonBefore="Par email"
            value={filters.email}
            onChange={event => onUpdateFilters({ ...filters, email: event.currentTarget.value })}
            onPressEnter={fetchContactsWithFilters}
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <Checkbox
            className={styles.filterRow}
            checked={filters.onlyContact}
            onChange={event => onUpdateFilters({ ...filters, onlyContact: event.target.checked })}
          >
            Seulement les contacts
          </Checkbox>
        </Col>
      </Row>
      <Row>
        <Col span={7}>
          <Button type="primary" icon="search" onClick={fetchContactsWithFilters}>
            Rechercher
          </Button>
          <Col span={7}>
            <Button icon="redo" onClick={resetFilters}>
              Effacer
            </Button>
          </Col>
        </Col>
      </Row>
    </div>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchContacts: (paginationRequest: PaginationRequest) =>
    dispatch(fetchContacts(paginationRequest))
})

export default connect(
  null,
  mapDispatchToProps
)(ContactsFilter)
