import { AlertPrivateDTO } from '@vaneauneuf/dtos'
import Table, { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import { fetchAlerts } from '../../../redux/user/thunks'

interface IMemberTableProps {
  id: string
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IMemberTableProps

class MemberAlertTable extends React.Component<IProps> {
  private columns: Array<ColumnProps<AlertPrivateDTO>> = [
    {
      title: 'Type',
      dataIndex: 'type'
    },
    {
      title: 'Ville',
      dataIndex: 'city'
    },
    {
      title: 'Code postal',
      dataIndex: 'postalCode'
    },
    {
      title: 'Departement',
      dataIndex: 'department'
    },
    {
      title: 'Prix min (€)',
      dataIndex: 'minPrice'
    },
    {
      title: 'Prix max (€)',
      dataIndex: 'maxPrice'
    },
    {
      title: 'Surface min (m²)',
      dataIndex: 'minSurface'
    },
    {
      title: 'Surface max (m²)',
      dataIndex: 'maxSurface'
    },
    {
      title: 'Nb pièces',
      dataIndex: 'roomsCount',
      render: data => (data ? data.join(',') : '')
    },
    {
      title: 'Livraison',
      dataIndex: 'delivery'
    },
    {
      title: 'Outil fiscal',
      dataIndex: 'fiscalTool'
    },
    {
      title: 'Pour investissement ?',
      dataIndex: 'invest',
      render: data => (data ? 'Oui' : 'Non')
    },
    {
      title: 'Rev. compl. mensuel (€)',
      dataIndex: 'monthlyComplementaryIncome'
    },
    {
      title: 'Eco. impôt annuel (€)',
      dataIndex: 'annualTaxSaving'
    },
    {
      title: 'Eco. impôt mensuel (€)',
      dataIndex: 'monthlySaving'
    }
  ]

  public async componentDidMount() {
    await this.props.fetchAlerts(this.props.id)
  }

  public render() {
    return (
      <Table
        columns={this.columns}
        dataSource={this.props.alerts}
        rowKey="id"
        loading={this.props.loading}
      />
    )
  }
}

const mapStateToProps = (state: IRootState) => ({
  alerts: state.userState.alerts,
  loading: state.userState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchAlerts: (id: string) => dispatch(fetchAlerts(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberAlertTable)
