import { LandingPagePrivateDTO } from '@vaneauneuf/dtos'
import { ActionsTypes } from './model'

export const setLandings = (landings: LandingPagePrivateDTO[]) => ({
  type: ActionsTypes.SET_LANDINGS,
  landings
})

export const setLanding = (landing: LandingPagePrivateDTO) => ({
  type: ActionsTypes.SET_LANDING,
  landing
})

export const addLanding = (landing: LandingPagePrivateDTO) => ({
  type: ActionsTypes.ADD_LANDING,
  landing
})

export const removeLanding = (idLanding: string) => ({
  type: ActionsTypes.REMOVE_LANDING,
  idLanding
})

export const setLoading = (loading: boolean) => ({
  type: ActionsTypes.SET_LANDING_LOADING,
  loading
})
