import { ProgramDeliveryEnum } from '@vaneauneuf/dtos'

export enum DeliveryEnum {
  EMPTY = '',
  NOW = 'Immédiatement',
  THIS_YEAR = 'Cette année',
  NEXT_YEAR = "L'année prochaine",
  AFTER_TWO_YEARS = 'Dans plus de 2 ans'
}

export const formatDelivery = (delivery: ProgramDeliveryEnum) => {
  if (delivery === ProgramDeliveryEnum.NOW) {
    return DeliveryEnum.NOW
  } else if (delivery === ProgramDeliveryEnum.THIS_YEAR) {
    return DeliveryEnum.THIS_YEAR
  } else if (delivery === ProgramDeliveryEnum.NEXT_YEAR) {
    return DeliveryEnum.NEXT_YEAR
  } else if (delivery === ProgramDeliveryEnum.AFTER_TWO_YEARS) {
    return DeliveryEnum.AFTER_TWO_YEARS
  }
  return DeliveryEnum.EMPTY
}
