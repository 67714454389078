import {
  AlertPrivateDTO,
  FavoritePrivateDTO,
  ProjectPrivateDTO,
  UserDTO,
  UserFormDTO,
  WishPrivateDTO
} from '@vaneauneuf/dtos'

export enum ActionsTypes {
  SET_CONTACTS = 'SET_CONTACTS',
  SET_CONTACT = 'SET_CONTACT',
  SET_MEMBERS = 'SET_MEMBERS',
  SET_MEMBER = 'SET_MEMBER',
  SET_PROJECTS = 'SET_PROJECTS',
  SET_ALERTS = 'SET_ALERTS',
  SET_FAVORITES = 'SET_FAVORITES',
  SET_WISHES = 'SET_WISHES',
  REMOVE_USER = 'REMOVE_USER',
  SET_USER_LOADING = 'SET_USER_LOADING'
}

export interface IUserState {
  contacts: UserFormDTO[]
  members: UserDTO[]
  projects: ProjectPrivateDTO[]
  alerts: AlertPrivateDTO[]
  favorites: FavoritePrivateDTO[]
  wishes: WishPrivateDTO[]
  total: number
  loading: boolean
}
