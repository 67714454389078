import { UserFormDTO } from '@vaneauneuf/dtos/dist'
import { Modal } from 'antd'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { match as Match } from 'react-router-dom'
import UpdateForm from '../../components/UpdateForm/UpdateForm'
import { IRootState } from '../../redux'
import { getContact } from '../../redux/user'
import getMeetingSupportLabel from '../../utils/getMeetingSupportLabel'
import ContactFormsTable from './ContactFormsTable/ContactFormsTable'
import styles from './ContactPage.module.scss'
import ContactUpdateForm from './ContactUpdateForm/ContactUpdateForm'

interface IContactPageProps {
  match: Match<{ idContact: string }>
}

type IProps = ReturnType<typeof mapStateToProps> & IContactPageProps

function ContactPage({ contact, match }: IProps) {
  const [modalContact, setModalContact] = useState<UserFormDTO | undefined>(undefined)
  const meetingForms = contact && contact.forms.filter((f: any) => f.formData.meetingDate)

  return (
    <div className={styles.container}>
      <h1>Détails du contact</h1>
      <UpdateForm component={ContactUpdateForm} match={match} />
      {meetingForms && (
        <div className={styles.link}>
          <a onClick={() => setModalContact(contact)}>Voir les rendez-vous</a>
        </div>
      )}
      <h2 className={styles.formTitle}>Formulaires remplis</h2>
      <ContactFormsTable forms={contact ? contact.forms : null} />
      <Modal
        title="Liste des rendez-vous"
        visible={!!modalContact}
        onOk={() => setModalContact(undefined)}
        onCancel={() => setModalContact(undefined)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <ul>
          {modalContact &&
            modalContact.forms
              .filter((f: any) => f.formData.meetingDate)
              .map((form: any, index: number) => {
                return (
                  <li key={index}>
                    {getMeetingSupportLabel(form.formData.support)}{' '}
                    {new Date(form.formData.meetingDate).toLocaleString()}
                  </li>
                )
              })}
        </ul>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state: IRootState, ownProps: IContactPageProps) => ({
  contact: getContact(state, ownProps.match.params.idContact ? ownProps.match.params.idContact : '')
})

export default connect(
  mapStateToProps,
  null
)(ContactPage)
