import { HomeConfigDTO, HomeConfigUpdateDTO } from '@vaneauneuf/dtos'
import Button from 'antd/lib/button'
import Form, { FormComponentProps } from 'antd/lib/form'
import Input from 'antd/lib/input'
import Message from 'antd/lib/message'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import Media from '../../../components/Media/Media'
import { ArrayInput } from '../../../components/UI/ArrayInput'
import { IRootState } from '../../../redux'
import { updateHomeConfig } from '../../../redux/HomeConfig'
import { createMedia } from '../../../redux/program'

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const UpdateForm: React.FC<FormComponentProps & IProps> = ({
  form,
  homeConfig,
  createMediaStore,
  updateHomeConfigStore
}) => {
  const { getFieldDecorator } = form

  const fillForm = () => {
    form.setFieldsValue({
      description: homeConfig.description,
      linkText: homeConfig.linkText,
      picture: homeConfig.picture,
      title: homeConfig.title
    })
  }

  const sendForm = () => {
    form.validateFieldsAndScroll(async (errors, values: HomeConfigDTO) => {
      if (!errors) {
        const { description, linkText, picture, title } = values
        if (!picture) {
          throw new Error('Missing picture')
        }

        let newPicture
        if (picture instanceof File) {
          const media = await createMediaStore(picture)
          newPicture = media.id
        } else {
          newPicture = picture.id
        }

        await updateHomeConfigStore(homeConfig.id, {
          description,
          linkText,
          picture: newPicture,
          title
        })

        Message.success('La configuration a bien été sauvegardée')
      }
    })
  }

  useEffect(() => {
    fillForm()
  }, [homeConfig])

  return (
    <>
      <Form>
        <h2>Section "Vaneau Neuf vous accompagne"</h2>
        <hr />
        <Form.Item label="Image de fond">
          {getFieldDecorator('picture', {
            rules: [{ required: true, message: 'Veuillez charger une image de fond' }]
          })(<Media />)}
        </Form.Item>
        <Form.Item label="Titre de la rubrique">
          {getFieldDecorator('title', {
            rules: [{ required: true, message: 'Veuillez saisir le titre de la rubrique' }]
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator('description', {
            rules: [{ required: true, message: 'Veuillez saisir la description' }]
          })(<ArrayInput />)}
        </Form.Item>
        <Form.Item label="Texte du lien">
          {getFieldDecorator('linkText', {
            rules: [{ required: true, message: 'Veuillez saisir le texte du lien' }]
          })(<Input />)}
        </Form.Item>
      </Form>
      <Button type="primary" onClick={() => sendForm()} style={{ marginLeft: '1rem' }}>
        Sauvegarder
      </Button>
    </>
  )
}

const mapStateToProps = (state: IRootState) => ({
  homeConfig: state.homeConfigState.homeConfig
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  createMediaStore: (file: any) => dispatch(createMedia(file)),
  updateHomeConfigStore: (id: string, homeConfig: HomeConfigUpdateDTO) => {
    dispatch(updateHomeConfig(id, homeConfig))
  }
})

const HomeConfigUpdateForm = Form.create({ name: 'home_config_update' })(UpdateForm)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeConfigUpdateForm)
