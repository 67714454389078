import { LotCreationDTO, LotPrivateDTO, LotUpdateDTO } from '@vaneauneuf/dtos'
import { Dispatch } from 'redux'
import { IRootState } from '..'
import { setLoading } from '../program/actions'
import { apiRequest } from '../utils/api'
import { addLot, removeLot, setLot, setLots } from './actions'

export const fetchLotsByProgramId = (id: string) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))

  try {
    const lots = await apiRequest<{ data: LotPrivateDTO[]; total: number }>(
      'GET',
      getState().authState.token,
      `/program/${id}/lots`
    )
    dispatch(setLots(lots.data))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const fetchLot = (id: string) => async (dispatch: Dispatch, getState: () => IRootState) => {
  dispatch(setLoading(true))
  try {
    const lot = await apiRequest<LotPrivateDTO>('GET', getState().authState.token, '/lot/' + id)
    dispatch(setLot(lot))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const createLot = (lot: LotCreationDTO) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const newLot = await apiRequest<LotPrivateDTO>('POST', getState().authState.token, '/lot', lot)
    dispatch(addLot(newLot))
  } catch (e) {
    dispatch(setLoading(false))
    throw e.response.data
  }
  dispatch(setLoading(false))
}

export const updateLot = (id: string, lot: LotUpdateDTO) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const lotUpdated = await apiRequest<LotPrivateDTO>(
      'PATCH',
      getState().authState.token,
      '/lot/' + id,
      lot
    )
    dispatch(setLot(lotUpdated))
  } catch (e) {
    dispatch(setLoading(false))
    throw e.response.data
  }
  dispatch(setLoading(false))
}

export const deleteLot = (id: string) => async (dispatch: Dispatch, getState: () => IRootState) => {
  dispatch(setLoading(true))
  try {
    await apiRequest('DELETE', getState().authState.token, '/lot/' + id)
    dispatch(removeLot(id))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}
