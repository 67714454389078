import { createReducer } from '../utils'
import { addPromoter, removePromoter, setLoading, setPromoter, setPromoters } from './actions'
import { ActionsTypes, IPromoterState } from './model'

const initialState: IPromoterState = {
  promoters: [],
  loading: false
}

const actionHandlers = {
  [ActionsTypes.SET_PROMOTERS]: (
    state: IPromoterState,
    { promoters }: ReturnType<typeof setPromoters>
  ): IPromoterState => ({
    ...state,
    promoters
  }),
  [ActionsTypes.SET_PROMOTER]: (
    state: IPromoterState,
    { promoter }: ReturnType<typeof setPromoter>
  ): IPromoterState => ({
    ...state,
    promoters: [
      ...state.promoters.filter(currentPromoter => currentPromoter.id !== promoter.id),
      promoter
    ]
  }),
  [ActionsTypes.ADD_PROMOTER]: (
    state: IPromoterState,
    { promoter }: ReturnType<typeof addPromoter>
  ): IPromoterState => ({
    ...state,
    promoters: [...state.promoters, promoter]
  }),
  [ActionsTypes.REMOVE_PROMOTER]: (
    state: IPromoterState,
    { idPromoter }: ReturnType<typeof removePromoter>
  ): IPromoterState => ({
    ...state,
    promoters: [...state.promoters].filter(promoter => promoter.id !== idPromoter)
  }),
  [ActionsTypes.SET_PROMOTER_LOADING]: (
    state: IPromoterState,
    { loading }: ReturnType<typeof setLoading>
  ): IPromoterState => ({
    ...state,
    loading
  })
}

export default createReducer(initialState, actionHandlers)
