import { createReducer } from '../utils'
import { addProgram, removeProgram, setLoading, setProgram, setPrograms } from './actions'
import { ActionsTypes, IProgramState } from './model'

const initialState: IProgramState = {
  programs: [],
  total: 0,
  loading: false
}

const actionHandlers = {
  [ActionsTypes.SET_PROGRAMS]: (
    state: IProgramState,
    { programs }: ReturnType<typeof setPrograms>
  ): IProgramState => ({
    ...state,
    programs: programs.data,
    total: programs.total
  }),
  [ActionsTypes.SET_PROGRAM]: (
    state: IProgramState,
    { program }: ReturnType<typeof setProgram>
  ): IProgramState => ({
    ...state,
    programs: [
      ...state.programs.filter(currentProgram => currentProgram.id !== program.id),
      program
    ]
  }),
  [ActionsTypes.ADD_PROGRAM]: (
    state: IProgramState,
    { program }: ReturnType<typeof addProgram>
  ): IProgramState => ({
    ...state,
    programs: [...state.programs, program]
  }),
  [ActionsTypes.REMOVE_PROGRAM]: (
    state: IProgramState,
    { idProgram }: ReturnType<typeof removeProgram>
  ): IProgramState => ({
    ...state,
    programs: [...state.programs].filter(program => program.id !== idProgram)
  }),
  [ActionsTypes.SET_PROGRAM_LOADING]: (
    state: IProgramState,
    { loading }: ReturnType<typeof setLoading>
  ): IProgramState => ({
    ...state,
    loading
  })
}

export default createReducer(initialState, actionHandlers)
