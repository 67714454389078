import {
  FiscalTools,
  LandingPageCreationDTO,
  LandingPagePublicDTO,
  LotTypes,
  ProgramDeliveryEnum
} from '@vaneauneuf/dtos'
import { AutoComplete, Checkbox, InputNumber, Radio, Select } from 'antd'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import message from 'antd/lib/message'
import Modal from 'antd/lib/modal'
import { SelectValue } from 'antd/lib/select'
import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IComponentFormCreateProps } from '../../../components/CreateForm/CreateForm'
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent'
import Media from '../../../components/Media/Media'
import SeoFooterForm from '../../../components/SeoFooterForm/SeoFooterForm'
import { IRootState } from '../../../redux'
import { createLanding, fetchLandings } from '../../../redux/landing'
import { createMedia } from '../../../redux/program'
import { formatDelivery } from '../../../utils/formatDelivery'
import ISuggestion, { getFieldsValuefromLocation, mapBoxSearch } from '../../../utils/mapBox'
import styles from './LandingsCreateForm.module.scss'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IComponentFormCreateProps

interface IState {
  errors: string[]
  dataSource: ISuggestion[]
  region?: string
  department?: string
  city?: string
  latitude?: number
  longitude?: number
}

class CreateForm extends React.PureComponent<IProps, IState> {
  public state: IState = {
    errors: [],
    dataSource: []
  }

  public render() {
    const { visible, hideModal, loading, form, landingPages } = this.props
    const { errors, dataSource } = this.state
    const { getFieldDecorator } = form

    const possibleSlug = this.getSlugByLocation(
      this.getSlugByLocation(this.buildLocalisationByLocation(this.state))
    )
    const actualSlug = form.getFieldValue('slug')

    const resetSlug = () => {
      form.setFieldsValue({ slug: possibleSlug })
    }

    const orderOptions = [
      {
        value: 1,
        label: 'En première position'
      }
    ].concat(
      [...landingPages]
        .sort(({ order: orderA }, { order: orderB }) => orderA - orderB)
        .map(page => ({
          value: page.order + 1,
          label: `Après '${page.title}'`
        }))
    )

    return (
      <Modal
        visible={visible}
        title="Créer une nouvelle landing page"
        okText="Sauvegarder"
        onCancel={hideModal}
        onOk={this.createLanding}
        confirmLoading={loading}
      >
        <Form>
          <Form.Item label="Page liste">{getFieldDecorator('search')(<Checkbox />)}</Form.Item>
          <Form.Item label="Visible sur la page d'accueil">
            {getFieldDecorator('showInHomepage')(<Checkbox />)}
          </Form.Item>
          <Form.Item label="Position">
            {getFieldDecorator('order')(
              <Select>
                {Object.values(orderOptions).map(position => (
                  <Select.Option key={position.value.toString()} value={position.value}>
                    {position.label}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Titre">
            {getFieldDecorator('title', {
              rules: [{ required: true, message: 'Veuillez saisir le titre de la landing page' }]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Sous-titre">
            {getFieldDecorator('subTitle', {
              rules: [{ message: 'Veuillez saisir le sous-titre de la landing page' }]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Description">
            {getFieldDecorator('header', {
              rules: [
                { required: true, message: 'Veuillez saisir la description de la landing page' }
              ]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Slug">
            {getFieldDecorator('slug', {
              rules: [{ required: true, message: 'Veuillez saisir le slug de la landing page' }]
            })(<Input />)}
            {possibleSlug && possibleSlug !== actualSlug && (
              <Button onClick={resetSlug}>
                Utiliser&nbsp;<b>{possibleSlug}</b>
              </Button>
            )}
          </Form.Item>
          <Form.Item label="Image gauche">{getFieldDecorator('imgLeft')(<Media />)}</Form.Item>
          <Form.Item label="Image droite">
            {getFieldDecorator('imgRight', {
              rules: [
                {
                  required: true,
                  message: "Veuillez télécharger l'image de droite de la landing page"
                }
              ]
            })(<Media />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('enabled', {
              valuePropName: 'checked'
            })(<Checkbox>Activer cette landing page</Checkbox>)}
          </Form.Item>
          {/* <Form.Item label="Tracking code Google Analytics">
            {getFieldDecorator('trackingCodeGA')(<Input />)}
          </Form.Item> */}
          <Form.Item label="Tracking code Facebook">
            {getFieldDecorator('trackingCodeFB')(<Input />)}
          </Form.Item>
          <Form.Item label="Localisation">
            <AutoComplete onSearch={this.autocomplete} onSelect={this.setLocalisationFields}>
              {dataSource.map((data: ISuggestion) => (
                <Select.Option key={data.id}>{data.place_name}</Select.Option>
              ))}
            </AutoComplete>
          </Form.Item>
          <Form.Item label="Recherche par radius">
            {getFieldDecorator('useRadius')(<Checkbox />)}
          </Form.Item>
          <Form.Item label="Type">
            {getFieldDecorator('type')(
              <Select allowClear>
                {Object.keys(LotTypes).map((lotType: any) => (
                  <Select.Option key={lotType} value={LotTypes[lotType as keyof typeof LotTypes]}>
                    {LotTypes[lotType as keyof typeof LotTypes]}
                  </Select.Option>
                ))}
                <Select.Option key="null" value={''}>
                  {'Non renseigné'}
                </Select.Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Fiscalité">
            {getFieldDecorator('fiscalTool')(
              <Select allowClear>
                {Object.keys(FiscalTools).map(fiscalTool => (
                  <Select.Option
                    key={fiscalTool}
                    value={FiscalTools[fiscalTool as keyof typeof FiscalTools]}
                  >
                    {FiscalTools[fiscalTool as keyof typeof FiscalTools]}
                  </Select.Option>
                ))}
                <Select.Option key="null" value={''}>
                  {'Non renseigné'}
                </Select.Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Nombre de pièces">
            {getFieldDecorator('roomsCount')(
              <Select mode="multiple">
                {[1, 2, 3, 4, 5].map(roomCount => (
                  <Select.Option key={roomCount.toString()} value={roomCount}>
                    {roomCount === 5 ? '5+' : roomCount}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Prix minimum">
            {getFieldDecorator('minPrice')(<InputNumber className={styles.width100} />)}
          </Form.Item>
          <Form.Item label="Prix maximum">
            {getFieldDecorator('maxPrice')(<InputNumber className={styles.width100} />)}
          </Form.Item>
          <Form.Item label="Surface minimum">
            {getFieldDecorator('minSurface')(<InputNumber className={styles.width100} />)}
          </Form.Item>
          <Form.Item label="Surface maximum">
            {getFieldDecorator('maxSurface')(<InputNumber className={styles.width100} />)}
          </Form.Item>
          <Form.Item label="Livraison">
            {getFieldDecorator('delivery')(
              <Select allowClear>
                {Object.keys(ProgramDeliveryEnum).map((delivery: any) => (
                  <Select.Option
                    key={delivery}
                    value={ProgramDeliveryEnum[delivery as keyof typeof ProgramDeliveryEnum]}
                  >
                    {formatDelivery(
                      ProgramDeliveryEnum[delivery as keyof typeof ProgramDeliveryEnum]
                    )}
                  </Select.Option>
                ))}
                <Select.Option key="null" value={''}>
                  {'Non renseigné'}
                </Select.Option>
              </Select>
            )}
          </Form.Item>
          {getFieldDecorator('invest', { initialValue: false })(
            <Radio.Group buttonStyle="solid">
              <Radio.Button value={false}>Habiter</Radio.Button>
              <Radio.Button value={true}>Investir</Radio.Button>
            </Radio.Group>
          )}
          <Form.Item label="Revenus / mois">
            {getFieldDecorator('monthlyComplementaryIncome')(
              <InputNumber className={styles.width100} />
            )}
          </Form.Item>
          <Form.Item label="Epargne / mois">
            {getFieldDecorator('annualTaxSaving')(<InputNumber className={styles.width100} />)}
          </Form.Item>
          <Form.Item label="Eco. impôt / an">
            {getFieldDecorator('monthlySaving')(<InputNumber className={styles.width100} />)}
          </Form.Item>
          <SeoFooterForm
            fieldTitle="Pied de page SEO"
            getFieldDecorator={getFieldDecorator}
            seoFooterLinksName="seoFooterLinks"
            seoFooterTextName="seoFooterText"
            disabled={false}
          />
        </Form>
        <ErrorComponent errors={errors} />
      </Modal>
    )
  }

  private buildLocalisationByLocation = (location: {
    region?: string
    department?: string
    city?: string
  }) => [location.city, location.department, location.region].filter(v => v).join(', ')

  private getSlugByLocation = (location: string) =>
    location
      .split(', ')
      .reverse()
      .join('/')

  private autocomplete = async (value: string) => {
    if (value.length >= 3) {
      const res = await mapBoxSearch(value)
      this.setState({ dataSource: res.features })
    }
  }

  private setLocalisationFields = (value: SelectValue) => {
    const location = this.state.dataSource.find(loc => loc.id === value)

    if (location) {
      const fieldsValue = getFieldsValuefromLocation(location)
      this.setState({
        region: fieldsValue.region,
        department: fieldsValue.department,
        city: fieldsValue.city,
        latitude: fieldsValue.latitude,
        longitude: fieldsValue.longitude
      })
    }
  }

  private createLanding = async () => {
    const { hideModal, form, locale } = this.props
    const { region, department, city, latitude, longitude } = this.state
    this.setState({ errors: [] })

    form.validateFieldsAndScroll(
      async (errors, fieldValues: LandingPagePublicDTO & { [index: string]: any }) => {
        if (errors) {
          return
        }

        if (!fieldValues.imgRight) {
          throw new Error("L'image de droite est obligatoire")
        }

        Object.keys(fieldValues).map(key => {
          if (fieldValues[key] === '') {
            fieldValues[key] = undefined
          }
        })

        try {
          let leftPicture
          let rightPicture

          if (fieldValues.imgLeft instanceof File) {
            const media = await this.props.createMediaStore(fieldValues.imgLeft)
            leftPicture = media.id
          } else {
            leftPicture = fieldValues.imgLeft ? fieldValues.imgLeft.id : null
          }

          if (fieldValues.imgRight instanceof File) {
            const media = await this.props.createMediaStore(fieldValues.imgRight)
            rightPicture = media.id
          } else {
            rightPicture = fieldValues.imgRight ? fieldValues.imgRight.id : null
          }

          await this.props.createLanding({
            ...fieldValues,
            showInHomepage: fieldValues.showInHomepage || false,
            subTitle: fieldValues.subTitle || null,
            region: region || null,
            department: department || null,
            city: city || null,
            latitude,
            longitude,
            country: locale,
            imgLeft: leftPicture,
            imgRight: rightPicture
          })
        } catch ({ errors }) {
          if (errors) {
            // @ts-ignore
            this.setState({ errors })
          }
          return
        }

        message.success('La landing page a bien été ajoutée')
        form.resetFields()
        hideModal()
        // Api create don't return landing page ID
        window.location.reload()
      }
    )
  }
}

const mapStateToProps = (state: IRootState) => ({
  locale: state.authState.locale,
  loading: state.landingState.loading,
  landingPages: state.landingState.landings
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  createMediaStore: (file: any) => dispatch(createMedia(file)),
  createLanding: (landing: LandingPageCreationDTO) => dispatch(createLanding(landing))
})

const LandingsCreateForm = Form.create({ name: 'landings_create_form' })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateForm)
)

export default LandingsCreateForm
