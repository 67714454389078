import { GlobalConfigDTO, GlobalConfigUpdateDTO, PaginationRequest } from '@vaneauneuf/dtos'
import { Dispatch } from 'redux'
import { IRootState } from '..'
import { apiRequest } from '../utils/api'
import { setGlobalConfig, setLoading } from './actions'

export const fetchGlobalConfig = (paginationRequest?: PaginationRequest) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const globalConfig = await apiRequest<GlobalConfigDTO>(
      'GET',
      getState().authState.token,
      '/globalConfig',
      undefined,
      paginationRequest
    )
    dispatch(setGlobalConfig(globalConfig))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const updateGlobalConfig = (id: string, globalConfig: GlobalConfigUpdateDTO) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const globalConfigUpdated = await apiRequest<GlobalConfigDTO>(
      'PATCH',
      getState().authState.token,
      '/globalConfig/' + id,
      globalConfig
    )
    dispatch(setGlobalConfig(globalConfigUpdated))
  } catch (e) {
    dispatch(setLoading(false))
    throw e.response.data
  }
  dispatch(setLoading(false))
}
