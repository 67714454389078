import { Form } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React from 'react'

import Wysiwyg from '../Wysiwyg/Wysiwyg'

interface IProps {
  fieldTitle: string
  seoFooterLinksName: string
  seoFooterTextName: string
  getFieldDecorator: any
  disabled: boolean
}

const SeoFooterForm = ({
  fieldTitle,
  seoFooterLinksName,
  seoFooterTextName,
  getFieldDecorator,
  disabled = false
}: IProps) => {
  return (
    <>
      <Form.Item label={`${fieldTitle} – Texte SEO pied de page`}>
        {getFieldDecorator(seoFooterTextName)(<Wysiwyg disabled={disabled} />)}
      </Form.Item>
      <Form.Item label={`${fieldTitle} – Liens SEO pied de page (un lien par ligne)`}>
        {getFieldDecorator(seoFooterLinksName)(
          <TextArea placeholder={`Titre du lien;https://urldulien.com`} disabled={disabled} />
        )}
      </Form.Item>
    </>
  )
}

export default SeoFooterForm
