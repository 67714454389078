"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ErrorsCode;

(function (ErrorsCode) {
  ErrorsCode["Bad_Request"] = "Bad_Request";
  ErrorsCode["Program_Not_Found"] = "Program_Not_Found";
  ErrorsCode["Promoter_Not_Found"] = "Promoter_Not_Found";
  ErrorsCode["Token_Expired"] = "Token_Expired";
  ErrorsCode["Token_Invalid"] = "Token_Invalid";
  ErrorsCode["Unauthorized"] = "Unauthorized";
  ErrorsCode["User_Not_Found"] = "User_Not_Found";
  ErrorsCode["Account_Type_Invalid"] = "Account_Type_Invalid";
  ErrorsCode["Lot_Not_Found"] = "Lot_Not_Found";
  ErrorsCode["Email_Already_Exists"] = "Email_Already_Exists";
  ErrorsCode["Email_Not_Found"] = "Email_Not_Found";
  ErrorsCode["Wrong_Credentials"] = "Wrong_Credentials";
  ErrorsCode["Not_Found"] = "Not_Found";
  ErrorsCode["Invalid_File"] = "Invalid_File";
  ErrorsCode["Invalid_File_Name"] = "Invalid_File_Name";
  ErrorsCode["Internal_Server_Error"] = "Internal_Server_Error";
  ErrorsCode["Media_Not_Found"] = "Media_Not_Found";
  ErrorsCode["News_Not_Found"] = "News_Not_Found";
  ErrorsCode["NewsTag_Not_Found"] = "NewsTag_Not_Found";
  ErrorsCode["Alert_Not_Found"] = "Alert_Not_Found";
  ErrorsCode["Alert_Limit_Reached"] = "Alert_Limit_Reached";
  ErrorsCode["Insufficient_Privileges"] = "Insufficient_Privileges";
  ErrorsCode["User_Is_Not_Member"] = "User_Is_Not_Member";
  ErrorsCode["Wish_Not_Found"] = "Wish_Not_Found";
  ErrorsCode["Wish_Limit_Reached"] = "Wish_Limit_Reached";
  ErrorsCode["Wish_Already_Exists"] = "Wish_Already_Exists";
  ErrorsCode["Project_Not_Found"] = "Project_Not_Found";
  ErrorsCode["Project_Limit_Reached"] = "Project_Limit_Reached";
  ErrorsCode["Favorite_Not_Found"] = "Favorite_Not_Found";
  ErrorsCode["Favorite_Already_Exists"] = "Favorite_Already_Exists";
  ErrorsCode["Account_Not_Validated"] = "Account_Not_Validated";
  ErrorsCode["Mail_Token_Expired"] = "Mail_Token_Expired";
  ErrorsCode["Invalid_Uuid"] = "Invalid_Uuid";
  ErrorsCode["Name_Already_Exist"] = "Name_Already_Exist";
  ErrorsCode["LandingPage_Not_Found"] = "LandingPage_Not_Found";
  ErrorsCode["Simulator_Limit_Reached"] = "Simulator_Limit_Reached";
  ErrorsCode["Program_Already_Exist"] = "Program_Already_Exist";
  ErrorsCode["Lot_Already_Exist"] = "Lot_Already_Exist";
  ErrorsCode["External_Request_Failed"] = "External_Request_Failed";
  ErrorsCode["Mgc_Program_Already_Exist"] = "Mgc_Program_Already_Exist";
  ErrorsCode["Timeout"] = "Timeout";
})(ErrorsCode = exports.ErrorsCode || (exports.ErrorsCode = {}));