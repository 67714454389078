import { NewsTagPrivateDTO } from '@vaneauneuf/dtos'

export enum ActionsTypes {
  SET_NEWSTAG_LIST = 'SET_NEWSTAG_LIST',
  SET_NEWSTAG = 'SET_NEWSTAG',
  ADD_NEWSTAG = 'ADD_NEWSTAG',
  REMOVE_NEWSTAG = 'REMOVE_NEWSTAG',
  SET_NEWSTAG_LOADING = 'SET_NEWSTAG_LOADING'
}

export interface INewsTagState {
  newsTagList: NewsTagPrivateDTO[]
  loading: boolean
}
