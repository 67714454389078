import { createReducer } from '../utils'
import {
  removeUser,
  setContact,
  setContacts,
  setLoading,
  setMember,
  setMemberAlerts,
  setMemberFavorites,
  setMemberProjects,
  setMembers,
  setMemberWishes
} from './actions'
import { ActionsTypes, IUserState } from './model'

const initialState: IUserState = {
  contacts: [],
  members: [],
  projects: [],
  alerts: [],
  favorites: [],
  wishes: [],
  total: 0,
  loading: false
}

const actionHandlers = {
  [ActionsTypes.SET_CONTACTS]: (
    state: IUserState,
    { contacts }: ReturnType<typeof setContacts>
  ): IUserState => ({
    ...state,
    contacts: contacts.data,
    total: contacts.total
  }),
  [ActionsTypes.SET_CONTACT]: (
    state: IUserState,
    { contact }: ReturnType<typeof setContact>
  ): IUserState => ({
    ...state,
    contacts: [
      ...state.contacts.filter(currentContact => currentContact.id !== contact.id),
      contact
    ]
  }),
  [ActionsTypes.SET_MEMBERS]: (
    state: IUserState,
    { members }: ReturnType<typeof setMembers>
  ): IUserState => ({
    ...state,
    members: members.data,
    total: members.total
  }),
  [ActionsTypes.SET_MEMBER]: (
    state: IUserState,
    { member }: ReturnType<typeof setMember>
  ): IUserState => ({
    ...state,
    members: [...state.members.filter(currentMember => currentMember.id !== member.id), member]
  }),

  [ActionsTypes.SET_PROJECTS]: (
    state: IUserState,
    { projects }: ReturnType<typeof setMemberProjects>
  ): IUserState => ({
    ...state,
    projects
  }),

  [ActionsTypes.SET_ALERTS]: (
    state: IUserState,
    { alerts }: ReturnType<typeof setMemberAlerts>
  ): IUserState => ({
    ...state,
    alerts
  }),
  [ActionsTypes.SET_FAVORITES]: (
    state: IUserState,
    { favorites }: ReturnType<typeof setMemberFavorites>
  ): IUserState => ({
    ...state,
    favorites
  }),
  [ActionsTypes.SET_WISHES]: (
    state: IUserState,
    { wishes }: ReturnType<typeof setMemberWishes>
  ): IUserState => ({
    ...state,
    wishes
  }),

  [ActionsTypes.REMOVE_USER]: (
    state: IUserState,
    { idUser }: ReturnType<typeof removeUser>
  ): IUserState => ({
    ...state,
    contacts: [...state.contacts].filter(contact => contact.id !== idUser)
  }),
  [ActionsTypes.SET_USER_LOADING]: (
    state: IUserState,
    { loading }: ReturnType<typeof setLoading>
  ): IUserState => ({
    ...state,
    loading
  })
}

export default createReducer(initialState, actionHandlers)
