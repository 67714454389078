"use strict";

var _classCallCheck = require("/srv/packages/backoffice/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var FilesToImportDTO = function FilesToImportDTO() {
  _classCallCheck(this, FilesToImportDTO);
};

exports.FilesToImportDTO = FilesToImportDTO;

var FileToImportDTO = function FileToImportDTO() {
  _classCallCheck(this, FileToImportDTO);
};

exports.FileToImportDTO = FileToImportDTO;
var FileType;

(function (FileType) {
  FileType[FileType["Picture"] = 0] = "Picture";
  FileType[FileType["GroundPlane"] = 1] = "GroundPlane";
  FileType[FileType["Plan2D"] = 2] = "Plan2D";
  FileType[FileType["Plan3D"] = 3] = "Plan3D";
  FileType[FileType["Booklet"] = 4] = "Booklet";
})(FileType = exports.FileType || (exports.FileType = {}));