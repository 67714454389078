import {
  FiscalTools,
  HeatingTypes,
  LotCreationDTO,
  LotSubTypes,
  LotTypes,
  ParkingTypes,
  PinelZones
} from '@vaneauneuf/dtos'
import Checkbox from 'antd/lib/checkbox'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import InputNumber from 'antd/lib/input-number'
import TextArea from 'antd/lib/input/TextArea'
import message from 'antd/lib/message'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IComponentFormCreateProps } from '../../../components/CreateForm/CreateForm'
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent'
import { IRootState } from '../../../redux'
import { createLot } from '../../../redux/lot'
import styles from './LotsCreateForm.module.scss'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IComponentFormCreateProps

interface IState {
  errors: string[]
}

class CreateForm extends React.Component<IProps, IState> {
  public state: IState = {
    errors: []
  }

  public render() {
    const { visible, hideModal, loading, form } = this.props
    const { errors } = this.state
    const { getFieldDecorator } = form
    return (
      <Modal
        visible={visible}
        title="Ajouter un nouveau lot"
        okText="Sauvegarder"
        onCancel={hideModal}
        onOk={this.createLot}
        confirmLoading={loading}
      >
        <Form>
          <Form.Item label="Référence">
            {getFieldDecorator('reference', {
              rules: [{ required: true, message: 'Please input the reference of lot!' }]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Prix">
            {getFieldDecorator('price', {
              rules: [{ required: true, message: 'Please input the price of lot!' }]
            })(<InputNumber className={styles.inputNumber} />)}
          </Form.Item>
          <Form.Item label="Surface">
            {getFieldDecorator('surface', {
              rules: [{ required: true, message: 'Please input the surface of lot!' }]
            })(<InputNumber className={styles.inputNumber} />)}
          </Form.Item>
          <Form.Item label="Etage">
            {getFieldDecorator('floor', {
              rules: [{ required: true, message: 'Please input the floor of lot!' }]
            })(<InputNumber className={styles.inputNumber} />)}
          </Form.Item>
          <Form.Item label="Type">
            {getFieldDecorator('type', {
              rules: [{ required: true, message: 'Please select the type of lot!' }]
            })(
              <Select>
                {Object.values(LotTypes).map(lotType => (
                  <Select.Option key={lotType} value={lotType}>
                    {lotType}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          {/* <Form.Item label="Sous type">
            {getFieldDecorator('subType')(
              <Select>
                {Object.values(LotSubTypes).map(lotSubType => (
                  <Select.Option key={lotSubType} value={lotSubType}>
                    {lotSubType}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item> */}
          <Form.Item label="Outil fiscal">
            {getFieldDecorator('fiscalTool')(
              <Select>
                {Object.values(FiscalTools).map(fiscalTool => (
                  <Select.Option key={fiscalTool} value={fiscalTool}>
                    {fiscalTool}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Zone pinel">
            {getFieldDecorator('pinelZone')(
              <Select>
                {Object.values(PinelZones).map(pinelZone => (
                  <Select.Option key={pinelZone} value={pinelZone}>
                    {pinelZone}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Type de parking">
            {getFieldDecorator('parkingType')(
              <Select>
                {Object.values(ParkingTypes).map(parkingType => (
                  <Select.Option key={parkingType} value={parkingType}>
                    {parkingType}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Type de chauffage">
            {getFieldDecorator('heatingType')(
              <Select allowClear>
                {Object.values(HeatingTypes).map(heatingType => (
                  <Select.Option key={heatingType} value={heatingType}>
                    {heatingType}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Exposition">
            {getFieldDecorator('northExposition', {
              initialValue: false
            })(<Checkbox>North</Checkbox>)}
            {getFieldDecorator('southExposition', {
              initialValue: false
            })(<Checkbox>South</Checkbox>)}
            {getFieldDecorator('eastExposition', {
              initialValue: false
            })(<Checkbox>East</Checkbox>)}
            {getFieldDecorator('westExposition', {
              initialValue: false
            })(<Checkbox>West</Checkbox>)}
          </Form.Item>
          <Form.Item label="Nombre de pièces">
            {getFieldDecorator('roomsCount', {
              rules: [{ required: true, message: 'Please input the rooms count of lot!' }]
            })(<InputNumber className={styles.inputNumber} />)}
          </Form.Item>
          <Form.Item label="Nombre d'extérieurs">
            {getFieldDecorator('exteriorsCount')(<InputNumber className={styles.inputNumber} />)}
          </Form.Item>
          <Form.Item label="Régulation thermique">
            {getFieldDecorator('bbc', {
              initialValue: false
            })(<Checkbox>BBC</Checkbox>)}
            {getFieldDecorator('RT2012', {
              initialValue: false
            })(<Checkbox>RT2012</Checkbox>)}
          </Form.Item>
          <Form.Item label="Caractéristiques">
            {getFieldDecorator('elevator', {
              initialValue: false
            })(<Checkbox>Elevator</Checkbox>)}
            {getFieldDecorator('zeroLoan', {
              initialValue: false
            })(<Checkbox>Zero Loan</Checkbox>)}
          </Form.Item>
          <Form.Item label="Nombre de caves">
            {getFieldDecorator('basementNb')(<InputNumber className={styles.inputNumber} />)}
          </Form.Item>
          <Form.Item label="Surface totale des caves (m²)">
            {getFieldDecorator('basementSurface')(<InputNumber className={styles.inputNumber} />)}
          </Form.Item>
          <Form.Item label="Nombre de balcons">
            {getFieldDecorator('balconyNb')(<InputNumber className={styles.inputNumber} />)}
          </Form.Item>
          <Form.Item label="Surface totale des balcons (m²)">
            {getFieldDecorator('balconySurface')(<InputNumber className={styles.inputNumber} />)}
          </Form.Item>
          <Form.Item label="Nombre de terrasses">
            {getFieldDecorator('terraceNb')(<InputNumber className={styles.inputNumber} />)}
          </Form.Item>
          <Form.Item label="Surface total des terrasses (m²)">
            {getFieldDecorator('terraceSurface')(<InputNumber className={styles.inputNumber} />)}
          </Form.Item>
          <Form.Item label="Nombre de jardins">
            {getFieldDecorator('yardNb')(<InputNumber className={styles.inputNumber} />)}
          </Form.Item>
          <Form.Item label="Surface totale des jardins (m²)">
            {getFieldDecorator('yardSurface')(<InputNumber className={styles.inputNumber} />)}
          </Form.Item>
        </Form>
        <ErrorComponent errors={errors} />
      </Modal>
    )
  }

  private createLot = async () => {
    const { hideModal, form } = this.props
    this.setState({ errors: [] })

    form.validateFieldsAndScroll(async (errors, fieldsValue: LotCreationDTO) => {
      if (errors) {
        return
      }

      if (!this.props.match || !this.props.match.params.idProgram) {
        return
      }

      const values = {
        ...fieldsValue,
        programId: this.props.match.params.idProgram
      }

      try {
        await this.props.createLot(values)
      } catch ({ errors }) {
        if (errors) {
          this.setState({ errors })
        }
        return
      }

      message.success('Le lot a bien été ajouté')
      form.resetFields()
      hideModal()
    })
  }
}

const mapStateToProps = (state: IRootState) => ({
  loading: state.lotState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  createLot: (lot: LotCreationDTO) => dispatch(createLot(lot))
})

const LotsCreateForm = Form.create({ name: 'lots_create_form' })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateForm)
)

export default LotsCreateForm
