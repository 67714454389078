import { Action, AnyAction } from 'redux'

export type Reducer<S = any, A extends Action<any> = any> = (state: S, action: A) => S

export interface IReducerHandler<TState> {
  [type: string]: Reducer<TState>
}

export const createReducer = <TState>(initialState: TState, handlers: IReducerHandler<TState>) => (
  state = initialState,
  action: AnyAction
): TState => {
  try {
    return action.type && handlers[action.type] ? handlers[action.type](state, action) : state
  } catch (e) {
    throw e
  }
}
