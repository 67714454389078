import { PromoterPrivateDTO } from '@vaneauneuf/dtos'
import { ActionsTypes } from './model'

export const setPromoters = (promoters: PromoterPrivateDTO[]) => ({
  type: ActionsTypes.SET_PROMOTERS,
  promoters
})

export const setPromoter = (promoter: PromoterPrivateDTO) => ({
  type: ActionsTypes.SET_PROMOTER,
  promoter
})

export const addPromoter = (promoter: PromoterPrivateDTO) => ({
  type: ActionsTypes.ADD_PROMOTER,
  promoter
})

export const removePromoter = (idPromoter: string) => ({
  type: ActionsTypes.REMOVE_PROMOTER,
  idPromoter
})

export const setLoading = (loading: boolean) => ({
  type: ActionsTypes.SET_PROMOTER_LOADING,
  loading
})
