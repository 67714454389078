import { Icon } from 'antd'
import React, { useEffect, useState } from 'react'
import styles from './Media.module.scss'

interface IMedia {
  id: string
  mediaUrl: string
}

interface IOwnProps {
  value?: IMedia | File
  onChange?: (file: File) => void
  disabled?: boolean
}

const Media = ({ value, onChange, disabled = false }: IOwnProps) => {
  const [media, setMedia] = useState<IMedia>()

  useEffect(() => {
    if (value instanceof File) {
      const reader = new FileReader()
      reader.onload = () => {
        if (typeof reader.result !== 'string') {
          console.error('Could not get file contents')

          return
        }

        setMedia({ id: '', mediaUrl: reader.result })
      }

      reader.readAsDataURL(value)
    } else {
      value && setMedia(value)
    }
  }, [value])

  const removeMedia = () => {
    setMedia(undefined)
  }

  const onFileChange = (event: { target: HTMLInputElement }) => {
    const { target } = event
    const file = (target.files || [])[0]
    file && onChange && onChange(file)
  }

  return (
    <div className={styles.container}>
      {media && media.mediaUrl && (
        <div className={styles.imageContainer}>
          <img className={styles.picture} src={media.mediaUrl} />
          {!disabled && (
            <div className={styles.deleteButton} onClick={() => removeMedia()}>
              <Icon type="close" />
            </div>
          )}
        </div>
      )}
      <input type="file" onChange={onFileChange} disabled={disabled} />
    </div>
  )
}

export default Media
