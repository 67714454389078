import { NewsTagPrivateDTO } from '@vaneauneuf/dtos'
import { ActionsTypes } from './model'

export const setNewsTagList = (newsTagList: NewsTagPrivateDTO[]) => ({
  type: ActionsTypes.SET_NEWSTAG_LIST,
  newsTagList
})

export const setNewsTag = (newsTag: NewsTagPrivateDTO) => ({
  type: ActionsTypes.SET_NEWSTAG,
  newsTag
})

export const addNewsTag = (newsTag: NewsTagPrivateDTO) => ({
  type: ActionsTypes.ADD_NEWSTAG,
  newsTag
})

export const removeNewsTag = (idNewsTag: string) => ({
  type: ActionsTypes.REMOVE_NEWSTAG,
  idNewsTag
})

export const setLoading = (loading: boolean) => ({
  type: ActionsTypes.SET_NEWSTAG_LOADING,
  loading
})
