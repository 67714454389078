"use strict";

var _classCallCheck = require("/srv/packages/backoffice/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

var lot_1 = require("./lot");

var InvestAmountRanges;

(function (InvestAmountRanges) {
  InvestAmountRanges["Less120k"] = "< 120 k";
  InvestAmountRanges["From120kTo160k"] = "120 - 160 k";
  InvestAmountRanges["From160kTo190k"] = "160 - 190 k";
  InvestAmountRanges["From190kTo220k"] = "190 - 220 k";
  InvestAmountRanges["From220kTo260k"] = "220 - 260 k";
  InvestAmountRanges["From260kTo300k"] = "260 - 300 k";
  InvestAmountRanges["From300kTo350k"] = "300 - 350 k";
  InvestAmountRanges["From350kTo400k"] = "350 - 400 k";
  InvestAmountRanges["From400kTo450k"] = "400 - 450 k";
  InvestAmountRanges["From450kTo500k"] = "450 - 500 k";
  InvestAmountRanges["From500k"] = "+ de 500 k";
})(InvestAmountRanges = exports.InvestAmountRanges || (exports.InvestAmountRanges = {}));

var ProjectPrivateDTO = function ProjectPrivateDTO() {
  _classCallCheck(this, ProjectPrivateDTO);
};

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], ProjectPrivateDTO.prototype, "id", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], ProjectPrivateDTO.prototype, "city", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], ProjectPrivateDTO.prototype, "postalCode", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], ProjectPrivateDTO.prototype, "department", void 0);

__decorate([class_validator_1.IsEnum(InvestAmountRanges), __metadata("design:type", String)], ProjectPrivateDTO.prototype, "investAmount", void 0);

__decorate([class_validator_1.IsEnum(lot_1.FiscalTools), __metadata("design:type", String)], ProjectPrivateDTO.prototype, "fiscalTool", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], ProjectPrivateDTO.prototype, "complementaryInformation", void 0);

__decorate([class_validator_1.IsDateString(), __metadata("design:type", Date)], ProjectPrivateDTO.prototype, "createDate", void 0);

exports.ProjectPrivateDTO = ProjectPrivateDTO;

var ProjectCreationDTO = function ProjectCreationDTO() {
  _classCallCheck(this, ProjectCreationDTO);
};

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], ProjectCreationDTO.prototype, "city", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], ProjectCreationDTO.prototype, "postalCode", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], ProjectCreationDTO.prototype, "department", void 0);

__decorate([class_validator_1.IsEnum(InvestAmountRanges), __metadata("design:type", String)], ProjectCreationDTO.prototype, "investAmount", void 0);

__decorate([class_validator_1.IsEnum(lot_1.FiscalTools), __metadata("design:type", String)], ProjectCreationDTO.prototype, "fiscalTool", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], ProjectCreationDTO.prototype, "complementaryInformation", void 0);

exports.ProjectCreationDTO = ProjectCreationDTO;

var ProjectUpdateDTO = function ProjectUpdateDTO() {
  _classCallCheck(this, ProjectUpdateDTO);
};

__decorate([class_validator_1.IsString(), class_validator_1.IsOptional(), __metadata("design:type", String)], ProjectUpdateDTO.prototype, "city", void 0);

__decorate([class_validator_1.IsString(), class_validator_1.IsOptional(), __metadata("design:type", String)], ProjectUpdateDTO.prototype, "postalCode", void 0);

__decorate([class_validator_1.IsString(), class_validator_1.IsOptional(), __metadata("design:type", String)], ProjectUpdateDTO.prototype, "department", void 0);

__decorate([class_validator_1.IsEnum(InvestAmountRanges), class_validator_1.IsOptional(), __metadata("design:type", String)], ProjectUpdateDTO.prototype, "investAmount", void 0);

__decorate([class_validator_1.IsEnum(lot_1.FiscalTools), class_validator_1.IsOptional(), __metadata("design:type", String)], ProjectUpdateDTO.prototype, "fiscalTool", void 0);

__decorate([class_validator_1.IsString(), class_validator_1.IsOptional(), __metadata("design:type", String)], ProjectUpdateDTO.prototype, "complementaryInformation", void 0);

exports.ProjectUpdateDTO = ProjectUpdateDTO;