"use strict";

var _classCallCheck = require("/srv/packages/backoffice/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var accountTypes_1 = require("./accountTypes");

var class_validator_1 = require("class-validator");

var AuthDTO = function AuthDTO() {
  _classCallCheck(this, AuthDTO);
};

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], AuthDTO.prototype, "accountId", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], AuthDTO.prototype, "token", void 0);

__decorate([class_validator_1.IsEnum(accountTypes_1.AccountTypes), __metadata("design:type", String)], AuthDTO.prototype, "type", void 0);

__decorate([class_validator_1.IsBoolean(), __metadata("design:type", Boolean)], AuthDTO.prototype, "isAdmin", void 0);

exports.AuthDTO = AuthDTO;

var LoginDTO = function LoginDTO() {
  _classCallCheck(this, LoginDTO);
};

__decorate([class_validator_1.IsEmail(), __metadata("design:type", String)], LoginDTO.prototype, "email", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], LoginDTO.prototype, "password", void 0);

exports.LoginDTO = LoginDTO;