import React from 'react'
import { match as Match } from 'react-router-dom'
import UpdateForm from '../../components/UpdateForm/UpdateForm'
import styles from './PromoterPage.module.scss'
import PromoterUpdateForm from './PromoterUpdateForm/PromoterUpdateForm'

interface IProps {
  match: Match<{ idPromoter: string }>
}

const PromoterPage: React.FunctionComponent<IProps> = props => (
  <div className={styles.container}>
    <h1>Promoter detail</h1>
    <UpdateForm component={PromoterUpdateForm} match={props.match} />
  </div>
)

export default PromoterPage
