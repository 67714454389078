import { AccountDTO } from '@vaneauneuf/dtos'

export enum ActionsTypes {
  SET_ADMINS = 'SET_ADMINS',
  SET_ADMIN = 'SET_ADMIN',
  ADD_ADMIN = 'ADD_ADMIN',
  REMOVE_ADMIN = 'REMOVE_ADMIN',
  SET_ADMIN_LOADING = 'SET_ADMIN_LOADING'
}

export interface IAdminsState {
  admins: AccountDTO[]
  loading: boolean
}
