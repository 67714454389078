import { useEffect, useState } from 'react'

interface IProps {
  value: any
  delay: number
  exceptions?: any[]
  callback?: () => void
}

export default function useDebounce({ value, delay = 200, exceptions = [], callback }: IProps) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    if (exceptions.find(exception => exception === value)) {
      setDebouncedValue(value)

      return
    }

    const handler = setTimeout(() => {
      if (JSON.stringify(debouncedValue) !== JSON.stringify(value)) {
        callback && callback()
        setDebouncedValue(value)
      }
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}
