import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { fetchGlobalConfig } from '../../redux/GlobalConfig'
import styles from './GlobalConfigPage.module.scss'
import GlobalConfigUpdateForm from './GlobalConfigUpdateForm/GlobalConfigUpdateForm'

type IProps = ReturnType<typeof mapDispatchToProps>

const GlobalConfigPage: React.FC<IProps> = ({ fetchGlobalConfigStore }) => {
  useEffect(() => {
    fetchGlobalConfigStore()
  }, [])

  return (
    <div className={styles.container}>
      <h1>Configuration globale</h1>
      <GlobalConfigUpdateForm />
    </div>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchGlobalConfigStore: () => dispatch(fetchGlobalConfig())
})

export default connect(
  undefined,
  mapDispatchToProps
)(GlobalConfigPage)
