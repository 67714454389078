import React, { FC } from 'react'
import { match as Match } from 'react-router-dom'
import UpdateForm from '../../components/UpdateForm/UpdateForm'
import styles from './LandingPage.module.scss'
import LandingUpdateForm from './LandingUpdateForm/LandingUpdateForm'

interface IProps {
  match: Match<{ idLanding: string }>
}

const LandingPage: FC<IProps> = props => (
  <div className={styles.container}>
    <h1>Détail de la landing page</h1>
    <UpdateForm component={LandingUpdateForm} match={props.match} />
  </div>
)

export default LandingPage
