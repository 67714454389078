import { ProgramFilterDTO } from '@vaneauneuf/dtos'
import React, { useState } from 'react'
import CreateForm from '../../components/CreateForm/CreateForm'
import ProgramsCreateForm from './ProgramsCreateForm/ProgramsCreateForm'
import ProgramsFilter from './ProgramsFilter/ProgramsFilter'
import styles from './ProgramsPage.module.scss'
import ProgramsTable from './ProgramsTable/ProgramsTable'

export const PAGE_SIZE = 50

const ProgramsPage: React.FC = () => {
  const [filters, setFilters] = useState<ProgramFilterDTO>({ city: '', name: '', promoter: '' })

  return (
    <div className={styles.container}>
      <h1>Programmes</h1>
      <CreateForm entity="program" component={ProgramsCreateForm} />
      <ProgramsFilter filters={filters} onUpdateFilters={setFilters} />
      <ProgramsTable filters={filters} />
    </div>
  )
}

export default ProgramsPage
