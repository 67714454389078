import { ProgramFilterDTO, ProgramPrivateDTO, ProgramSearchDTO } from '@vaneauneuf/dtos'

export enum ActionsTypes {
  SET_PROGRAMS = 'SET_PROGRAMS',
  SET_PROGRAM = 'SET_PROGRAM',
  ADD_PROGRAM = 'ADD_PROGRAM',
  REMOVE_PROGRAM = 'REMOVE_PROGRAM',
  SET_PROGRAM_LOADING = 'SET_PROGRAM_LOADING'
}

export interface IProgramState {
  programs: ProgramPrivateDTO[]
  total: number
  loading: boolean
}
