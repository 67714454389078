import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { IRootState } from '../redux'

type IProps = ReturnType<typeof mapStateToProps> & RouteProps

/**
 * Route that redirect to login page if user is not connected
 */
const PrivateRoute: React.FunctionComponent<IProps> = ({ token, ...rest }) =>
  token ? <Route {...rest} /> : <Redirect to={{ pathname: '/login' }} />

const mapStateToProps = (state: IRootState) => ({
  token: state.authState.token
})

export default connect(mapStateToProps)(PrivateRoute)
