import { PaginationRequest, WishFilterDTO, WishPrivateDTO, WishUpdateDTO } from '@vaneauneuf/dtos'
import { PaginationConfig } from 'antd/lib/pagination'
import Table, { ColumnProps, SorterResult } from 'antd/lib/table'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import { fetchWishes, updateWish } from '../../../redux/wish'
import { PAGE_SIZE } from '../WishesPage'
import { WishEditableCell } from './WishEditableCell/WishEditableCell'
import { WishEditableFormRow } from './WishEditableFormRow/WishEditableFormRow'
import styles from './WishesTable.module.scss'

interface IWishesTableProps {
  filters: WishFilterDTO
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IWishesTableProps

class WishesTable extends React.Component<IProps> {
  private columns: Array<ColumnProps<WishPrivateDTO> & { editable?: boolean }> = [
    {
      title: 'Nom du programme',
      dataIndex: 'name',
      render: (name, record) => <Link to={'/programs/' + record.idProgram}>{name}</Link>,
      sorter: true
    },
    {
      title: 'Référence du lot',
      dataIndex: 'reference',
      render: (name, record) => (
        <Link to={`/programs/${record.idProgram}/lots/${record.idLot}`}>{name}</Link>
      ),
      sorter: true
    },
    {
      title: 'Adresse e-mail du demandeur',
      dataIndex: 'userEmail',
      sorter: true
    },
    {
      title: 'Réponse',
      dataIndex: 'answer',
      sorter: true,
      editable: true
    },
    {
      title: 'Date de demande',
      dataIndex: 'createDate',
      render: (form, record) => new Date((record as any).createDate).toLocaleString(),
      sorter: true
    }
  ]

  public async componentDidMount() {
    await this.props.fetchWishes({ take: PAGE_SIZE, ...this.props.filters })
  }

  public render() {
    const components = {
      body: {
        row: WishEditableFormRow,
        cell: WishEditableCell
      }
    }
    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: (record: WishPrivateDTO) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave
        })
      }
    })

    return (
      <Table
        components={components}
        columns={columns}
        dataSource={this.props.wishes}
        rowKey="id"
        rowClassName={() => styles.editableRow}
        pagination={{ total: this.props.total, defaultPageSize: PAGE_SIZE }}
        onChange={this.handleTableChange}
        loading={this.props.loading}
      />
    )
  }

  private handleSave = (row: WishPrivateDTO) => {
    this.props.updateWish(row.id, { answer: row.answer })
  }

  private handleTableChange = (
    pagination: PaginationConfig,
    filters: any,
    sorter: SorterResult<WishPrivateDTO>
  ) => {
    let sorterField

    if (sorter.field === 'name') {
      sorterField = 'program.name'
    } else if (sorter.field === 'reference') {
      sorterField = 'lot.reference'
    } else if (sorter.field === 'userEmail') {
      sorterField = 'account.email'
    } else {
      sorterField = 'wish.' + sorter.field
    }

    this.props.fetchWishes({
      skip: pagination.current ? (pagination.current - 1) * PAGE_SIZE : 0,
      take: PAGE_SIZE,
      order: { [sorterField]: sorter.order === 'ascend' ? 'ASC' : 'DESC' },
      ...this.props.filters
    })
  }
}

const mapStateToProps = (state: IRootState) => ({
  wishes: state.wishState.wishes,
  total: state.wishState.total,
  loading: state.wishState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchWishes: (paginationRequest?: PaginationRequest) => dispatch(fetchWishes(paginationRequest)),
  updateWish: (id: string, wish: WishUpdateDTO) => dispatch(updateWish(id, wish))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WishesTable)
