import React from 'react'
import { match as Match } from 'react-router-dom'
import UpdateForm from '../../components/UpdateForm/UpdateForm'
import styles from './AdminPage.module.scss'
import AdminUpdateForm from './AdminUpdateForm/AdminUpdateForm'

interface IProps {
  match: Match<{ idAdmin: string }>
}

const AdminPage: React.FunctionComponent<IProps> = props => (
  <div className={styles.container}>
    <h1>Admin detail</h1>
    <UpdateForm component={AdminUpdateForm} match={props.match} />
  </div>
)

export default AdminPage
