import Button from 'antd/lib/button'
import { FormComponentProps } from 'antd/lib/form'
import React, { memo, useState } from 'react'
import { match as Match } from 'react-router-dom'
import styles from './CreateForm.module.scss'

interface ICreateFormProps {
  entity: string
  component: React.ComponentType<IFormComponentPropsCustom>
  match?: Match<{ idProgram?: string }>
}

interface IFormComponentPropsCustom {
  visible: boolean
  hideModal: () => void
  match?: Match<{ idProgram?: string }>
}

export type IComponentFormCreateProps = FormComponentProps & IFormComponentPropsCustom

export default memo(function CreateForm({
  component: ComponentForm,
  entity,
  match
}: ICreateFormProps) {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <div className={styles.addButtonContainer}>
        <Button type="primary" onClick={() => setVisible(true)}>
          {`Créer ${entity}`}
        </Button>
      </div>
      <ComponentForm visible={visible} hideModal={() => setVisible(false)} match={match} />
    </>
  )
})
