import { createReducer } from '../utils'
import { addLanding, removeLanding, setLanding, setLandings, setLoading } from './actions'
import { ActionsTypes, ILandingState } from './model'

const initialState: ILandingState = {
  landings: [],
  loading: false
}

const actionHandlers = {
  [ActionsTypes.SET_LANDINGS]: (
    state: ILandingState,
    { landings }: ReturnType<typeof setLandings>
  ): ILandingState => ({
    ...state,
    landings
  }),
  [ActionsTypes.SET_LANDING]: (
    state: ILandingState,
    { landing }: ReturnType<typeof setLanding>
  ): ILandingState => ({
    ...state,
    landings: state.landings.map(l => (l.id === landing.id ? landing : l))
  }),
  [ActionsTypes.ADD_LANDING]: (
    state: ILandingState,
    { landing }: ReturnType<typeof addLanding>
  ): ILandingState => ({
    ...state,
    landings: [...state.landings.filter(l => l.id !== landing.id), landing]
  }),
  [ActionsTypes.REMOVE_LANDING]: (
    state: ILandingState,
    { idLanding }: ReturnType<typeof removeLanding>
  ): ILandingState => ({
    ...state,
    landings: state.landings.filter(landing => landing.id !== idLanding)
  }),
  [ActionsTypes.SET_LANDING_LOADING]: (
    state: ILandingState,
    { loading }: ReturnType<typeof setLoading>
  ): ILandingState => ({
    ...state,
    loading
  })
}

export default createReducer(initialState, actionHandlers)
