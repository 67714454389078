import { LandingPagePrivateDTO } from '@vaneauneuf/dtos'
import Modal from 'antd/lib/modal'
import Table, { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import { deleteLanding, fetchLandings, moveLanding } from '../../../redux/landing'
import styles from './LandingsTable.module.scss'

const PAGE_SIZE = 50

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

type RowRecord = LandingPagePrivateDTO & { canGoUp: boolean; canGoDown: boolean }

class LandingsTable extends React.Component<IProps> {
  private columns: Array<ColumnProps<RowRecord>> = [
    {
      title: 'Page liste',
      dataIndex: 'search',
      render: (name, record) => (record.search ? 'oui' : 'non')
    },
    {
      title: 'Titre',
      dataIndex: 'title',
      render: (name, record) => <Link to={'/landings/' + record.id}>{name}</Link>
    },
    {
      title: 'Sous-titre',
      dataIndex: 'subTitle'
    },
    {
      title: 'Slug',
      dataIndex: 'slug'
    },
    {
      title: 'Activé',
      dataIndex: 'enabled',
      render: enabled => (enabled ? 'Oui' : 'Non')
    },
    {
      title: '↑↓',
      render: (text, record) => (
        <div className={styles.moveWrapper}>
          <button
            disabled={!record.canGoUp}
            onClick={() => this.props.moveLanding(record.id, record.order - 1)}
          >
            ↑ Monter
          </button>
          <button
            disabled={!record.canGoDown}
            onClick={() => this.props.moveLanding(record.id, record.order + 1)}
          >
            ↓ Descendre
          </button>
        </div>
      )
    },
    {
      title: 'Action',
      render: (text, record) => <a onClick={() => this.handleDelete(record.id)}>Supprimer</a>
    }
  ]

  public async componentDidMount() {
    await this.props.fetchLandings()
  }

  public render() {
    return (
      <>
        <Table
          columns={this.columns}
          dataSource={this.props.landings.map((landing, index) => ({
            ...landing,
            canGoUp: this.props.landings.length > 1 && index > 0,
            canGoDown: this.props.landings.length > 1 && index + 1 < this.props.landings.length
          }))}
          rowKey="id"
          pagination={{ defaultPageSize: PAGE_SIZE }}
          loading={this.props.loading}
        />
      </>
    )
  }

  private handleDelete = (id: string) => {
    Modal.confirm({
      title: 'Voulez vous vraiment supprimer cette landing page ?',
      onOk: () => this.props.deleteLanding(id)
    })
  }
}

const mapStateToProps = (state: IRootState) => ({
  landings: state.landingState.landings,
  loading: state.landingState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchLandings: () => dispatch(fetchLandings()),
  deleteLanding: (id: string) => dispatch(deleteLanding(id)),
  moveLanding: (id: string, order: number) => {
    dispatch(moveLanding(id, order)).then(() => {
      dispatch(fetchLandings())
    })
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingsTable)
