import {
  PaginationRequest,
  ProjectPrivateDTO,
  UserDTO,
  UserFilterDTO,
  UserFormCreationDTO
} from '@vaneauneuf/dtos'
import Modal from 'antd/lib/modal'
import Table, { ColumnProps, PaginationConfig, SorterResult } from 'antd/lib/table'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import { deleteUser, fetchMembers, fetchProjects } from '../../../redux/user'

interface IMemberTableProps {
  id: string
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IMemberTableProps

class MemberProjectTable extends React.Component<IProps> {
  private columns: Array<ColumnProps<ProjectPrivateDTO>> = [
    {
      title: 'Ville',
      dataIndex: 'city'
    },
    {
      title: 'Code postal',
      dataIndex: 'postalCode'
    },
    {
      title: 'Département',
      dataIndex: 'department'
    },
    {
      title: 'Montant investissement',
      dataIndex: 'investAmount'
    },
    {
      title: 'Outil fiscal',
      dataIndex: 'fiscalTool'
    },
    {
      title: 'Info. complémentaires',
      dataIndex: 'complementaryInformation'
    },
    {
      title: 'Date de création',
      dataIndex: 'createDate',
      render: createDate => new Date(createDate).toLocaleString()
    }
  ]

  public async componentDidMount() {
    await this.props.fetchProjects(this.props.id)
  }

  public render() {
    return (
      <Table
        columns={this.columns}
        dataSource={this.props.projects2}
        rowKey="id"
        loading={this.props.loading}
      />
    )
  }
}

const mapStateToProps = (state: IRootState) => ({
  projects2: state.userState.projects,
  loading: state.userState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchProjects: (id: string) => dispatch(fetchProjects(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberProjectTable)
