import { NewsUpdateDTO } from '@vaneauneuf/dtos'
import { Radio, Select } from 'antd'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import TextArea from 'antd/lib/input/TextArea'
import message from 'antd/lib/message'
import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent'
import { IComponentFormUpdateProps } from '../../../components/UpdateForm/UpdateForm'
import { IRootState } from '../../../redux'
import { fetchNews, getNews, updateNews } from '../../../redux/news'
import { fetchNewsTagList } from '../../../redux/newsTag'
import ISuggestion from '../../../utils/mapBox'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IComponentFormUpdateProps

interface IState {
  errors: string[]
  dataSource: ISuggestion[]
}

class UpdateForm extends React.Component<IProps, IState> {
  public state: IState = {
    errors: [],
    dataSource: []
  }

  public async componentDidMount() {
    if (!this.props.match.params.idNews) {
      return
    }

    await this.props.fetchNews(this.props.match.params.idNews)
    await this.props.fetchNewsTagList()
    this.fillForm()
  }

  public render() {
    const { edit, loading, form, newsTagList } = this.props
    const { errors } = this.state
    const { getFieldDecorator } = form

    return (
      <>
        <Form>
          <Form.Item label="Titre">
            {getFieldDecorator('title', {
              rules: [{ required: true, message: 'Veuillez entrer le titre' }]
            })(<Input placeholder="Titre de l'actualité" disabled={!edit} />)}
          </Form.Item>

          <Form.Item label="Introduction">
            {getFieldDecorator('introduction', {
              rules: [{ required: false, message: "Veuillez entrer l'introduction de l'actualité" }]
            })(<Input placeholder="Introduction qui apparaitra sous le titre" disabled={!edit} />)}
          </Form.Item>

          <Form.Item label="Url vers l'image">
            {getFieldDecorator('picture', {
              rules: [
                {
                  required: false,
                  message: 'Veuillez choisir une url pour une image'
                }
              ]
            })(<Input addonBefore="https://" placeholder="url-vers-image.com" disabled={!edit} />)}
          </Form.Item>

          <Form.Item label="Contenu">
            {getFieldDecorator('content', {
              rules: [{ required: true, message: "Veuillez entrer un contenu pour l'article" }]
            })(<TextArea placeholder="Texte en format markdown" disabled={!edit} />)}
            <span>
              Pour vous aider à rédiger, il est conseillé d'utiliser{' '}
              <a href="http://rexxars.github.io/markdown-editor/" target="_blank">
                l'outil en ligne
              </a>
            </span>
          </Form.Item>

          <Form.Item label="Slug">
            {getFieldDecorator('slug', {
              rules: [{ required: true, message: 'Veuillez entrer le slug' }]
            })(<Input placeholder="Slug de l'actualité" disabled={!edit} />)}
          </Form.Item>

          <Form.Item label="Balise meta titre">
            {getFieldDecorator('titleTag')(
              <Input maxLength={255} placeholder="Contenu de la balise titre" disabled={!edit} />
            )}
          </Form.Item>

          <Form.Item label="Balise meta description">
            {getFieldDecorator('metaDescription')(
              <Input.TextArea
                maxLength={1000}
                placeholder="Contenu de la balise description"
                disabled={!edit}
              />
            )}
          </Form.Item>

          <Form.Item label="Tags">
            {getFieldDecorator('tags', {
              rules: [{ required: false }]
            })(
              <Select
                mode="multiple"
                disabled={!edit}
                style={{ width: '100%' }}
                placeholder="Tags de l'actualités"
              >
                {newsTagList.map(tag => (
                  <Select.Option key={tag.id} value={tag.slug}>
                    {tag.slug}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('isInformation')(
              <Radio.Group buttonStyle="solid" disabled={!edit}>
                <Radio.Button value={false}>Actualité</Radio.Button>
                <Radio.Button value={true}>Information</Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
        </Form>
        <ErrorComponent errors={errors} />
        {edit && (
          <>
            <Button onClick={this.cancelEdit}>Cancel</Button>
            <Button
              type="primary"
              onClick={this.updateNews}
              loading={loading}
              style={{ marginLeft: '1rem' }}
            >
              Save
            </Button>
          </>
        )}
      </>
    )
  }

  private fillForm = () => {
    const { news, form } = this.props
    form.setFieldsValue({
      title: news.title,
      introduction: news.introduction,
      picture: news.picture,
      content: news.content,
      createDate: news.createDate,
      slug: news.slug,
      titleTag: news.titleTag,
      metaDescription: news.metaDescription,
      isInformation: news.isInformation,
      tags: news.tags
    })
  }

  private updateNews = async () => {
    const { stopEdit, news, form } = this.props
    this.setState({ errors: [] })

    form.validateFieldsAndScroll(async (errors, fieldsValue: NewsUpdateDTO) => {
      if (errors) {
        return
      }

      try {
        await this.props.updateNews(news.id, fieldsValue)
      } catch ({ errors }) {
        if (errors) {
          // @ts-ignore
          this.setState({ errors })
        }
        return
      }

      message.success('News was successfully updated')
      stopEdit()
    })
  }

  private cancelEdit = () => {
    this.props.stopEdit()
    this.fillForm()
  }
}

const mapStateToProps = (state: IRootState, ownProps: IComponentFormUpdateProps) => ({
  loading: state.newsState.loading,
  news: getNews(state, ownProps.match.params.idNews ? ownProps.match.params.idNews : ''),
  newsTagList: state.newsTagState.newsTagList
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchNews: (id: string) => dispatch(fetchNews(id)),
  fetchNewsTagList: () => dispatch(fetchNewsTagList()),
  updateNews: (id: string, news: NewsUpdateDTO) => dispatch(updateNews(id, news))
})

const NewsUpdateForm = Form.create({ name: 'news_update_form' })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UpdateForm)
)

export default NewsUpdateForm
