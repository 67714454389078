import { PaginationRequest, ProgramFilterDTO, ProgramPrivateDTO } from '@vaneauneuf/dtos'
import Modal from 'antd/lib/modal'
import { PaginationConfig } from 'antd/lib/pagination'
import Table, { ColumnProps, SorterResult } from 'antd/lib/table'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import { deleteProgram, fetchPrograms } from '../../../redux/program'
import { PAGE_SIZE } from '../ProgramsPage'

interface IProgramsTableProps {
  filters: ProgramFilterDTO
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProgramsTableProps

class ProgramsTable extends React.Component<IProps> {
  private columns: Array<ColumnProps<ProgramPrivateDTO>> = [
    {
      title: 'Nom',
      dataIndex: 'name',
      render: (name, record) => <Link to={'/programs/' + record.id}>{name}</Link>,
      sorter: true
    },
    {
      title: 'Promoteur',
      dataIndex: 'promoter',
      render: promoter => (promoter ? promoter.name : ''),
      sorter: (a, b) => {
        if (a.promoter && b.promoter) {
          return a.promoter.name.localeCompare(b.promoter.name)
        }
        return 0
      }
    },
    {
      title: 'Rue',
      dataIndex: 'street'
    },
    {
      title: 'Ville',
      dataIndex: 'city',
      sorter: true
    },
    {
      title: 'Départment',
      dataIndex: 'department'
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
      render: plan => plan && <a href={plan}>Voir le plan</a>
    },
    {
      title: 'Photos',
      dataIndex: 'pictures',
      render: photos =>
        photos.map((photo: string, index: number) => (
          <div key={index}>
            <a href={photo}> #{index + 1}</a>
          </div>
        ))
    },
    {
      title: 'Sélection (habiter)',
      dataIndex: 'liveSelection',
      render: liveSelection => (liveSelection ? 'Oui' : 'Non')
    },
    {
      title: 'Action',
      render: (text, record) => <a onClick={() => this.handleDelete(record.id)}>Supprimer</a>
    }
  ]

  public async componentDidMount() {
    await this.props.fetchPrograms({ take: PAGE_SIZE, ...this.props.filters })
  }

  public render() {
    return (
      <>
        <Table
          columns={this.columns}
          dataSource={this.props.programs}
          rowKey="id"
          pagination={{ total: this.props.total, defaultPageSize: PAGE_SIZE }}
          onChange={this.handleTableChange}
          loading={this.props.loading}
        />
      </>
    )
  }

  private handleTableChange = (
    pagination: PaginationConfig,
    filters: any,
    sorter: SorterResult<ProgramPrivateDTO>
  ) => {
    this.props.fetchPrograms({
      skip: pagination.current ? (pagination.current - 1) * PAGE_SIZE : 0,
      take: PAGE_SIZE,
      order: { ['program.' + sorter.field]: sorter.order === 'ascend' ? 'ASC' : 'DESC' },
      ...this.props.filters
    })
  }

  private handleDelete = (id: string) => {
    Modal.confirm({
      title: 'Do you really want to delete this program?',
      onOk: () => this.props.deleteProgram(id)
    })
  }
}

const mapStateToProps = (state: IRootState) => ({
  programs: state.programState.programs,
  total: state.programState.total,
  loading: state.programState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchPrograms: (paginationRequest: PaginationRequest) =>
    dispatch(fetchPrograms(paginationRequest)),
  deleteProgram: (id: string) => dispatch(deleteProgram(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgramsTable)
