import { PromoterPrivateDTO } from '@vaneauneuf/dtos'
import Modal from 'antd/lib/modal'
import Table, { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import { deletePromoter, fetchPromoters } from '../../../redux/promoter'

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

class PromotersTable extends React.Component<IProps> {
  private columns: Array<ColumnProps<PromoterPrivateDTO>> = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name, record) => <Link to={'/promoters/' + record.id}>{name}</Link>
    },
    {
      title: 'Description',
      dataIndex: 'description'
    },
    {
      title: 'Site web',
      dataIndex: 'website'
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Téléphone',
      dataIndex: 'phone'
    },
    {
      title: 'Activé',
      dataIndex: 'enabled',
      render: enabled => (enabled ? 'Yes' : 'Non')
    },
    {
      title: 'Action',
      render: (text, record) => <a onClick={() => this.handleDelete(record.id)}>Supprimer</a>
    }
  ]

  public async componentDidMount() {
    await this.props.fetchPromoters()
  }

  public render() {
    return <Table columns={this.columns} dataSource={this.props.promoters} rowKey="id" />
  }

  private handleDelete = (id: string) => {
    Modal.confirm({
      title: 'Do you really want to delete this promoter?',
      onOk: () => this.props.deletePromoter(id)
    })
  }
}

const mapStateToProps = (state: IRootState) => ({
  promoters: state.promoterState.promoters
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchPromoters: () => dispatch(fetchPromoters()),
  deletePromoter: (id: string) => dispatch(deletePromoter(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotersTable)
