"use strict";

var _classCallCheck = require("/srv/packages/backoffice/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

var LogLevels;

(function (LogLevels) {
  LogLevels["DEBUG"] = "DEBUG";
  LogLevels["INFO"] = "INFO";
  LogLevels["WARN"] = "WARN";
  LogLevels["ERROR"] = "ERROR";
})(LogLevels = exports.LogLevels || (exports.LogLevels = {}));

var LogActions;

(function (LogActions) {
  LogActions["GET"] = "GET";
  LogActions["POST"] = "POST";
  LogActions["PATCH"] = "PATCH";
  LogActions["DELETE"] = "DELETE";
  LogActions["BATCH"] = "BATCH";
})(LogActions = exports.LogActions || (exports.LogActions = {}));

var LogResources;

(function (LogResources) {
  LogResources["ACCOUNT"] = "ACCOUNT";
  LogResources["AUTH"] = "AUTH";
  LogResources["BULL"] = "BULL";
  LogResources["FAVORITE"] = "FAVORITE";
  LogResources["FLUX"] = "FLUX";
  LogResources["FORM"] = "FORM";
  LogResources["LOT"] = "LOT";
  LogResources["MEDIA"] = "MEDIA";
  LogResources["PROGRAM"] = "PROGRAM";
  LogResources["NEWS"] = "NEWS";
  LogResources["PINEL_ZONE"] = "PINEL_ZONE";
  LogResources["PROJECT"] = "PROJECT";
  LogResources["PROMOTER"] = "PROMOTER";
  LogResources["USER"] = "USER";
  LogResources["WISH"] = "WISH";
})(LogResources = exports.LogResources || (exports.LogResources = {}));

var LogPublicDTO = function LogPublicDTO() {
  _classCallCheck(this, LogPublicDTO);
};

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], LogPublicDTO.prototype, "id", void 0);

__decorate([class_validator_1.IsEnum(LogActions), __metadata("design:type", String)], LogPublicDTO.prototype, "action", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], LogPublicDTO.prototype, "origin", void 0);

__decorate([class_validator_1.IsDate(), __metadata("design:type", Date)], LogPublicDTO.prototype, "createDate", void 0);

exports.LogPublicDTO = LogPublicDTO;