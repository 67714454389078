import { PaginationRequest, WishPrivateDTO, WishUpdateDTO } from '@vaneauneuf/dtos'
import { Dispatch } from 'redux'
import { IRootState } from '..'
import { apiRequest } from '../utils/api'
import { setLoading, setWishes, updateWishStore } from './actions'

export const fetchWishes = (paginationRequest?: PaginationRequest) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const wishes = await apiRequest<{ data: WishPrivateDTO[]; total: number }>(
      'GET',
      getState().authState.token,
      '/wish',
      undefined,
      paginationRequest
    )
    dispatch(setWishes(wishes))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const updateWish = (id: string, wish: WishUpdateDTO) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const wishUpdated = await apiRequest<WishPrivateDTO>(
      'PATCH',
      getState().authState.token,
      '/wish/' + id,
      wish
    )
    dispatch(updateWishStore(wishUpdated))
  } catch (e) {
    dispatch(setLoading(false))
    throw e.response.data
  }
  dispatch(setLoading(false))
}
