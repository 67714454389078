import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { fetchHomeConfig } from '../../redux/HomeConfig'
import styles from './HomeConfigPage.module.scss'
import HomeConfigUpdateForm from './HomeConfigUpdateForm/HomeConfigUpdateForm'

type IProps = ReturnType<typeof mapDispatchToProps>

const HomeConfigPage: React.FC<IProps> = ({ fetchHomeConfigStore }) => {
  useEffect(() => {
    fetchHomeConfigStore()
  }, [])

  return (
    <div className={styles.container}>
      <h1>Configuration page d'accueil</h1>
      <HomeConfigUpdateForm />
    </div>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchHomeConfigStore: () => dispatch(fetchHomeConfig())
})

export default connect(
  undefined,
  mapDispatchToProps
)(HomeConfigPage)
