import { GlobalConfigDTO } from '@vaneauneuf/dtos'
import { ActionsTypes } from './model'

export const setGlobalConfig = (globalConfig: GlobalConfigDTO) => ({
  type: ActionsTypes.SET_GLOBALCONFIG,
  globalConfig
})

export const setLoading = (loading: boolean) => ({
  type: ActionsTypes.SET_GLOBALCONFIG_LOADING,
  loading
})
