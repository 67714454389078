import { Checkbox } from 'antd'
import Button from 'antd/lib/button'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import message from 'antd/lib/message'
import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import { login } from '../../../redux/auth'
import styles from './LoginForm.module.scss'

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

interface IState {
  emailValue: string
  passwordValue: string
  belgium: boolean
}

class LoginForm extends React.PureComponent<IProps, IState> {
  public state: IState = { emailValue: '', passwordValue: '', belgium: false }

  public render() {
    const { loading } = this.props
    const { emailValue, passwordValue, belgium } = this.state

    return (
      <Form onSubmit={this.handleSubmit} className={`login-form ${styles.form}`}>
        <Form.Item>
          <Input
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Email"
            name="email"
            type="text"
            value={emailValue}
            onChange={this.handleEmail}
          />
        </Form.Item>
        <Form.Item>
          <Input
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Password"
            name="passord"
            type="password"
            value={passwordValue}
            onChange={this.handlePassword}
          />
        </Form.Item>
        <div className={styles.actionCtn}>
          <Form.Item>
            <Checkbox value={belgium} onChange={this.handleBelgium}>
              Belgique
            </Checkbox>
          </Form.Item>
          <Button type="primary" htmlType="submit" className={styles.submit} loading={loading}>
            Login
          </Button>
        </div>
      </Form>
    )
  }

  private handleSubmit: React.FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault()
    try {
      await this.props.login(this.state.emailValue, this.state.passwordValue, this.state.belgium)
    } catch (e) {
      if (e.message === 'Unauthorized') {
        message.error("Vous n'êtes pas autorisé à accéder à cette interface")
      }
    }
  }

  private handleEmail: React.ChangeEventHandler<HTMLInputElement> = event =>
    this.setState({ emailValue: event.currentTarget.value })

  private handlePassword: React.ChangeEventHandler<HTMLInputElement> = event =>
    this.setState({ passwordValue: event.currentTarget.value })

  private handleBelgium = (event: CheckboxChangeEvent) =>
    this.setState({ belgium: event.target.checked })
}

const mapStateToProps = (state: IRootState) => ({
  loading: state.authState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  login: (email: string, password: string, belgium: boolean) =>
    dispatch(login(email, password, belgium))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm)
