import { Country } from '@vaneauneuf/dtos'
import { ActionsTypes } from './model'

export const setToken = (token: string) => ({ type: ActionsTypes.SET_TOKEN, token })

export const resetToken = () => ({ type: ActionsTypes.RESET_TOKEN })

export const setLoading = (loading: boolean) => ({ type: ActionsTypes.SET_AUTH_LOADING, loading })

export const setLocale = (locale: Country) => ({ type: ActionsTypes.SET_LOCALE, locale })
