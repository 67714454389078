import { Country } from '@vaneauneuf/dtos'
import { createReducer } from '../utils'
import { setLoading, setLocale, setToken } from './actions'
import { ActionsTypes, IAuthState } from './model'

const initialState: IAuthState = {
  token: localStorage.getItem('token'),
  locale: localStorage.getItem('country') === 'belgium' ? Country.BELGIQUE : Country.FRANCE,
  loading: false
}

const actionHandlers = {
  [ActionsTypes.SET_TOKEN]: (
    state: IAuthState,
    { token }: ReturnType<typeof setToken>
  ): IAuthState => ({
    ...state,
    token
  }),
  [ActionsTypes.RESET_TOKEN]: (state: IAuthState): IAuthState => ({
    ...state,
    token: null
  }),
  [ActionsTypes.SET_LOCALE]: (
    state: IAuthState,
    { locale }: ReturnType<typeof setLocale>
  ): IAuthState => ({
    ...state,
    locale
  }),
  [ActionsTypes.SET_AUTH_LOADING]: (
    state: IAuthState,
    { loading }: ReturnType<typeof setLoading>
  ): IAuthState => ({
    ...state,
    loading
  })
}

export default createReducer(initialState, actionHandlers)
