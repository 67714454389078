import React from 'react'
import CreateForm from '../../components/CreateForm/CreateForm'
import AdminsCreateForm from './AdminsCreateForm/AdminsCreateForm'
import styles from './AdminsPage.module.scss'
import AdminsTable from './AdminsTable/AdminsTable'

const AdminsPage: React.FunctionComponent = () => (
  <div className={styles.container}>
    <h1>Admins</h1>
    <CreateForm entity="admin" component={AdminsCreateForm} />
    <AdminsTable />
  </div>
)

export default AdminsPage
