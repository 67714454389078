import { LandingPagePrivateDTO } from '@vaneauneuf/dtos'

export enum ActionsTypes {
  SET_LANDINGS = 'SET_LANDINGS',
  SET_LANDING = 'SET_LANDING',
  ADD_LANDING = 'ADD_LANDING',
  REMOVE_LANDING = 'REMOVE_LANDING',
  SET_LANDING_LOADING = 'SET_LANDING_LOADING'
}

export interface ILandingState {
  landings: LandingPagePrivateDTO[]
  loading: boolean
}
