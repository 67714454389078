import { AccountUpdateDTO, PromoterUpdateDTO } from '@vaneauneuf/dtos'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import message from 'antd/lib/message'
import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent'
import { IComponentFormUpdateProps } from '../../../components/UpdateForm/UpdateForm'
import { IRootState } from '../../../redux'
import { fetchAdmin, getAdmin, updateAdmin } from '../../../redux/admin'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IComponentFormUpdateProps

interface IState {
  errors: string[]
}

class UpdateForm extends React.Component<IProps, IState> {
  public state: IState = {
    errors: []
  }

  public async componentDidMount() {
    if (!this.props.match.params.idAdmin) {
      return
    }

    await this.props.fetchAdmin(this.props.match.params.idAdmin)
    this.fillForm()
  }

  public render() {
    const { edit, loading, form } = this.props
    const { errors } = this.state
    const { getFieldDecorator } = form
    return (
      <>
        <Form>
          <Form.Item label="E-mail">
            {getFieldDecorator('email', {
              rules: [
                { type: 'email', message: 'Email invalide' },
                { required: true, message: 'Veuillez saisir un email' }
              ]
            })(<Input disabled={!edit} />)}
          </Form.Item>
        </Form>
        <ErrorComponent errors={errors} />
        {edit && (
          <>
            <Button onClick={this.cancelEdit}>Cancel</Button>
            <Button
              type="primary"
              onClick={this.updateAdmin}
              loading={loading}
              style={{ marginLeft: '1rem' }}
            >
              Save
            </Button>
          </>
        )}
      </>
    )
  }

  private fillForm = () => {
    const { admin, form } = this.props
    form.setFieldsValue({
      email: admin.email
    })
  }

  private updateAdmin = async () => {
    const { stopEdit, admin, form } = this.props
    this.setState({ errors: [] })

    form.validateFieldsAndScroll(async (errors, values: AccountUpdateDTO) => {
      if (errors) {
        return
      }

      try {
        await this.props.updateAdmin(admin.id, values)
      } catch ({ errors }) {
        if (errors) {
          this.setState({ errors })
        }
        return
      }

      message.success("L'admin a été mis à jour")
      stopEdit()
    })
  }

  private cancelEdit = () => {
    this.props.stopEdit()
    this.fillForm()
  }
}

const mapStateToProps = (state: IRootState, ownProps: IComponentFormUpdateProps) => ({
  loading: state.adminState.loading,
  admin: getAdmin(state, ownProps.match.params.idAdmin ? ownProps.match.params.idAdmin : '')
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchAdmin: (id: string) => dispatch(fetchAdmin(id)),
  updateAdmin: (id: string, admin: PromoterUpdateDTO) => dispatch(updateAdmin(id, admin))
})

const AdminUpdateForm = Form.create({ name: 'admin_update_form' })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UpdateForm)
)

export default AdminUpdateForm
