import { WishPrivateDTO } from '@vaneauneuf/dtos'
import { ActionsTypes } from './model'

export const setWishes = (wishes: { data: WishPrivateDTO[]; total: number }) => ({
  type: ActionsTypes.SET_WISHES,
  wishes
})

export const updateWishStore = (wish: WishPrivateDTO) => ({
  type: ActionsTypes.UPDATE_WISH,
  wish
})

export const setLoading = (loading: boolean) => ({
  type: ActionsTypes.SET_WISH_LOADING,
  loading
})
