import Icon from 'antd/lib/icon'
import Menu, { ClickParam } from 'antd/lib/menu'
import { push } from 'connected-react-router'
import * as React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

class NavBar extends React.PureComponent<IProps> {
  public render() {
    return (
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[this.props.pathname]}
        onClick={this.handleClickMenuItem}
      >
        <Menu.Item key="/">
          <Icon type="home" />
          <span>Accueil</span>
        </Menu.Item>
        <Menu.Item key="/admins">
          <Icon type="idcard" />
          <span>Administrateurs</span>
        </Menu.Item>
        <Menu.Item key="/promoters">
          <Icon type="team" />
          <span>Promoteurs</span>
        </Menu.Item>
        <Menu.Item key="/programs">
          <Icon type="bank" />
          <span>Programmes</span>
        </Menu.Item>
        <Menu.Item key="/contacts">
          <Icon type="user" />
          <span>Contacts</span>
        </Menu.Item>
        <Menu.Item key="/members">
          <Icon type="user" />
          <span>Membres</span>
        </Menu.Item>
        <Menu.Item key="/news">
          <Icon type="notification" />
          <span>Actualités / Infos</span>
        </Menu.Item>
        <Menu.Item key="/news-tag">
          <Icon type="tag" />
          <span>Actu. / Infos - Tags</span>
        </Menu.Item>
        <Menu.Item key="/wishes">
          <Icon type="notification" />
          <span>Demandes de disponibilité</span>
        </Menu.Item>
        <Menu.Item key="/landings">
          <Icon type="home" />
          <span>Landing pages</span>
        </Menu.Item>
        <Menu.Item key="/home">
          <Icon type="home" />
          <span>Page d'accueil</span>
        </Menu.Item>
        <Menu.Item key="/config">
          <Icon type="setting" />
          <span>Configuration</span>
        </Menu.Item>
      </Menu>
    )
  }

  private handleClickMenuItem = (param: ClickParam) => {
    this.props.push(param.key)
  }
}

const mapStateToProps = (state: IRootState) => ({
  pathname: state.router.location.pathname
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  push: (location: string) => dispatch(push(location))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavBar)
