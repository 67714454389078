import React from 'react'
import CreateForm from '../../components/CreateForm/CreateForm'
import NewsCreateForm from './NewsCreateForm/NewsCreateForm'
import styles from './NewsListPage.module.scss'
import NewsListTable from './NewsListTable/NewsListTable'

const NewsListPage: React.FunctionComponent = () => (
  <div className={styles.container}>
    <h1>Actualités / Infos</h1>
    <CreateForm entity="news" component={NewsCreateForm} />
    <NewsListTable />
  </div>
)

export default NewsListPage
