import { PromoterUpdateDTO } from '@vaneauneuf/dtos'
import Button from 'antd/lib/button'
import Checkbox from 'antd/lib/checkbox'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import message from 'antd/lib/message'
import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent'
import { IComponentFormUpdateProps } from '../../../components/UpdateForm/UpdateForm'
import { IRootState } from '../../../redux'
import { fetchPromoter, getPromoter, updatePromoter } from '../../../redux/promoter'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IComponentFormUpdateProps

interface IState {
  errors: string[]
}

class UpdateForm extends React.Component<IProps, IState> {
  public state: IState = {
    errors: []
  }

  public async componentDidMount() {
    if (!this.props.match.params.idPromoter) {
      return
    }

    await this.props.fetchPromoter(this.props.match.params.idPromoter)
    this.fillForm()
  }

  public render() {
    const { edit, loading, form } = this.props
    const { errors } = this.state
    const { getFieldDecorator } = form
    return (
      <>
        <Form>
          <Form.Item label="Nom">
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Veuillez renseigner le nom du promoteur' }]
            })(<Input disabled={!edit} />)}
          </Form.Item>
          <Form.Item label="E-mail">
            {getFieldDecorator('email')(<Input type="email" disabled={!edit} />)}
          </Form.Item>
          <Form.Item label="Téléphone">
            {getFieldDecorator('phone')(<Input type="tel" disabled={!edit} />)}
          </Form.Item>
          <Form.Item label="Description">
            {getFieldDecorator('description')(<Input.TextArea disabled={!edit} />)}
          </Form.Item>
          <Form.Item label="Site web">
            {getFieldDecorator('website', {
              rules: [{ type: 'url', message: "L'URL n'est pas valide" }]
            })(<Input disabled={!edit} />)}
          </Form.Item>
          <Form.Item label="Url du logo">
            {getFieldDecorator('imgLogo', {
              rules: [
                {
                  pattern: new RegExp(
                    // tslint:disable-next-line: max-line-length
                    '^(https://www.||https://)[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$'
                  ),
                  message: "L'URL n'est pas valide (https obligatoire)"
                }
              ]
            })(<Input disabled={!edit} />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('enabled', {
              valuePropName: 'checked'
            })(<Checkbox disabled={!edit}>Enabled</Checkbox>)}
          </Form.Item>
        </Form>
        <ErrorComponent errors={errors} />
        {edit && (
          <>
            <Button onClick={this.cancelEdit}>Cancel</Button>
            <Button
              type="primary"
              onClick={this.updatePromoter}
              loading={loading}
              style={{ marginLeft: '1rem' }}
            >
              Save
            </Button>
          </>
        )}
      </>
    )
  }

  private fillForm = () => {
    const { promoter, form } = this.props
    form.setFieldsValue({
      email: promoter.email,
      name: promoter.name,
      description: promoter.description,
      phone: promoter.phone,
      website: promoter.website,
      imgLogo: promoter.imgLogo,
      enabled: promoter.enabled
    })
  }

  private updatePromoter = async () => {
    const { stopEdit, promoter, form } = this.props
    this.setState({ errors: [] })

    form.validateFieldsAndScroll(async (errors, values: PromoterUpdateDTO) => {
      if (errors) {
        return
      }

      try {
        await this.props.updatePromoter(promoter.id, values)
      } catch ({ errors }) {
        if (errors) {
          this.setState({ errors })
        }
        return
      }

      message.success('Le promoteur a été mis à jour')
      stopEdit()
    })
  }

  private cancelEdit = () => {
    this.props.stopEdit()
    this.fillForm()
  }
}

const mapStateToProps = (state: IRootState, ownProps: IComponentFormUpdateProps) => ({
  loading: state.promoterState.loading,
  promoter: getPromoter(
    state,
    ownProps.match.params.idPromoter ? ownProps.match.params.idPromoter : ''
  )
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchPromoter: (id: string) => dispatch(fetchPromoter(id)),
  updatePromoter: (id: string, promoter: PromoterUpdateDTO) =>
    dispatch(updatePromoter(id, promoter))
})

const PromoterUpdateForm = Form.create({ name: 'promoter_update_form' })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UpdateForm)
)

export default PromoterUpdateForm
