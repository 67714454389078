import { WishPrivateDTO } from '@vaneauneuf/dtos'

export enum ActionsTypes {
  SET_WISHES = 'SET_WISHES',
  UPDATE_WISH = 'UPDATE_WISH',
  SET_WISH_LOADING = 'SET_WISH_LOADING'
}

export interface IWishState {
  wishes: WishPrivateDTO[]
  total: number
  loading: boolean
}
