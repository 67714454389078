import { GlobalConfigDTO } from '@vaneauneuf/dtos'

export enum ActionsTypes {
  SET_GLOBALCONFIG = 'SET_GLOBALCONFIG',
  SET_GLOBALCONFIG_LOADING = 'SET_GLOBALCONFIG_LOADING'
}

export interface IGlobalConfigState {
  globalConfig: GlobalConfigDTO
  loading: boolean
}
