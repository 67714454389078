import { NewsCreationDTO } from '@vaneauneuf/dtos'
import { Radio } from 'antd'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import TextArea from 'antd/lib/input/TextArea'
import message from 'antd/lib/message'
import Modal from 'antd/lib/modal'
import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import slugify from 'slugify'
import { IComponentFormCreateProps } from '../../../components/CreateForm/CreateForm'
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent'
import { IRootState } from '../../../redux'
import { createNews } from '../../../redux/news'
import { fetchPromoters } from '../../../redux/promoter'
import ISuggestion from '../../../utils/mapBox'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IComponentFormCreateProps

interface IState {
  confirmDirty: boolean
  errors: string[]
  dataSource: ISuggestion[]
}

class CreateForm extends React.Component<IProps, IState> {
  public state: IState = {
    confirmDirty: false,
    errors: [],
    dataSource: []
  }

  public render() {
    const { visible, hideModal, loading, form } = this.props
    const { errors } = this.state
    const { getFieldDecorator } = form
    return (
      <Modal
        visible={visible}
        title="Créer une nouvelle actualité"
        okText="Sauvegarder"
        onCancel={hideModal}
        onOk={this.createNews}
        confirmLoading={loading}
      >
        <Form>
          <Form.Item label="Titre">
            {getFieldDecorator('title', {
              rules: [{ required: true, message: 'Veuillez entrer le titre' }]
            })(
              <Input
                placeholder="Titre de l'actualité"
                onChange={e =>
                  form.setFieldsValue({
                    title: e.currentTarget.value,
                    slug: slugify(e.currentTarget.value)
                      .toLocaleLowerCase()
                      .replace(/[*+~.()'"!:@]/g, '-')
                  })
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Introduction">
            {getFieldDecorator('introduction', {
              rules: [{ required: true, message: "Veuillez entrer l'introduction de l'actualité" }]
            })(<Input placeholder="Introduction qui apparaitra sous le titre" />)}
          </Form.Item>

          <Form.Item label="Url vers l'image">
            {getFieldDecorator('picture', {
              rules: [
                {
                  required: true,
                  message: 'Veuillez choisir une url pour une image'
                }
              ]
            })(<Input placeholder="https://url-vers-image.com" />)}
          </Form.Item>

          <Form.Item label="Contenu">
            {getFieldDecorator('content', {
              rules: [{ required: true, message: "Veuillez entrer un contenu pour l'article" }]
            })(<TextArea />)}
            <span>
              Pour vous aider à rédiger, il est conseillé d'utiliser{' '}
              <a href="http://rexxars.github.io/markdown-editor/" target="_blank">
                l'outil en ligne
              </a>
            </span>
          </Form.Item>

          <Form.Item label="Slug">
            {getFieldDecorator('slug', {
              rules: [{ required: true, message: 'Veuillez entrer le slug' }]
            })(<Input placeholder="Slug de l'actualité" />)}
          </Form.Item>

          <Form.Item label="Balise meta titre">
            {getFieldDecorator('titleTag')(
              <Input maxLength={255} placeholder="Contenu de la balise titre" />
            )}
          </Form.Item>

          <Form.Item label="Balise meta description">
            {getFieldDecorator('metaDescription')(
              <Input.TextArea maxLength={1000} placeholder="Contenu de la balise description" />
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('isInformation', { initialValue: false })(
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={false}>Actualité</Radio.Button>
                <Radio.Button value={true}>Information</Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
        </Form>
        <ErrorComponent errors={errors} />
      </Modal>
    )
  }

  private createNews = async () => {
    const { hideModal, form, locale } = this.props
    this.setState({ errors: [] })

    form.validateFieldsAndScroll(async (errors, fieldsValue: NewsCreationDTO) => {
      fieldsValue.country = locale

      if (errors) {
        return
      }

      try {
        await this.props.createNews(fieldsValue)
      } catch ({ errors }) {
        if (errors) {
          // @ts-ignore
          this.setState({ errors })
        }
        return
      }

      message.success("L'actualité a bien été créée")
      form.resetFields()
      hideModal()
    })
  }
}

const mapStateToProps = (state: IRootState) => ({
  locale: state.authState.locale,
  loading: state.newsState.loading,
  promoters: state.promoterState.promoters
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  createNews: (news: NewsCreationDTO) => dispatch(createNews(news)),
  fetchPromoters: () => dispatch(fetchPromoters())
})

const NewsCreateForm = Form.create({ name: 'newsList_create_form' })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateForm)
)

export default NewsCreateForm
