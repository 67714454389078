import { ActionsTypes } from '../news/model'

import { addNews, removeNews, setLoading, setNews, setNewsList } from '../news/actions'

import { INewsState } from '.'
import { createReducer } from '../utils'

const initialState: INewsState = {
  newsList: [],
  total: 0,
  loading: false
}

const actionHandlers = {
  [ActionsTypes.SET_NEWS_LIST]: (
    state: INewsState,
    { newsList }: ReturnType<typeof setNewsList>
  ): INewsState => ({
    ...state,
    newsList: newsList.data,
    total: newsList.total
  }),
  [ActionsTypes.SET_NEWS]: (
    state: INewsState,
    { news }: ReturnType<typeof setNews>
  ): INewsState => ({
    ...state,
    newsList: [...state.newsList.filter(currentNews => currentNews.id !== news.id), news]
  }),
  [ActionsTypes.ADD_NEWS]: (
    state: INewsState,
    { news }: ReturnType<typeof addNews>
  ): INewsState => ({
    ...state,
    newsList: [...state.newsList, news]
  }),
  [ActionsTypes.REMOVE_NEWS]: (
    state: INewsState,
    { idNews }: ReturnType<typeof removeNews>
  ): INewsState => ({
    ...state,
    newsList: [...state.newsList].filter(news => news.id !== idNews)
  }),
  [ActionsTypes.SET_NEWS_LOADING]: (
    state: INewsState,
    { loading }: ReturnType<typeof setLoading>
  ): INewsState => ({
    ...state,
    loading
  })
}

export default createReducer(initialState, actionHandlers)
