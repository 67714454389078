import { PaginationRequest, ProgramFilterDTO, ProgramTags } from '@vaneauneuf/dtos'
import { Button, Card, Col, Row } from 'antd'
import Search from 'antd/lib/input/Search'
import React, { Dispatch, FC, memo, SetStateAction, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import { Tag } from '../../../components/UI/Tag'
import { IRootState } from '../../../redux'
import { fetchPrograms } from '../../../redux/program'
import useDebounce from '../../../utils/hooks/useDebounce'
import { PAGE_SIZE } from '../ProgramsPage'
import styles from './ProgramsFilter.module.scss'

interface IProgramsFilterProps {
  filters: ProgramFilterDTO
  onUpdateFilters: Dispatch<SetStateAction<ProgramFilterDTO>>
}

type IProps = ReturnType<typeof mapDispatchToProps> & IProgramsFilterProps

type TagSelectionType = { [key in ProgramTags]?: boolean }

const ProgramsFilter: FC<IProps> = memo(
  ({ filters, fetchPrograms: fetchProgramStore, onUpdateFilters }) => {
    const [tags, setTags] = useState<TagSelectionType>(
      Object.keys(ProgramTags)
        .filter(key => !!filters[key as keyof typeof ProgramTags])
        .reduce((acc, key) => ({ ...acc, [key]: true }), {})
    )

    useDebounce({
      value: tags,
      delay: 300,
      callback: () => {
        fetchProgramsWithFilters()
      }
    })

    const resetFilters = () => {
      onUpdateFilters({ city: '', name: '', promoter: '' })
      fetchProgramsWithFilters()
    }

    const onTagUpdate = (key: ProgramTags, isChecked: boolean) => {
      const { [key]: _IGNORED, ...rest } = tags
      const newTags = isChecked ? { ...tags, [key]: true } : rest
      setTags(newTags)
    }

    useEffect(() => {
      const tagKeys = Object.keys(ProgramTags)
      const filtersWithoutTags = Object.entries(filters)
        .filter(([key]) => !tagKeys.includes(key))
        .reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {})
      onUpdateFilters({ ...filtersWithoutTags, ...(tags as ProgramFilterDTO) })
    }, [tags])

    const fetchProgramsWithFilters = () => fetchProgramStore({ take: PAGE_SIZE, ...filters })

    return (
      <div className={styles.container}>
        <h2>Filtrer</h2>
        <Row>
          <Col span={6}>
            <Search
              placeholder="Filter par nom de programme"
              className={styles.filterRow}
              addonBefore="Par nom"
              value={filters.name}
              onChange={event => onUpdateFilters({ ...filters, name: event.currentTarget.value })}
              onPressEnter={fetchProgramsWithFilters}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Search
              placeholder="Filtrer par ville du programme"
              className={styles.filterRow}
              addonBefore="Par ville"
              value={filters.city}
              onChange={event => onUpdateFilters({ ...filters, city: event.currentTarget.value })}
              onPressEnter={fetchProgramsWithFilters}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Search
              placeholder="Filter par promoteur"
              className={styles.filterRow}
              addonBefore="Par promoteur"
              value={filters.promoter}
              onChange={event =>
                onUpdateFilters({ ...filters, promoter: event.currentTarget.value })
              }
              onPressEnter={fetchProgramsWithFilters}
            />
          </Col>
        </Row>
        <Row style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
          <div className="ant-col-6">
            <span className="ant-input-wrapper ant-input-group">
              <span className="ant-input-group-addon">Par tag</span>
              <Card style={{ width: 800, marginBottom: 0 }} size="small">
                {Object.entries(ProgramTags).map(([key, title]) => (
                  <Tag
                    editable
                    title={title}
                    checked={tags[key as ProgramTags]}
                    onChange={(isChecked: boolean) => onTagUpdate(key as ProgramTags, isChecked)}
                  />
                ))}
              </Card>
            </span>
          </div>
        </Row>
        <Row>
          <Col span={7}>
            <Button type="primary" icon="search" onClick={fetchProgramsWithFilters}>
              Rechercher
            </Button>
            <Col span={7}>
              <Button icon="redo" onClick={resetFilters}>
                Effacer
              </Button>
            </Col>
          </Col>
        </Row>
      </div>
    )
  }
)

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchPrograms: (paginationRequest: PaginationRequest) =>
    dispatch(fetchPrograms(paginationRequest))
})

export default connect(
  null,
  mapDispatchToProps
)(ProgramsFilter)
