import { AccountCreationDTO, AccountDTO, AccountUpdateDTO } from '@vaneauneuf/dtos'
import { Dispatch } from 'redux'
import { IRootState } from '..'
import { apiRequest } from '../utils/api'
import { addAdmin, removeAdmin, setAdmin, setAdmins, setLoading } from './actions'

export const fetchAdmins = () => async (dispatch: Dispatch, getState: () => IRootState) => {
  dispatch(setLoading(true))
  try {
    const admins = await apiRequest<{ data: AccountDTO[]; total: number }>(
      'GET',
      getState().authState.token,
      '/account/admin'
    )
    dispatch(setAdmins(admins.data))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const fetchAdmin = (id: string) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const admin = await apiRequest<AccountDTO>('GET', getState().authState.token, '/account/' + id)
    dispatch(setAdmin(admin))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const createAdmin = (admin: AccountCreationDTO) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const newAdmin = await apiRequest<AccountDTO>(
      'POST',
      getState().authState.token,
      '/account',
      admin
    )
    dispatch(addAdmin(newAdmin))
  } catch (e) {
    dispatch(setLoading(false))
    throw e.response.data
  }
  dispatch(setLoading(false))
}

export const updateAdmin = (id: string, admin: AccountUpdateDTO) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const adminUpdated = await apiRequest<AccountDTO>(
      'PATCH',
      getState().authState.token,
      '/account/' + id,
      admin
    )
    dispatch(setAdmin(adminUpdated))
  } catch (e) {
    dispatch(setLoading(false))
    throw e.response.data
  }
  dispatch(setLoading(false))
}

export const deleteAdmin = (id: string) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    await apiRequest('DELETE', getState().authState.token, '/account/' + id)
    dispatch(removeAdmin(id))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}
