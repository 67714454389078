import React from 'react'
import CreateForm from '../../components/CreateForm/CreateForm'
import PromotersCreateForm from './PromotersCreateForm/PromotersCreateForm'
import styles from './PromotersPage.module.scss'
import PromotersTable from './PromotersTable/PromotersTable'

const PromotersPage: React.FunctionComponent = () => (
  <div className={styles.container}>
    <h1>Promoteurs</h1>
    <CreateForm entity="promoter" component={PromotersCreateForm} />
    <PromotersTable />
  </div>
)

export default PromotersPage
