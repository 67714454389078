import React from 'react'
import { match as Match } from 'react-router-dom'
import LotMedias from '../../components/LotMedias/LotMedias'
import UpdateForm from '../../components/UpdateForm/UpdateForm'
import styles from './LotPage.module.scss'
import LotUpdateForm from './LotUpdateForm/LotUpdateForm'

interface IProps {
  match: Match<{ idProgram: string; idLot: string }>
}

const LotPage: React.FunctionComponent<IProps> = props => (
  <div className={styles.container}>
    <h1>Détails du lot</h1>
    <UpdateForm component={LotUpdateForm} match={props.match} />
    <LotMedias match={props.match} />
  </div>
)

export default LotPage
