import { WishPrivateDTO } from '@vaneauneuf/dtos'
import Table, { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import { fetchMemberWishes } from '../../../redux/user/thunks'

interface IMemberTableProps {
  id: string
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IMemberTableProps

class MemberWishTable extends React.Component<IProps> {
  private columns: Array<ColumnProps<WishPrivateDTO>> = [
    {
      title: 'Nom du programme',
      dataIndex: 'name'
    },
    {
      title: 'Ville',
      dataIndex: 'city'
    },
    {
      title: 'Surface (m²)',
      dataIndex: 'surface'
    },
    {
      title: 'Etage',
      dataIndex: 'floor'
    },
    {
      title: 'Nb pièces',
      dataIndex: 'roomsCount'
    },
    {
      title: 'Prix',
      dataIndex: 'price'
    },
    {
      title: 'Référence',
      dataIndex: 'reference'
    },
    {
      title: 'Réponse',
      dataIndex: 'answer'
    },
    {
      title: 'Voir',
      render: (name, record) => (
        <>
          <Link to={'/programs/' + record.idProgram}>Programme</Link> /{' '}
          <Link to={'/programs/' + record.idProgram + '/lots/' + record.idLot}>Lot</Link>
        </>
      )
    }
  ]

  public async componentDidMount() {
    await this.props.fetchWishes(this.props.id)
  }

  public render() {
    return (
      <Table
        columns={this.columns}
        dataSource={this.props.wishes}
        rowKey="id"
        loading={this.props.loading}
      />
    )
  }
}

const mapStateToProps = (state: IRootState) => ({
  wishes: state.userState.wishes,
  loading: state.userState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchWishes: (id: string) => dispatch(fetchMemberWishes(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberWishTable)
