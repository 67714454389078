import React from 'react'
import logo from '../../assets/images/logo-vaneau.svg'
import LoginForm from './LoginForm/LoginForm'
import styles from './LoginPage.module.scss'

const LoginPage: React.FunctionComponent = () => (
  <div className={styles.container}>
    <img alt="Logo" title="Logo" src={logo} />
    <LoginForm />
  </div>
)

export default LoginPage
