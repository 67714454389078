"use strict";

function __export(m) {
  for (var p in m) {
    if (!exports.hasOwnProperty(p)) exports[p] = m[p];
  }
}

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

var storage_1 = require("class-transformer/storage");

var class_validator_jsonschema_1 = require("class-validator-jsonschema");

__export(require("class-validator"));

var metadatas = class_validator_1.getFromContainer(class_validator_1.MetadataStorage).validationMetadatas;
exports.definitions = class_validator_jsonschema_1.validationMetadatasToSchemas(metadatas, {
  skipMissingProperties: false,
  classTransformerMetadataStorage: storage_1.defaultMetadataStorage
});