"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Country;

(function (Country) {
  Country["FRANCE"] = "France";
  Country["BELGIQUE"] = "Belgique";
})(Country = exports.Country || (exports.Country = {}));