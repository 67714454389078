import React from 'react'
import Quill from 'react-quill'

import styles from './Wysiwyg.module.scss'

interface IProps {
  value?: string
  onChange?: (value: string) => void
  disabled?: boolean
}

const Wysiwyg = ({ value = '', onChange, disabled = false }: IProps) => {
  return (
    <Quill
      value={value}
      onChange={onChange}
      className={styles.editor}
      theme="snow"
      readOnly={disabled}
    />
  )
}

export default Wysiwyg
