import { ActionsTypes } from './model'

import { setGlobalConfig, setLoading } from './actions'

import { IGlobalConfigState } from '.'
import { createReducer } from '../utils'

const initialState: IGlobalConfigState = {
  globalConfig: {
    id: '',
    limitSimulationMember: 0,
    limitSimulationVisitor: 0,
    searchSeoText: '',
    homeSeoFooterText: '',
    homeSeoFooterLinks: '',
    searchSeoFooterText: '',
    searchSeoFooterLinks: ''
  },
  loading: false
}

const actionHandlers = {
  [ActionsTypes.SET_GLOBALCONFIG]: (
    state: IGlobalConfigState,
    { globalConfig }: ReturnType<typeof setGlobalConfig>
  ): IGlobalConfigState => ({
    ...state,
    globalConfig
  }),
  [ActionsTypes.SET_GLOBALCONFIG_LOADING]: (
    state: IGlobalConfigState,
    { loading }: ReturnType<typeof setLoading>
  ): IGlobalConfigState => ({
    ...state,
    loading
  })
}

export default createReducer(initialState, actionHandlers)
