import { NewsPrivateDTO, PaginationRequest } from '@vaneauneuf/dtos'
import Modal from 'antd/lib/modal'
import { PaginationConfig } from 'antd/lib/pagination'
import Table, { ColumnProps, SorterResult } from 'antd/lib/table'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import { deleteNews, fetchNewsList } from '../../../redux/news'

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const PAGE_SIZE = 50

class NewsListTable extends React.Component<IProps> {
  private columns: Array<ColumnProps<NewsPrivateDTO>> = [
    {
      title: 'Titre',
      dataIndex: 'title'
    },
    {
      title: 'Introduction',
      dataIndex: 'introduction'
    },
    {
      title: "Url vers l'image",
      dataIndex: 'picture',
      render: (url: any) => (url ? <img width={200} src={url} alt="" /> : '')
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      render: (name, record) => record.tags.join(',')
    },
    {
      title: 'Date de création',
      dataIndex: 'createDate',
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.createDate).getTime() - new Date(b.createDate).getTime(),
      render: (name, record) => new Date(record.createDate).toLocaleString()
    },
    {
      title: 'Détails',
      dataIndex: 'Voir plus',
      render: (name, record) => <Link to={'/news/' + record.id}>Voir plus</Link>
    },
    {
      title: 'Action',
      render: (text, record) => <a onClick={() => this.handleDelete(record.id)}>Supprimer</a>
    }
  ]

  public async componentDidMount() {
    await this.props.fetchNewsList({ take: PAGE_SIZE })
  }

  public render() {
    return (
      <Table
        columns={this.columns}
        dataSource={this.props.newsList}
        rowKey="id"
        pagination={{ total: this.props.total, defaultPageSize: PAGE_SIZE }}
        onChange={this.handleTableChange}
        loading={this.props.loading}
      />
    )
  }

  private handleTableChange = (
    pagination: PaginationConfig,
    filters: any,
    sorter: SorterResult<NewsPrivateDTO>
  ) => {
    this.props.fetchNewsList({
      skip: pagination.current ? (pagination.current - 1) * PAGE_SIZE : 0,
      take: PAGE_SIZE,
      order: { ['news.' + sorter.field]: sorter.order === 'ascend' ? 'ASC' : 'DESC' }
    })
  }

  private handleDelete = (id: string) => {
    Modal.confirm({
      title: 'Voulez vous vraiment supprimer cette actualité ?',
      onOk: () => this.props.deleteNews(id)
    })
  }
}

const mapStateToProps = (state: IRootState) => ({
  newsList: state.newsState.newsList,
  total: state.newsState.total,
  loading: state.newsState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchNewsList: (paginationRequest?: PaginationRequest) =>
    dispatch(fetchNewsList(paginationRequest)),
  deleteNews: (id: string) => dispatch(deleteNews(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsListTable)
