import { ProgramUpdateDTO } from '@vaneauneuf/dtos'
import { Col, Icon, Row } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import { match as Match } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../redux'
import { deleteMedia, fetchProgram, getProgram, updateProgram } from '../../redux/program'
import AddMediaComponent from '../AddMediaComponent/AddMediaComponent'
import styles from './ProgramMedias.module.scss'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProgramMediasProps

interface IProgramMediasProps {
  match: Match<{ idProgram: string }>
}

class ProgramMedias extends React.Component<IProps> {
  public async componentDidMount() {
    await this.props.fetchProgram(this.props.match.params.idProgram)
  }

  public async fetchProgram() {
    await this.props.fetchProgram(this.props.match.params.idProgram)
  }

  public async removeMedia(url: string | null) {
    url && (await this.props.deleteMedia(url))
    this.fetchProgram()
  }

  public render() {
    return (
      <div>
        {this.props.program && (
          <Row>
            <Col span={8}>
              <h2>Plan</h2>
              {this.props.program.plan && (
                <>
                  <a href={this.props.program.plan}>Télécharger le plan</a>
                  <span
                    className={styles.warn}
                    onClick={() => this.removeMedia(this.props.program.plan)}
                  >
                    <Icon type="close" /> supprimer le plan
                  </span>
                </>
              )}
              {!this.props.program.plan && <span>Aucun plan téléchargé</span>}
              <AddMediaComponent idProgram={this.props.match.params.idProgram} field="planId" />
            </Col>
            <Col span={8}>
              <h2>Brochure</h2>
              {this.props.program.booklet && (
                <>
                  <a href={this.props.program.booklet}>Télécharger le booklet</a>
                  <span
                    className={styles.warn}
                    onClick={() => this.removeMedia(this.props.program.booklet)}
                  >
                    <Icon type="close" /> supprimer le booklet
                  </span>
                </>
              )}
              {!this.props.program.booklet && <span>Aucune brochure téléchargée</span>}
              <AddMediaComponent idProgram={this.props.match.params.idProgram} field="bookletId" />
            </Col>
            <Col span={8}>
              <h2>Pictures</h2>
              {this.props.program.pictures &&
                this.props.program.pictures.map((p, idx) => (
                  <div className={styles.imgCtn} key={idx}>
                    <img className={styles.picture} src={p} />
                    <span className={styles.deleteBtn} onClick={() => this.removeMedia(p)}>
                      <Icon type="close" />
                    </span>
                  </div>
                ))}
              {!this.props.program.pictures && <span>Aucune photo téléchargée</span>}
              <AddMediaComponent
                idProgram={this.props.match.params.idProgram}
                field="newPictureId"
              />
            </Col>
          </Row>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state: IRootState, ownProps: IProgramMediasProps) => ({
  program: getProgram(state, ownProps.match.params.idProgram ? ownProps.match.params.idProgram : '')
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchProgram: (id: string) => dispatch(fetchProgram(id)),
  updateProgram: (id: string, program: ProgramUpdateDTO) => dispatch(updateProgram(id, program)),
  deleteMedia: (irOrUrl: string) => dispatch(deleteMedia(irOrUrl))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgramMedias)
