import { NewsPrivateDTO } from '@vaneauneuf/dtos'
import { ActionsTypes } from './model'

export const setNewsList = (newsList: { data: NewsPrivateDTO[]; total: number }) => ({
  type: ActionsTypes.SET_NEWS_LIST,
  newsList
})

export const setNews = (news: NewsPrivateDTO) => ({
  type: ActionsTypes.SET_NEWS,
  news
})

export const addNews = (news: NewsPrivateDTO) => ({
  type: ActionsTypes.ADD_NEWS,
  news
})

export const removeNews = (idNews: string) => ({
  type: ActionsTypes.REMOVE_NEWS,
  idNews
})

export const setLoading = (loading: boolean) => ({
  type: ActionsTypes.SET_NEWS_LOADING,
  loading
})
