import { UserFormCreationDTO, UserFormDTO } from '@vaneauneuf/dtos'
import Table, { ColumnProps } from 'antd/lib/table'
import React from 'react'

interface IProps {
  forms: UserFormCreationDTO[] | null
}

export default class ContactFormsTable extends React.Component<IProps> {
  private columns: Array<ColumnProps<UserFormCreationDTO>> = [
    {
      title: 'Type',
      dataIndex: 'type'
    },
    {
      title: 'Origine',
      dataIndex: 'formOrig'
    },
    {
      title: 'Date',
      dataIndex: 'createDate',
      render: (form, record) => new Date((record as any).createDate).toLocaleString()
    },
    {
      title: 'Données',
      dataIndex: 'formData',
      render: data => JSON.stringify(data, null, '\n')
    }
  ]

  public render() {
    if (!this.props.forms) {
      return null
    }
    return <Table columns={this.columns} dataSource={this.props.forms} rowKey="id" />
  }
}
