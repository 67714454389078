"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var FormTypes;

(function (FormTypes) {
  FormTypes["ContactForm"] = "ContactForm";
  FormTypes["SimulatorForm"] = "SimulatorForm";
  FormTypes["DownloadForm"] = "DownloadForm";
})(FormTypes = exports.FormTypes || (exports.FormTypes = {}));