import { NewsPrivateDTO } from '@vaneauneuf/dtos'

export enum ActionsTypes {
  SET_NEWS_LIST = 'SET_NEWS_LIST',
  SET_NEWS = 'SET_NEWS',
  ADD_NEWS = 'ADD_NEWS',
  REMOVE_NEWS = 'REMOVE_NEWS',
  SET_NEWS_LOADING = 'SET_NEWS_LOADING'
}

export interface INewsState {
  newsList: NewsPrivateDTO[]
  total: number
  loading: boolean
}
