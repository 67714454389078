import { AccountDTO } from '@vaneauneuf/dtos'
import Modal from 'antd/lib/modal'
import Table, { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import { deleteAdmin, fetchAdmins } from '../../../redux/admin'

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

class AdminsTable extends React.Component<IProps> {
  private columns: Array<ColumnProps<AccountDTO>> = [
    {
      title: 'Id',
      dataIndex: 'id',
      render: (name, record) => <Link to={'/admins/' + record.id}>{name}</Link>
    },
    {
      title: 'E-mail',
      dataIndex: 'email'
    },
    {
      title: 'Type',
      dataIndex: 'type'
    },
    {
      title: 'Action',
      render: (text, record) => <a onClick={() => this.handleDelete(record.id)}>Supprimer</a>
    }
  ]

  public async componentDidMount() {
    await this.props.fetchAdmins()
  }

  public render() {
    return <Table columns={this.columns} dataSource={this.props.admins} rowKey="id" />
  }

  private handleDelete = (id: string) => {
    Modal.confirm({
      title: 'Voulez vous vraiment supprimer cet admin ?',
      onOk: () => this.props.deleteAccount(id)
    })
  }
}

const mapStateToProps = (state: IRootState) => ({
  admins: state.adminState.admins
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchAdmins: () => dispatch(fetchAdmins()),
  deleteAccount: (id: string) => dispatch(deleteAdmin(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminsTable)
