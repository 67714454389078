import { HomeConfigDTO, HomeConfigUpdateDTO, PaginationRequest } from '@vaneauneuf/dtos'
import { Dispatch } from 'redux'
import { IRootState } from '..'
import { apiRequest } from '../utils/api'
import { setHomeConfig, setLoading } from './actions'

export const fetchHomeConfig = (paginationRequest?: PaginationRequest) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const homeConfig = await apiRequest<HomeConfigDTO>(
      'GET',
      getState().authState.token,
      '/homeConfig',
      undefined,
      paginationRequest
    )
    dispatch(setHomeConfig(homeConfig))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const updateHomeConfig = (id: string, homeConfig: HomeConfigUpdateDTO) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const homeConfigUpdated = await apiRequest<HomeConfigDTO>(
      'PATCH',
      getState().authState.token,
      '/homeConfig/' + id,
      homeConfig
    )
    dispatch(setHomeConfig(homeConfigUpdated))
  } catch (e) {
    dispatch(setLoading(false))
    throw e.response.data
  }
  dispatch(setLoading(false))
}
