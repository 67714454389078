import { ProgramCreationDTO, ProgramTags } from '@vaneauneuf/dtos'
import AutoComplete from 'antd/lib/auto-complete'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import TextArea from 'antd/lib/input/TextArea'
import message from 'antd/lib/message'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Select, { SelectValue } from 'antd/lib/select'
import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IComponentFormCreateProps } from '../../../components/CreateForm/CreateForm'
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent'
import { Tag } from '../../../components/UI/Tag'
import { IRootState } from '../../../redux'
import { createProgram } from '../../../redux/program'
import { fetchPromoters } from '../../../redux/promoter'
import ISuggestion, { getFieldsValuefromLocation, mapBoxSearch } from '../../../utils/mapBox'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IComponentFormCreateProps

interface IState {
  confirmDirty: boolean
  errors: string[]
  dataSource: ISuggestion[]
}

class CreateForm extends React.PureComponent<IProps, IState> {
  public state: IState = {
    confirmDirty: false,
    errors: [],
    dataSource: []
  }

  public async componentDidMount() {
    await this.props.fetchPromoters()
  }

  public render() {
    const { visible, hideModal, loading, promoters, form } = this.props
    const { errors, dataSource } = this.state
    const { getFieldDecorator } = form
    return (
      <Modal
        visible={visible}
        title="Créer un nouveau programme"
        okText="Sauvegarder"
        onCancel={hideModal}
        onOk={this.createProgram}
        confirmLoading={loading}
      >
        <Form>
          <Form.Item label="Promoteur">
            {getFieldDecorator('promoterId', {
              rules: [
                { required: true, message: 'Veuillez sélectionnez le promoteur du programme' }
              ]
            })(
              <Select>
                {promoters.map(promoter => (
                  <Select.Option key={promoter.id} value={promoter.id}>
                    {promoter.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Nom">
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Veuillez saisir le nom du programme' }]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Description">
            {getFieldDecorator('description', {
              rules: [{ required: true, message: 'Veuillez saisir la description du programme' }]
            })(<TextArea placeholder="Texte en format markdown" />)}
          </Form.Item>
          <Form.Item label="Adresse">
            {getFieldDecorator('address', {
              rules: [{ required: true, message: "Veuillez saisir l'adresse du programme" }]
            })(
              <AutoComplete onSearch={this.autocomplete} onSelect={this.setLocalisationFields}>
                {dataSource.map((data: ISuggestion) => (
                  <Select.Option key={data.id}>{data.place_name}</Select.Option>
                ))}
              </AutoComplete>
            )}
          </Form.Item>
          <Form.Item label="Rue">
            {getFieldDecorator('street', {
              rules: [{ required: true, message: 'Veuillez saisir la rue du programme' }]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Ville">
            {getFieldDecorator('city', {
              rules: [{ required: true, message: 'Veuillez saisir la ville du programme' }]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Code postal">
            {getFieldDecorator('postalCode', {
              rules: [{ required: true, message: 'Veuillez saisir le code postal du programme' }]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Pays">
            {getFieldDecorator('country', {
              rules: [{ required: true, message: 'Veuillez saisir le pays du programme' }]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Département">
            {getFieldDecorator('department', {
              rules: [
                { required: true, message: 'Veuillez saisir le département du programme (ex : 95)' }
              ]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Region">
            {getFieldDecorator('region', {
              rules: [{ required: false, message: 'Veuillez saisir la région du programme' }]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Latitude">
            {getFieldDecorator('latitude', {
              rules: [{ required: true, message: 'Veuillez saisir la latitude du programme' }]
            })(<Input type="number" />)}
          </Form.Item>
          <Form.Item label="Longitude">
            {getFieldDecorator('longitude', {
              rules: [{ required: true, message: 'Veuillez saisir la longitude du programme' }]
            })(<Input type="number" />)}
          </Form.Item>
          <Form.Item label="Date de livraison">
            {getFieldDecorator('finishDate', {
              rules: [{ type: 'object', required: true, message: 'Sélectionnez la date' }]
            })(<DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />)}
          </Form.Item>
          <div className="ant-row ant-form-item">
            <div className="ant-form-item-label">
              <label>Tags</label>
            </div>
            <div className="ant-form-item-control-wrapper">
              <Row style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                {Object.entries(ProgramTags).map(([key, title]) => (
                  <Col key={key} style={{ marginBottom: '8px' }}>
                    {getFieldDecorator(key, {
                      valuePropName: 'checked'
                    })(<Tag title={title} editable />)}
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </Form>
        <ErrorComponent errors={errors} />
      </Modal>
    )
  }

  private createProgram = async () => {
    const { hideModal, form, locale } = this.props
    this.setState({ errors: [] })

    form.validateFieldsAndScroll(async (errors, fieldsValue: ProgramCreationDTO) => {
      if (errors) {
        return
      }

      const coordinates: GeoJSON.Point = {
        type: 'Point',
        coordinates: [(fieldsValue as any).latitude, (fieldsValue as any).longitude]
      }

      const values = {
        ...fieldsValue,
        finishDate: fieldsValue.finishDate,
        coordinates
      }

      try {
        await this.props.createProgram(values)
      } catch ({ errors }) {
        if (errors) {
          this.setState({ errors })
        }
        return
      }

      message.success('Le programme a bien été ajouté')
      form.resetFields()
      hideModal()
    })
  }

  private autocomplete = async (value: string) => {
    if (value.length >= 3) {
      const res = await mapBoxSearch(value)
      this.setState({ dataSource: res.features })
    }
  }

  private setLocalisationFields = (value: SelectValue) => {
    const location = this.state.dataSource.find(loc => loc.id === value)

    if (location) {
      const fieldsValue = getFieldsValuefromLocation(location)
      this.props.form.setFieldsValue(fieldsValue)
    }
  }
}

const mapStateToProps = (state: IRootState) => ({
  locale: state.authState.locale,
  loading: state.programState.loading,
  promoters: state.promoterState.promoters
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  createProgram: (program: ProgramCreationDTO) => dispatch(createProgram(program)),
  fetchPromoters: () => dispatch(fetchPromoters())
})

const ProgramsCreateForm = Form.create({ name: 'programs_create_form' })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateForm)
)

export default ProgramsCreateForm
