import React from 'react'
import CreateForm from '../../components/CreateForm/CreateForm'
import NewsTagCreateAndUpdateForm from './NewsTagCreateForm/NewsTagCreateForm'
import styles from './NewsTagListPage.module.scss'
import NewsTagListTable from './NewsTagListTable/NewsTagListTable'

const NewsTagListPage: React.FunctionComponent = () => (
  <div className={styles.container}>
    <h1>Liste des Tags (Actualités / Infos)</h1>
    <CreateForm entity="newsTag" component={NewsTagCreateAndUpdateForm} />
    <NewsTagListTable />
  </div>
)

export default NewsTagListPage
