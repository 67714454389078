import { IWishState } from '.'
import { createReducer } from '../utils'
import { setLoading, setWishes, updateWishStore } from './actions'
import { ActionsTypes } from './model'

const initialState: IWishState = {
  wishes: [],
  total: 0,
  loading: false
}

const actionHandlers = {
  [ActionsTypes.SET_WISHES]: (
    state: IWishState,
    { wishes }: ReturnType<typeof setWishes>
  ): IWishState => ({
    ...state,
    wishes: wishes.data,
    total: wishes.total
  }),
  [ActionsTypes.UPDATE_WISH]: (
    state: IWishState,
    { wish }: ReturnType<typeof updateWishStore>
  ): IWishState => ({
    ...state,
    wishes: state.wishes.map(w => (w.id === wish.id ? wish : w))
  }),
  [ActionsTypes.SET_WISH_LOADING]: (
    state: IWishState,
    { loading }: ReturnType<typeof setLoading>
  ): IWishState => ({
    ...state,
    loading
  })
}

export default createReducer(initialState, actionHandlers)
