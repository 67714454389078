import { PaginationRequest, UserFilterDTO } from '@vaneauneuf/dtos'
import { Button, Col, Row } from 'antd'
import Search from 'antd/lib/input/Search'
import React, { Dispatch, FC } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import { fetchMembers } from '../../../redux/user'
import { PAGE_SIZE } from '../MembersPage'
import styles from './MembersFilter.module.scss'

interface IMemberFiltersProps {
  filters: UserFilterDTO
  onUpdateFilters: Dispatch<React.SetStateAction<UserFilterDTO>>
}

type IProps = ReturnType<typeof mapDispatchToProps> & IMemberFiltersProps

const MembersFilter: FC<IProps> = ({
  filters,
  fetchMembers: fetchMembersStore,
  onUpdateFilters
}) => {
  const resetFilters = () => {
    onUpdateFilters({ email: '', name: '', phone: '' })
    fetchMembersWithFilters()
  }

  const fetchMembersWithFilters = () => fetchMembersStore({ take: PAGE_SIZE, ...filters })

  return (
    <div className={styles.container}>
      <h2>Filtrer</h2>
      <Row>
        <Col span={6}>
          <Search
            placeholder="Filter par nom"
            className={styles.filterRow}
            addonBefore="Par nom"
            value={filters.name}
            onChange={event => onUpdateFilters({ ...filters, name: event.currentTarget.value })}
            onPressEnter={fetchMembersWithFilters}
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <Search
            placeholder="Filtrer par numéro de téléphone"
            className={styles.filterRow}
            addonBefore="Par téléphone"
            value={filters.phone}
            onChange={event => onUpdateFilters({ ...filters, phone: event.currentTarget.value })}
            onPressEnter={fetchMembersWithFilters}
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <Search
            placeholder="Filtrer par adresse email"
            className={styles.filterRow}
            addonBefore="Par email"
            value={filters.email}
            onChange={event => onUpdateFilters({ ...filters, email: event.currentTarget.value })}
            onPressEnter={fetchMembersWithFilters}
          />
        </Col>
      </Row>
      <Row>
        <Col span={7}>
          <Button type="primary" icon="search" onClick={fetchMembersWithFilters}>
            Rechercher
          </Button>
          <Col span={7}>
            <Button icon="redo" onClick={resetFilters}>
              Effacer
            </Button>
          </Col>
        </Col>
      </Row>
    </div>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchMembers: (paginationRequest: PaginationRequest) => dispatch(fetchMembers(paginationRequest))
})

export default connect(
  null,
  mapDispatchToProps
)(MembersFilter)
