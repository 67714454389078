"use strict";

var _classCallCheck = require("/srv/packages/backoffice/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var class_validator_1 = require("class-validator");

var NavigationsRoot;

(function (NavigationsRoot) {
  NavigationsRoot["Defiscalisation"] = "defiscalisation";
  NavigationsRoot["PromoteurImmobilier"] = "promoteur-immobilier";
  NavigationsRoot["ProgrammeImmobilierNeuf"] = "programme-immobilier-neuf";
  NavigationsRoot["AchatLogementNeuf"] = "achat-logement-neuf";
  NavigationsRoot["Achat"] = "achat";
})(NavigationsRoot = exports.NavigationsRoot || (exports.NavigationsRoot = {}));

var NavigationsCritera;

(function (NavigationsCritera) {
  NavigationsCritera["type"] = "type";
  NavigationsCritera["fiscalTool"] = "fiscalTool";
  NavigationsCritera["city"] = "city";
  NavigationsCritera["department"] = "department";
  NavigationsCritera["region"] = "region";
  NavigationsCritera["promoter"] = "promoter";
})(NavigationsCritera = exports.NavigationsCritera || (exports.NavigationsCritera = {}));

var NavMapChildPublicDTO = function NavMapChildPublicDTO() {
  _classCallCheck(this, NavMapChildPublicDTO);
};

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], NavMapChildPublicDTO.prototype, "id", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], NavMapChildPublicDTO.prototype, "h2", void 0);

exports.NavMapChildPublicDTO = NavMapChildPublicDTO;

var NavMapPublicDTO = function NavMapPublicDTO() {
  _classCallCheck(this, NavMapPublicDTO);
};

__decorate([class_validator_1.IsUUID(), __metadata("design:type", String)], NavMapPublicDTO.prototype, "id", void 0);

__decorate([class_validator_1.IsEnum(NavigationsRoot), __metadata("design:type", String)], NavMapPublicDTO.prototype, "rootNav", void 0);

__decorate([class_validator_1.IsEnum(NavigationsCritera), __metadata("design:type", String)], NavMapPublicDTO.prototype, "criteria1", void 0);

__decorate([class_validator_1.IsEnum(NavigationsCritera), __metadata("design:type", String)], NavMapPublicDTO.prototype, "criteria2", void 0);

__decorate([class_validator_1.IsEnum(NavigationsCritera), __metadata("design:type", String)], NavMapPublicDTO.prototype, "criteria3", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], NavMapPublicDTO.prototype, "breadcrumb", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], NavMapPublicDTO.prototype, "metaTitle", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], NavMapPublicDTO.prototype, "metaDescription", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], NavMapPublicDTO.prototype, "h1", void 0);

__decorate([class_validator_1.IsString(), __metadata("design:type", String)], NavMapPublicDTO.prototype, "description", void 0);

exports.NavMapPublicDTO = NavMapPublicDTO;