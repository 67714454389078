import { NewsTagPrivateDTO, NewsTagPublicDTO } from '@vaneauneuf/dtos'
import Modal from 'antd/lib/modal'
import { PaginationConfig } from 'antd/lib/pagination'
import Table, { ColumnProps, SorterResult } from 'antd/lib/table'
import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import { deleteNewsTag, fetchNewsTagList } from '../../../redux/newsTag'
import NewsTagCreateAndUpdateForm from '../NewsTagCreateForm/NewsTagCreateForm'

interface IState {
  visible: boolean
  selectedTag?: NewsTagPublicDTO
}

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const PAGE_SIZE = 50

class NewsTagListTable extends React.Component<IProps> {
  public state: IState = {
    visible: false,
    selectedTag: undefined
  }

  private columns: Array<ColumnProps<NewsTagPrivateDTO>> = [
    {
      title: 'Slug',
      dataIndex: 'slug'
    },
    {
      title: 'Edit',
      dataIndex: 'Edition',
      render: (name, record) => <a onClick={() => this.openEditModal(record)}>Editer</a>
    },
    {
      title: 'Action',
      render: (text, record) => <a onClick={() => this.handleDelete(record.id)}>Supprimer</a>
    }
  ]

  public async componentDidMount() {
    await this.props.fetchNewsTagList()
  }

  public render() {
    return (
      <>
        <NewsTagCreateAndUpdateForm
          visible={this.state.visible}
          tag={this.state.selectedTag}
          hideModal={() => this.setState({ ...this.state, visible: false })}
        />
        <Table
          columns={this.columns}
          dataSource={this.props.newsTagList}
          rowKey="id"
          pagination={false}
          loading={this.props.loading}
        />
      </>
    )
  }
  private handleDelete = (id: string) => {
    Modal.confirm({
      title: 'Voulez vous vraiment supprimer ce tag ?',
      onOk: () => this.props.deleteNewsTag(id)
    })
  }
  private openEditModal = (tag: NewsTagPublicDTO) => {
    this.setState({ ...this.state, visible: true, selectedTag: tag })
  }
}

const mapStateToProps = (state: IRootState) => ({
  newsTagList: state.newsTagState.newsTagList,
  loading: state.newsTagState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchNewsTagList: () => dispatch(fetchNewsTagList()),
  deleteNewsTag: (id: string) => dispatch(deleteNewsTag(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsTagListTable)
