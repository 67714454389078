import { PaginationRequest, WishAnswers, WishFilterDTO } from '@vaneauneuf/dtos'
import { Button, Col, Row, Select } from 'antd'
import Search from 'antd/lib/input/Search'
import React, { Dispatch, FC, memo, SetStateAction } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import { fetchWishes } from '../../../redux/wish'
import { PAGE_SIZE } from '../WishesPage'
import styles from './WishesFilter.module.scss'

interface IWishesFilterProps {
  filters: WishFilterDTO
  onUpdateFilters: Dispatch<SetStateAction<WishFilterDTO>>
}

type IProps = ReturnType<typeof mapDispatchToProps> & IWishesFilterProps

const WishesFilter: FC<IProps> = memo(
  ({ filters, fetchWishes: fetchWishesStore, onUpdateFilters }) => {
    const resetFilters = () => {
      onUpdateFilters({ name: '', reference: '', email: '', answer: undefined })
      fetchWishesWithFilters()
    }

    const fetchWishesWithFilters = () => fetchWishesStore({ take: PAGE_SIZE, ...filters })

    return (
      <div className={styles.container}>
        <h2>Filtrer</h2>
        <Row>
          <Col span={6}>
            <Search
              placeholder="Filter par nom de programme"
              className={styles.filterRow}
              addonBefore="Par nom"
              value={filters.name}
              onChange={event => onUpdateFilters({ ...filters, name: event.currentTarget.value })}
              onPressEnter={fetchWishesWithFilters}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Search
              placeholder="Filtrer par référence de lot"
              className={styles.filterRow}
              addonBefore="Par référence"
              value={filters.reference}
              onChange={event =>
                onUpdateFilters({ ...filters, reference: event.currentTarget.value })
              }
              onPressEnter={fetchWishesWithFilters}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Search
              placeholder="Filtrer par e-mail de demandeur"
              className={styles.filterRow}
              addonBefore="Par email"
              value={filters.email}
              onChange={event => onUpdateFilters({ ...filters, email: event.currentTarget.value })}
              onPressEnter={fetchWishesWithFilters}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Select
              placeholder="Filtrer par réponse"
              className={styles.filterRowSelect}
              value={filters.answer}
              onChange={answer => onUpdateFilters({ ...filters, answer })}
            >
              {Object.keys(WishAnswers).map((wishAnswer: any) => (
                <Select.Option key={wishAnswer} value={WishAnswers[wishAnswer]}>
                  {WishAnswers[wishAnswer]}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={7}>
            <Button type="primary" icon="search" onClick={fetchWishesWithFilters}>
              Rechercher
            </Button>
            <Col span={7}>
              <Button icon="redo" onClick={resetFilters}>
                Effacer
              </Button>
            </Col>
          </Col>
        </Row>
      </div>
    )
  }
)

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchWishes: (paginationRequest: PaginationRequest) => dispatch(fetchWishes(paginationRequest))
})

export default connect(
  null,
  mapDispatchToProps
)(WishesFilter)
