import {
  AlertPrivateDTO,
  FavoritePrivateDTO,
  PaginationRequest,
  ProjectPrivateDTO,
  UserDTO,
  UserFilterDTO,
  UserFormDTO,
  UserUpdateDTO,
  WishPrivateDTO
} from '@vaneauneuf/dtos'
import { Dispatch } from 'redux'
import { IRootState } from '..'
import { apiRequest } from '../utils/api'
import {
  removeUser,
  setContact,
  setContacts,
  setLoading,
  setMember,
  setMemberAlerts,
  setMemberFavorites,
  setMemberProjects,
  setMembers,
  setMemberWishes
} from './actions'

export const fetchContacts = (queryParams: PaginationRequest & UserFilterDTO) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))

  queryParams.includeMembers = true

  try {
    const contacts = await apiRequest<{ data: UserFormDTO[]; total: number }>(
      'GET',
      getState().authState.token,
      `/user/contact?countries[]=${getState().authState.locale}`,
      undefined,
      queryParams
    )
    dispatch(setContacts(contacts))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const fetchContact = (id: string) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const contact = await apiRequest<UserFormDTO>(
      'GET',
      getState().authState.token,
      '/user/contact/' + id
    )
    dispatch(setContact(contact))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const updateContact = (id: string, contact: UserUpdateDTO) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const contactUpdated = await apiRequest<UserFormDTO>(
      'PATCH',
      getState().authState.token,
      '/user/contact/' + id,
      contact
    )
    dispatch(setContact(contactUpdated))
  } catch (e) {
    dispatch(setLoading(false))
    throw e.response.data
  }
  dispatch(setLoading(false))
}

export const fetchMembers = (queryParams: PaginationRequest & UserFilterDTO) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))

  try {
    const members = await apiRequest<{ data: UserDTO[]; total: number }>(
      'GET',
      getState().authState.token,
      `/user/member?countries[]=${getState().authState.locale}`,
      undefined,
      queryParams
    )
    dispatch(setMembers(members))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const fetchMember = (id: string) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const member = await apiRequest<UserDTO>('GET', getState().authState.token, '/user/' + id)
    dispatch(setMember(member))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const updateMember = (id: string, member: UserUpdateDTO) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const memberUpdated = await apiRequest<UserDTO>(
      'PATCH',
      getState().authState.token,
      '/user/member/' + id,
      member
    )
    dispatch(setMember(memberUpdated))
  } catch (e) {
    dispatch(setLoading(false))
    throw e.response.data
  }
  dispatch(setLoading(false))
}

export const fetchProjects = (id: string) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const projects = await apiRequest<ProjectPrivateDTO[]>(
      'GET',
      getState().authState.token,
      '/user/member/' + id + '/project'
    )
    dispatch(setMemberProjects(projects))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const fetchAlerts = (id: string) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const alerts = await apiRequest<AlertPrivateDTO[]>(
      'GET',
      getState().authState.token,
      '/user/member/' + id + '/alert'
    )
    dispatch(setMemberAlerts(alerts))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const fetchFavorites = (id: string) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const favorites = await apiRequest<FavoritePrivateDTO[]>(
      'GET',
      getState().authState.token,
      '/user/member/' + id + '/favorite'
    )
    dispatch(setMemberFavorites(favorites))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const fetchMemberWishes = (id: string) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    const wishes = await apiRequest<WishPrivateDTO[]>(
      'GET',
      getState().authState.token,
      '/user/member/' + id + '/wish'
    )
    dispatch(setMemberWishes(wishes))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}

export const deleteUser = (id: string) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(setLoading(true))
  try {
    await apiRequest('DELETE', getState().authState.token, '/user/' + id)
    dispatch(removeUser(id))
  } catch (e) {
    console.error(e)
  }
  dispatch(setLoading(false))
}
