import { WishAnswers, WishPrivateDTO } from '@vaneauneuf/dtos'
import { Select } from 'antd'
import Form from 'antd/lib/form'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { ColumnProps } from 'antd/lib/table'
import React, { createRef, FC, useEffect, useState } from 'react'
import { WishEditableContext } from '../WishEditableRow/WishEditableRow'
import styles from './WishEditableCell.module.scss'

export const WishEditableCell: FC<ColumnProps<WishPrivateDTO> & any> = ({
  children,
  dataIndex,
  record,
  title,
  handleSave,
  editable,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  let select: any | null = createRef()
  let form: WrappedFormUtils | null

  useEffect(() => {
    if (editing) {
      select.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
  }

  const save = (answer: WishAnswers) => {
    toggleEdit()
    handleSave({ ...record, answer })
  }

  const renderCell = (formInjected: WrappedFormUtils | null) => {
    form = formInjected
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form &&
          form.getFieldDecorator(dataIndex as string, {
            initialValue: record[dataIndex as string]
          })(
            <Select ref={node => (select = node)} onChange={save} onBlur={save}>
              {Object.keys(WishAnswers).map((wishAnswer: any) => (
                <Select.Option key={wishAnswer} value={WishAnswers[wishAnswer]}>
                  {WishAnswers[wishAnswer]}
                </Select.Option>
              ))}
            </Select>
          )}
      </Form.Item>
    ) : (
      <div className={styles.editableCellValueWrap} onClick={toggleEdit}>
        {children}
      </div>
    )
  }

  return (
    <td {...restProps}>
      {editable ? (
        <WishEditableContext.Consumer>{renderCell}</WishEditableContext.Consumer>
      ) : (
        children
      )}
    </td>
  )
}
