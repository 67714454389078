import { LotPrivateDTO } from '@vaneauneuf/dtos'
import { ActionsTypes } from './model'

export const setLots = (lots: LotPrivateDTO[]) => ({
  type: ActionsTypes.SET_LOTS,
  lots
})

export const setLot = (lot: LotPrivateDTO) => ({
  type: ActionsTypes.SET_LOT,
  lot
})

export const addLot = (lot: LotPrivateDTO) => ({
  type: ActionsTypes.ADD_LOT,
  lot
})

export const removeLot = (idLot: string) => ({
  type: ActionsTypes.REMOVE_LOT,
  idLot
})

export const setLoading = (loading: boolean) => ({
  type: ActionsTypes.SET_LOT_LOADING,
  loading
})
