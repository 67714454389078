"use strict";

var _toConsumableArray = require("/srv/packages/backoffice/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var deptDepartments_1 = require("./deptDepartments");

var stringNormalization_1 = require("./stringNormalization");

var deptRegion = {
  '01': 'Auvergne-Rhône-Alpes',
  '02': 'Hauts-de-France',
  '03': 'Auvergne-Rhône-Alpes',
  '04': "Provence-Alpes-C\xF4te d'Azur",
  '05': "Provence-Alpes-C\xF4te d'Azur",
  '06': "Provence-Alpes-C\xF4te d'Azur",
  '07': 'Auvergne-Rhône-Alpes',
  '08': 'Grand Est',
  '09': 'Occitanie',
  '10': 'Grand Est',
  '11': 'Occitanie',
  '12': 'Occitanie',
  '13': "Provence-Alpes-C\xF4te d'Azur",
  '14': 'Normandie',
  '15': 'Auvergne-Rhône-Alpes',
  '16': 'Nouvelle-Aquitaine',
  '17': 'Nouvelle-Aquitaine',
  '18': 'Centre-Val de Loire',
  '19': 'Nouvelle-Aquitaine',
  '20': 'Corse',
  '2A': 'Corse',
  '2B': 'Corse',
  '21': 'Bourgogne-Franche-Comté',
  '22': 'Bretagne',
  '23': 'Nouvelle-Aquitaine',
  '24': 'Nouvelle-Aquitaine',
  '25': 'Bourgogne-Franche-Comté',
  '26': 'Auvergne-Rhône-Alpes',
  '27': 'Normandie',
  '28': 'Centre-Val de Loire',
  '29': 'Bretagne',
  '30': 'Occitanie',
  '31': 'Occitanie',
  '32': 'Occitanie',
  '33': 'Nouvelle-Aquitaine',
  '34': 'Occitanie',
  '35': 'Bretagne',
  '36': 'Centre-Val de Loire',
  '37': 'Centre-Val de Loire',
  '38': 'Auvergne-Rhône-Alpes',
  '39': 'Bourgogne-Franche-Comté',
  '40': 'Nouvelle-Aquitaine',
  '41': 'Centre-Val de Loire',
  '42': 'Auvergne-Rhône-Alpes',
  '43': 'Auvergne-Rhône-Alpes',
  '44': 'Pays de la Loire',
  '45': 'Centre-Val de Loire',
  '46': 'Occitanie',
  '47': 'Nouvelle-Aquitaine',
  '48': 'Occitanie',
  '49': 'Pays de la Loire',
  '50': 'Normandie',
  '51': 'Grand Est',
  '52': 'Grand Est',
  '53': 'Pays de la Loire',
  '54': 'Grand Est',
  '55': 'Grand Est',
  '56': 'Bretagne',
  '57': 'Grand Est',
  '58': 'Bourgogne-Franche-Comté',
  '59': 'Hauts-de-France',
  '60': 'Hauts-de-France',
  '61': 'Normandie',
  '62': 'Hauts-de-France',
  '63': 'Auvergne-Rhône-Alpes',
  '64': 'Nouvelle-Aquitaine',
  '65': 'Occitanie',
  '66': 'Occitanie',
  '67': 'Grand Est',
  '68': 'Grand Est',
  '69': 'Auvergne-Rhône-Alpes',
  '70': 'Bourgogne-Franche-Comté',
  '71': 'Bourgogne-Franche-Comté',
  '72': 'Pays de la Loire',
  '73': 'Auvergne-Rhône-Alpes',
  '74': 'Auvergne-Rhône-Alpes',
  '75': 'Ile-de-France',
  '76': 'Normandie',
  '77': 'Ile-de-France',
  '78': 'Ile-de-France',
  '79': 'Nouvelle-Aquitaine',
  '80': 'Hauts-de-France',
  '81': 'Occitanie',
  '82': 'Occitanie',
  '83': "Provence-Alpes-C\xF4te d'Azur",
  '84': "Provence-Alpes-C\xF4te d'Azur",
  '85': 'Pays de la Loire',
  '86': 'Nouvelle-Aquitaine',
  '87': 'Nouvelle-Aquitaine',
  '88': 'Grand Est',
  '89': 'Bourgogne-Franche-Comté',
  '90': 'Bourgogne-Franche-Comté',
  '91': 'Ile-de-France',
  '92': 'Ile-de-France',
  '93': 'Ile-de-France',
  '94': 'Ile-de-France',
  '95': 'Ile-de-France',
  '971': 'Guadeloupe',
  '972': 'Martinique',
  '973': 'Guyane',
  '974': 'La Réunion',
  '976': 'Mayotte'
};
var normalizedRegions = Object.values(deptRegion).map(function (region) {
  return stringNormalization_1.stripAccents(region).toLowerCase();
});

var uniqueRegions = _toConsumableArray(new Set(normalizedRegions));

exports.regionExists = function (title) {
  return uniqueRegions.includes(stringNormalization_1.stripAccents(title).toLowerCase()) ? title : null;
};

exports.getRegionFromDepartment = function (department) {
  var postCode = deptDepartments_1.getPostCodeFromDepartment(department);
  return postCode ? deptRegion[postCode] : '';
};

exports.getRegionFromPostCode = function (postCode) {
  return postCode.substring(0, 2) === '97' ? deptRegion[postCode.substring(0, 3)] : deptRegion[postCode.substring(0, 2)];
};

exports.default = deptRegion;