import { AccountCreationDTO, AccountTypes } from '@vaneauneuf/dtos'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import message from 'antd/lib/message'
import Modal from 'antd/lib/modal'
import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IComponentFormCreateProps } from '../../../components/CreateForm/CreateForm'
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent'
import { IRootState } from '../../../redux'
import { createAdmin } from '../../../redux/admin'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IComponentFormCreateProps

interface IState {
  errors: string[]
}

class CreateForm extends React.Component<IProps, IState> {
  public state: IState = {
    errors: []
  }

  public render() {
    const { visible, hideModal, loading, form } = this.props
    const { errors } = this.state
    const { getFieldDecorator } = form
    return (
      <Modal
        visible={visible}
        title="Créer un nouvel admin"
        okText="Sauvegarder"
        onCancel={hideModal}
        onOk={this.createAdmin}
        confirmLoading={loading}
      >
        <Form>
          <Form.Item label="E-mail">
            {getFieldDecorator('email', {
              rules: [
                { type: 'email', message: 'Email invalide' },
                { required: true, message: 'Veuillez saisir un email' }
              ]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Password">
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Veuillez saisir un mot de passe' }]
            })(<Input type="password" />)}
          </Form.Item>
        </Form>
        <ErrorComponent errors={errors} />
      </Modal>
    )
  }

  private createAdmin = async () => {
    const { hideModal, form } = this.props
    this.setState({ errors: [] })

    form.validateFieldsAndScroll(async (errors, fieldsValue: AccountCreationDTO) => {
      if (errors) {
        return
      }

      const values = {
        ...fieldsValue,
        type: AccountTypes.Admin
      }

      try {
        await this.props.createAccount(values)
      } catch ({ errors }) {
        if (errors) {
          this.setState({ errors })
        }
        return
      }

      message.success('Le nouvel admin a bien été ajouté')
      form.resetFields()
      hideModal()
    })
  }
}

const mapStateToProps = (state: IRootState) => ({
  loading: state.adminState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  createAccount: (account: AccountCreationDTO) => dispatch(createAdmin(account))
})

const AdminsCreateForm = Form.create({ name: 'admins_create_form' })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateForm)
)

export default AdminsCreateForm
