import React from 'react'
import styles from './ErrorComponent.module.scss'

interface IProps {
  errors: string[]
}

const ErrorComponent: React.FunctionComponent<IProps> = props => (
  <>
    {props.errors.map((error, index) => (
      <p key={index} className={styles.error}>
        {error}
      </p>
    ))}
  </>
)

export default ErrorComponent
