import { PaginationRequest, UserFilterDTO } from '@vaneauneuf/dtos'
import React, { useState } from 'react'
import ContactsExport from './ContactsExport/ContactsExport'
import ContactsFilter from './ContactsFilter/ContactsFilter'
import styles from './ContactsPage.module.scss'
import ContactsTable from './ContactsTable/ContactsTable'

export const PAGE_SIZE = 50

const ContactsPage: React.FC = () => {
  const [filters, setFilters] = useState<UserFilterDTO & PaginationRequest>({
    phone: '',
    name: '',
    email: '',
    onlyContact: true
  })

  return (
    <div className={styles.container}>
      <h1>Contacts</h1>
      <ContactsExport />
      <ContactsFilter filters={filters} onUpdateFilters={setFilters} />
      <ContactsTable filters={filters} onUpdateFilters={setFilters} />
    </div>
  )
}

export default ContactsPage
