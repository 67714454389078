"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var AccountTypes;

(function (AccountTypes) {
  AccountTypes["Admin"] = "Admin";
  AccountTypes["Promoter"] = "Promoter";
  AccountTypes["User"] = "User";
  AccountTypes["NotRegistered"] = "Not Registered";
})(AccountTypes = exports.AccountTypes || (exports.AccountTypes = {}));