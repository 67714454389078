import { ConnectedRouter } from 'connected-react-router'
import React from 'react'
import 'react-quill/dist/quill.snow.css'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import AdminPage from './pages/AdminPage/AdminPage'
import AdminsPage from './pages/AdminsPage/AdminsPage'
import ContactPage from './pages/ContactPage/ContactPage'
import ContactsPage from './pages/ContactsPage/ContactsPage'
import GlobalConfigPage from './pages/GlobalConfigPage/GlobalConfigPage'
import HomeConfigPage from './pages/HomeConfigPage/HomeConfigPage'
import HomePage from './pages/HomePage/HomePage'
import LandingPage from './pages/LandingPage/LandingPage'
import LandingsPage from './pages/LandingsPage/LandingsPage'
import LoginPage from './pages/LoginPage/LoginPage'
import LotPage from './pages/LotPage/LotPage'
import MemberPage from './pages/MemberPage/MemberPage'
import MembersPage from './pages/MembersPage/MembersPage'
import NewsListPage from './pages/NewsListPage/NewsListPage'
import NewsPage from './pages/NewsPage/NewsPage'
import NewsTagListPage from './pages/NewsTagListPage/NewsTagListPage'
import ProgramPage from './pages/ProgramPage/ProgramPage'
import ProgramsPage from './pages/ProgramsPage/ProgramsPage'
import PromoterPage from './pages/PromoterPage/PromoterPage'
import PromotersPage from './pages/PromotersPage/PromotersPage'
import WishesPage from './pages/WishesPage/WishesPage'
import store, { browserHistory } from './redux'
import './styles/global.scss'
import Layout from './templates/Layout/Layout'
import PrivateRoute from './templates/PrivateRoute'

const App: React.FunctionComponent = () => (
  <Provider store={store}>
    <ConnectedRouter history={browserHistory}>
      <Switch>
        <Route path="/login" exact component={LoginPage} />
        <PrivateRoute path="/">
          <Layout>
            <Route path="/" exact component={HomePage} />
            <Route path="/admins" exact component={AdminsPage} />
            <Route path="/admins/:idAdmin" exact component={AdminPage} />
            <Route path="/promoters" exact component={PromotersPage} />
            <Route path="/promoters/:idPromoter" exact component={PromoterPage} />
            <Route path="/programs" exact component={ProgramsPage} />
            <Route path="/programs/:idProgram" exact component={ProgramPage} />
            <Route path="/programs/:idProgram/lots/:idLot" exact component={LotPage} />
            <Route path="/contacts" exact component={ContactsPage} />
            <Route path="/contacts/:idContact" exact component={ContactPage} />
            <Route path="/members" exact component={MembersPage} />
            <Route path="/members/:idMember" exact component={MemberPage} />
            <Route path="/news" exact component={NewsListPage} />
            <Route path="/news/:idNews" exact component={NewsPage} />
            <Route path="/news-tag" exact component={NewsTagListPage} />
            <Route path="/wishes" exact component={WishesPage} />
            <Route path="/landings" exact component={LandingsPage} />
            <Route path="/landings/:idLanding" exact component={LandingPage} />
            <Route path="/home" exact component={HomeConfigPage} />
            <Route path="/config" exact component={GlobalConfigPage} />
          </Layout>
        </PrivateRoute>
      </Switch>
    </ConnectedRouter>
  </Provider>
)

export default App
