import { UserUpdateDTO } from '@vaneauneuf/dtos'
import Button from 'antd/lib/button'
import Checkbox from 'antd/lib/checkbox'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import message from 'antd/lib/message'
import React from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import ErrorComponent from '../../../components/ErrorComponent/ErrorComponent'
import { IComponentFormUpdateProps } from '../../../components/UpdateForm/UpdateForm'
import { IRootState } from '../../../redux'
import { fetchMember, getMember, updateMember } from '../../../redux/user'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IComponentFormUpdateProps

interface IState {
  errors: string[]
}

class UpdateForm extends React.Component<IProps, IState> {
  public state: IState = {
    errors: []
  }

  public async componentDidMount() {
    if (!this.props.match.params.idMember) {
      return
    }

    await this.props.fetchMember(this.props.match.params.idMember)
    this.fillForm()
  }

  public render() {
    const { edit, loading, form } = this.props
    const { errors } = this.state
    const { getFieldDecorator } = form
    return (
      <>
        <Form>
          <Form.Item label="E-mail">
            {getFieldDecorator('email')(<Input disabled={true} />)}
          </Form.Item>
          <Form.Item label="Phone">
            {getFieldDecorator('phone')(<Input disabled={!edit} />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('contactByPhone', {
              valuePropName: 'checked'
            })(<Checkbox disabled={!edit}>OK pour contact par téléphone</Checkbox>)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('contactBySms', {
              valuePropName: 'checked'
            })(<Checkbox disabled={!edit}>OK pour contact par SMS</Checkbox>)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('contactByMail', {
              valuePropName: 'checked'
            })(<Checkbox disabled={!edit}>OK pour contact par email</Checkbox>)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('messageNotification', {
              valuePropName: 'checked'
            })(<Checkbox disabled={!edit}>Notifications de messages</Checkbox>)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('wishNotification', {
              valuePropName: 'checked'
            })(<Checkbox disabled={!edit}>Notifications de souhaits</Checkbox>)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('wishAvailableNotification', {
              valuePropName: 'checked'
            })(<Checkbox disabled={!edit}>Notification de souhait disponible</Checkbox>)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('newProgramNotification', {
              valuePropName: 'checked'
            })(<Checkbox disabled={!edit}>Notification de nouveau programme</Checkbox>)}
          </Form.Item>
        </Form>
        <ErrorComponent errors={errors} />
        {edit && (
          <>
            <Button onClick={this.cancelEdit}>Annuler</Button>
            <Button
              type="primary"
              onClick={this.updateMember}
              loading={loading}
              style={{ marginLeft: '1rem' }}
            >
              Sauvegarder
            </Button>
          </>
        )}
      </>
    )
  }

  private fillForm = () => {
    const { member, form } = this.props

    form.setFieldsValue({
      email: member.email,
      phone: member.phone,
      contactByPhone: member.contactByPhone,
      contactBySms: member.contactBySms,
      contactByMail: member.contactByMail,
      messageNotification: member.messageNotification,
      wishNotification: member.wishNotification,
      wishAvailableNotification: member.wishAvailableNotification,
      newProgramNotification: member.newProgramNotification
    })
  }

  private updateMember = async () => {
    const { stopEdit, member, form } = this.props
    this.setState({ errors: [] })

    form.validateFieldsAndScroll(async (errors, values: UserUpdateDTO) => {
      if (errors) {
        return
      }

      try {
        await this.props.updateMember(member.id, values)
      } catch ({ errors }) {
        if (errors) {
          this.setState({ errors })
        }
        return
      }

      message.success('Le membre a bien été mis à jour')
      stopEdit()
    })
  }

  private cancelEdit = () => {
    this.props.stopEdit()
    this.fillForm()
  }
}

const mapStateToProps = (state: IRootState, ownProps: IComponentFormUpdateProps) => ({
  loading: state.userState.loading,
  member: getMember(state, ownProps.match.params.idMember ? ownProps.match.params.idMember : '')
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchMember: (id: string) => dispatch(fetchMember(id)),
  updateMember: (id: string, member: UserUpdateDTO) => dispatch(updateMember(id, member))
})

const MemberUpdateForm = Form.create({ name: 'member_update_form' })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UpdateForm)
)

export default MemberUpdateForm
