import Button from 'antd/lib/button'
import { FormComponentProps } from 'antd/lib/form'
import React from 'react'
import { match as Match } from 'react-router-dom'

interface IUpdateFormProps {
  component: React.ComponentType<IFormComponentPropsCustom>
  match: Match<{
    idPromoter?: string
    idProgram?: string
    idLot?: string
    idContact?: string
    idMember?: string
    idAdmin?: string
    idNews?: string
    idLanding?: string
  }>
}

interface IFormComponentPropsCustom {
  edit: boolean
  stopEdit: () => void
  match: Match<{
    idPromoter?: string
    idProgram?: string
    idLot?: string
    idContact?: string
    idMember?: string
    idAdmin?: string
    idNews?: string
    idLanding?: string
  }>
}

export type IComponentFormUpdateProps = FormComponentProps & IFormComponentPropsCustom

interface IState {
  edit: boolean
}

export default class UpdateForm extends React.Component<IUpdateFormProps, IState> {
  public state: IState = {
    edit: false
  }

  public startEdit = () => {
    this.setState({ edit: true })
  }

  public stopEdit = () => {
    this.setState({ edit: false })
  }

  public render() {
    const { component: ComponentForm, match } = this.props
    const { edit } = this.state
    return (
      <>
        <ComponentForm edit={this.state.edit} stopEdit={this.stopEdit} match={match} />
        {!edit && (
          <Button type="primary" onClick={this.startEdit}>
            Editer
          </Button>
        )}
      </>
    )
  }
}
